<div>
    <div fxLayoutAlign="center center">
        <h1 style="font-size: 30px; text-align: center;"> <b>Privacy Policy </b></h1>
    </div>
    <br>
    <div fxLayout fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <div fxFlex="100">
            <img src="assets/Websiteimg/Privacy-Policy-Banner-New.png" class="imageHeight">
        </div>
    </div>
    <br>
    <br>
    <div fxLayoutAlign="start start" class="margin">
        <h3> <b>Welcome To Our Privacy Policy Page </b></h3>
    </div>
    <div fxLayoutAlign="start start" class="margin">
        <p> <b>Your Privacy Is Critically Important To Us </b></p>
    </div>
    <div fxLayoutAlign="start start" class="margin">
        <p>APT Study is located at:</p>
    </div>
    <div fxLayoutAlign="start start" class="margin">
        <p> APTstudy <br>
            161/1,Above Indian bank,Near Vikramaditya college, Zone-II Maharana Pratap Nagar, Bhopal <br>
            462011 – Madhya Pradesh , India <br>
            917554082444<br>
            <br>
            It is APTstudy’s policy to respect your privacy regarding any information we may collect while operating our
            website. This Privacy Policy applies to https://aptstudy.com (hereinafter, “us”, “we”, or
            “https://aptstudy.com”). We respect your privacy and are committed to protecting personally identifiable
            information you may provide us through the Website. We have adopted this privacy policy (“Privacy Policy”)
            to explain what information may be collected on our Website, how we use this information, and under what
            circumstances we may disclose the information to third parties. This Privacy Policy applies only to
            information we collect through the Website and does not apply to our collection of information from other
            sources.
        </p>
    </div>
    <div fxLayoutAlign="start start" class="margin">
        <p>
            This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general
            rules and policies governing your use of our Website. Depending on your activities when visiting our
            Website, you may be required to agree to additional terms and conditions.
        </p>
    </div>
    <br>
    <div fxLayoutAlign="start start" class="margin">
        <h3> <b>Website Visitors </b></h3>
    </div>
    <div fxLayoutAlign="start start" class="margin">
        <p>
            Like most website operators, APTstudy collects non-personally-identifying information of the sort that web
            browsers and servers typically make available, such as the browser type, language preference, referring
            site, and the date and time of each visitor request. APTstudy’s purpose in collecting non-personally
            identifying information is to better understand how APTstudy’s visitors use its website. From time to time,
            APTstudy may release non-personally-identifying information in the aggregate, e.g., by publishing a report
            on trends in the usage of its website.
            APTstudy also collects potentially personally-identifying information like Internet Protocol (IP) addresses
            for logged in users and for users leaving comments on https://aptstudy.com blog posts. APTstudy only
            discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses
            personally-identifying information as described below.
        </p>
    </div>
    <br>
    <div fxLayoutAlign="start start" class="margin">
        <h3> <b>Gathering Of Personally-identifying Information </b></h3>
    </div>
    <div fxLayoutAlign="start start" class="margin">
        <p>
            Certain visitors to APTstudy’s websites choose to interact with APTstudy in ways that require APTstudy to
            gather personally-identifying information. The amount and type of information that APTstudy gathers depends
            on the nature of the interaction. For example, we ask visitors who sign up for a blog at
            https://aptstudy.com to provide a username and email address.
        </p>
    </div>
    <br>
    <div fxLayoutAlign="start start" class="margin">
        <h3> <b>Security </b></h3>
    </div>
    <div fxLayoutAlign="start start" class="margin">
        <p>
            The security of your Personal Information is important to us, but remember that no method of transmission
            over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
        </p>
    </div>
    <br>
    <div fxLayoutAlign="start start" class="margin">
        <h3> <b>Advertisements </b></h3>
    </div>
    <div fxLayoutAlign="start start" class="margin">
        <p>
            Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These
            cookies allow the ad server to recognize your computer each time they send you an online advertisement to
            compile information about you or others who use your computer. This information allows ad networks to, among
            other things, deliver targeted advertisements that they believe will be of most interest to you. This
            Privacy Policy covers the use of cookies by APTstudy and does not cover the use of cookies by any
            advertisers.
        </p>
    </div>
    <br>
    <div fxLayoutAlign="start start" class="margin">
        <h3> <b>Links to External Sites </b></h3>
    </div>
    <div fxLayoutAlign="start start" class="margin">
        <p>
            Our Service may contain links to external sites that are not operated by us. If you click on a third party
            link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy
            and terms and conditions of every site you visit.
            <br><br>
            We have no control over, and assume no responsibility for the content, privacy policies or practices of any
            third party sites, products or services.
        </p>
    </div>
    <br>
    <div fxLayoutAlign="start start" class="margin">
        <h3> <b>https://aptstudy.com Uses Google Adwords For Marketing </b></h3>
    </div>
    <div fxLayoutAlign="start start" class="margin">
        <p>
            https://aptstudy.com uses the remarketing services to advertise on third party websites (including Google)
            to previous visitors to our site. It could mean that we advertise to previous visitors who haven’t completed
            a task on our site, for example using the contact form to make an enquiry. This could be in the form of an
            advertisement on the Google search results page, or a site in the Google Display Network. Third-party
            vendors, including Google, use cookies to serve ads based on someone’s past visits. Of course, any data
            collected will be used in accordance with our own privacy policy and Google’s privacy policy.
            <br><br>
            You can set preferences for how Google advertises to you using the Google Ad Preferences page, and if you
            want to you can opt out of interest-based advertising entirely by cookie settings or permanently using a
            browser plugin.
        </p>
    </div>
    <br>
    <div fxLayoutAlign="start start" class="margin">
        <h3> <b>Aggregated Statistics </b></h3>
    </div>
    <div fxLayoutAlign="start start" class="margin">
        <p>
            APTstudy may collect statistics about the behavior of visitors to its website. APTstudy may display this
            information publicly or provide it to others. However, APTstudy does not disclose your
            personally-identifying information.
        </p>
    </div>
    <br>
    <div fxLayoutAlign="start start" class="margin">
        <h3> <b>Cookies </b></h3>
    </div>
    <div fxLayoutAlign="start start" class="margin">
        <p>
            To enrich and perfect your online experience, APTstudy uses “Cookies”, similar technologies and services
            provided by others to display personalized content, appropriate advertising and store your preferences on
            your computer.
            <br><br>
            A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s
            browser provides to the website each time the visitor returns. APTstudy uses cookies to help APTstudy
            identify and track visitors, their usage of https://aptstudy.com, and their website access preferences.
            APTstudy visitors who do not wish to have cookies placed on their computers should set their browsers to
            refuse cookies before using APTstudy’s websites, with the drawback that certain features of APTstudy’s
            websites may not function properly without the aid of cookies.
            <br><br>
            By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and
            agree to APTstudy’s use of cookies.
        </p>
    </div>
    <br>
    <div fxLayoutAlign="start start" class="margin">
        <h3> <b>E-Commerce </b></h3>
    </div>
    <div fxLayoutAlign="start start" class="margin">
        <p>
            Those who engage in transactions with APTstudy – by purchasing APTstudy’s services or products, are asked to
            provide additional information, including as necessary the personal and financial information required to
            process those transactions. In each case, APTstudy collects such information only insofar as is necessary or
            appropriate to fulfill the purpose of the visitor’s interaction with APTstudy. APTstudy does not disclose
            personally-identifying information other than as described below. And visitors can always refuse to supply
            personally-identifying information, with the caveat that it may prevent them from engaging in certain
            website-related activities.
        </p>
    </div>
    <br>
    <div fxLayoutAlign="start start" class="margin">
        <h3> <b>Privacy Policy Changes </b></h3>
    </div>
    <div fxLayoutAlign="start start" class="margin">
        <p>
            Although most changes are likely to be minor, APTstudy may change its Privacy Policy from time to time, and
            in APTstudy’s sole discretion. APTstudy encourages visitors to frequently check this page for any changes to
            its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute
            your acceptance of such change.
        </p>
    </div>
    <br>
    <div fxLayoutAlign="start start" class="margin">
        <h3> <b>Credit and Contact Information </b></h3>
    </div>
    <div fxLayoutAlign="start start" class="margin">
        <p>
            This privacy policy was created at termsandconditionstemplate.com. If you have any questions about this
            Privacy Policy, please contact us via email or phone.
        </p>
    </div>

</div>