import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ComponentPortal } from "@angular/cdk/portal";
import { MatSnackBar } from "@angular/material/snack-bar";
import { base_url } from "../../../service/config";
import { HttpClient } from "@angular/common/http";
import { DOCUMENT } from '@angular/common';
const EMAIL_REGEX = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
const NUM_REGEX = /^[1-9][0-9]{5,14}$/;
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
//import { PromotionalFormTemplateComponent } from 'src/app/screens/promotional-form-template/promotional-form-template.component';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { identifierName } from "@angular/compiler";
import { GetDataServiceService } from "../../../service/get-data-service.service";
import { ThanksComponent } from "../../../thanks/thanks.component";
import { NotRegisterComponent } from "../../../not-register/not-register.component";
import { EventListenerFocusTrapInertStrategy } from "@angular/cdk/a11y";
import { InvalidCampaignComponent } from "../../invalid-campaign/invalid-campaign.component";

@Component({
  selector: "app-face-camp",
  templateUrl: "./face-camp.component.html",
  styleUrls: ["./face-camp.component.scss"],
})
export class FaceCampComponent implements OnInit {
  id: any = "";
  countryCodes: [
    { country: "India"; code: 91 },
    { country: "UAE"; code: 971 },
    { country: "Bangladesh"; code: 880 },
    { country: "Singapore"; code: 65 },
    { country: "Nepal"; code: 977 }
  ];
  faceCampaignData: any;
  dir = base_url + "uploads/classes/download/";
  mobileDevice: boolean = false;
  public utm_source: string = "";
  public utm_medium: string = "";
  public campaignType: string = "";
  public publisherId: string = "";
  public invalidcampaign: boolean = true;
  campForm = new FormGroup({});
  public minLength: number;
  public maxLength: number = 10;
  formData: any = [
    {
      key: "name",
      label: "Name",
      required: true,
      subtype: "text",
      type: "Textinput",
    },
    {
      key: "country",
      label: "Country",
      multiple: false,
      options: ["India", "UAE", "Bangladesh", "Singapore", "Nepal"],
      required: true,
      subtype: "single",
      type: "Dropdown",
    },
    {
      key: "city",
      label: "City",
      required: true,
      subtype: "text",
      type: "Textinput",
    },
    {
      key: "mobile",
      label: "Contact Number",
      required: true,
      subtype: "number",
      type: "Textinput",
    },
    {
      key: "email",
      label: "Email",
      required: true,
      subtype: "email",
      type: "Textinput",
    },
  ];

  constructor(
    public http: HttpClient,
    public ps: GetDataServiceService,
    public route: ActivatedRoute,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.id = this.route.snapshot.queryParamMap.get("id");
    this.utm_source = this.route.snapshot.queryParamMap.get("utm_source");
    if (this.utm_source !== null) {
      this.utm_medium = this.route.snapshot.queryParamMap.get("utm_medium");
      this.campaignType = this.route.snapshot.queryParamMap.get("campaigntype");
      this.publisherId = this.route.snapshot.queryParamMap.get("publisherid");
    } else {
    }
    this.getData(this.id);
  }

  formLoaded: boolean = false;
  formPortal: ComponentPortal<any>;
  initPortal() {
    // THIS METHOD WILL SET THE TEMPLATE VALUE
    // setTimeout(() => {

    // }, 6000)
    //this.formPortal = new ComponentPortal(PromotionalFormTemplateComponent);
    console.log(this.formPortal);
    // this.formLoaded = true
  }

  ngOnInit() {
    this.getWidth();
    this.profileVisted()
    this.visitorsCounted()
    // this.initPortal()
  }

  visitorsCounted() {
    let url = base_url + '/visitors';
    this.ps.getData(url).subscribe((data: any) => {
      // console.log(data);
      if (data.statusCode === 200) {
        this.visitorsCount = data?.data[0]?.count;
        this.displayCount = this.visitorsCount?.toString().split('');
      }
    })
  }

  visitorsCount = 100;
  displayCount: any = [5, 8, 9, 8, 4];

  profileVisted() {

    if (!sessionStorage.getItem('face_count')) {
      //api call
      let url = base_url + '/visitors/addVisitor';
      this.ps.PostData({}, url).subscribe((data: any) => {
        if (data.statusCode === 200) {
          this.visitorsCounted()
        }
      })
    }
    sessionStorage.setItem('face_count', '3ajh-bsj2-Had7-dk9A');
  }

  getWidth(event?) {
    this.mobileDevice = event
      ? event.target.innerWidth <= 959
        ? true
        : false
      : window.innerWidth <= 959
        ? true
        : false;
  }

  getData(id) {
    this.getFaceToFace(id).subscribe(
      (data: any) => {
        console.log("FacetoFace", data);
        if (data.statusCode !== 200 || !data.data[0] || !data.data[0].status || data.data[0].status !== 'active') {
          this.invalidcampaign = false;
          const dialogRef1 = this.dialog.open(InvalidCampaignComponent, {
            width: '500px',
            disableClose: true,
            data: { screen: " Invalid Campaign", action: "", heading: "This campaign is not available" }
          }).afterClosed().subscribe(e => {
            this.redirect();
          });
        }
        else if ((data.statusCode === 200 && !data.data[0]) || (data.statusCode === 200 && !data.data[0].status) || (data.statusCode === 200 && data.data[0].status !== 'active')) {
          this.invalidcampaign = false;
          const dialogRef1 = this.dialog.open(InvalidCampaignComponent, {
            width: '500px',
            disableClose: true,
            data: { screen: " Invalid Campaign", action: "", heading: "This campaign is not available" }
          }).afterClosed().subscribe(e => {
            this.redirect();
          });
        }
        else {
          this.faceCampaignData = data.data[0];
          this.getForm(id).subscribe((form: any) => {
            console.log("Form data is", form);
            if (form.data.length > 0) {
              this.formData = [
                ...this.formData,
                ...form.data[0].requestData.fields,
              ];
              console.log(this.formData);
              this.createForm(this.formData);
            } else {
              this.formData = [...this.formData];
              this.createForm(this.formData);
            }
            if (form.statusCode === 200) {
              this.faceCampaignData.form = form.data[0];
              // setForm(form.data[0])
              console.log(this.faceCampaignData);
            }
          });
        }
      },
      (err) => {
        this.matSnackBar.open("Failed to get data from server", "OK", {
          duration: 2000,
        });
      }
    );
  }

  redirect() {
    this.document.location.href = 'https://aptstudy.com/';
  }

  countryCheck(country) {
    if (country === 'country') {
      if (this.campForm.value.country === "India") {
        console.log(this.campForm.value.country)
        this.minLength = 10;
        this.maxLength = 10;
      }
      else {
        console.log(this.campForm.value.country)
        this.minLength = 5;
        this.maxLength = 16;
      }
    }
  }


  getFaceToFace(id) {
    //	let url = base_url + 'Classes/' + id;
    let url = base_url + 'Classes?filter={"where": {"id": "' + id + '"}}';
    console.log("The url is", url);
    return this.http.get(url);
  }

  getForm(id) {
    let url = base_url + "Forms/";
    let filter = '?filter={"where": {"campaignId": "' + id + '"}}';
    return this.http.get(url + filter);
  }


  createForm(data) {
    data.forEach((element, index) => {
      this.campForm.addControl(
        element.key, element.key === 'email' ? (
          new FormControl("", element.required ? [Validators.pattern(EMAIL_REGEX)] : [])) : (
        new FormControl("", element.required ? [Validators.required] : []))
      );

      if (index === data.length - 1) {
        this.formLoaded = true;
      }
    });
  }

  submit() {
    let url = base_url +  "Users"
    console.log(this.campForm.value);
    let obj = {};
    if (this.utm_source) {
      obj = {
        ...this.campForm.value,
        ...{
          username: this.campForm.value.mobile,
          classId: this.id,
          password: "apt#123",
          credits: 0,
          utm_medium: this.utm_medium,
          campaigntype: this.campaignType,
          publisherid: this.publisherId,
          utm_source: this.utm_source,
          source: this.campaignType,
        }
      };
    } else {
      obj = {
        ...this.campForm.value,
        ...{
          username: this.campForm.value.mobile,
          classId: this.id,
          credits: 0,
          password: "apt#123",
          source: this.campaignType,
        }
      };
    }
    console.log(obj);
    this.ps.PostData(obj, url).subscribe((response: any) => {
      console.log(response);
      if (response.statusCode === 200) {
        const dialogRef1 = this.dialog.open(ThanksComponent, {
          width: "500px",
          disableClose: true,
          data: {
            screen: "Thank You",
            action: "Thanks",
            heading: "Thank You For Registering",
          },
        });
      }
      else {
        const dialogRef1 = this.dialog.open(NotRegisterComponent, {
          width: '500px',
          disableClose: true,
          data: { screen: "Not Registered", action: "", heading: "Could Not Register" }
        });
      }
      this.campForm.reset();
      /*
      Object.keys(this.campForm.controls).forEach(key => {
        this.campForm.get(key).setErrors(null);
      }); */
    });

  }
}
