<!-- <br><br> -->
<br>
<br>
<br>
<div style="height: 100%;" fxLayout fxLayoutAlign="center center" class="full-width">
    <div fxLayout="column" fxLayoutAlign="start center" fxFlex="80" fxFlex.md="90" flex.sm="95" fxFlex.xs="96">
        <h1 class="mat-h1"><b>Feedback Form</b></h1>
        <br>
        <br>
        <br>
        <div class="full-width" fxLayout fxLayout fxLayoutGap="20px" fxLayout.lt-md="column">
            <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="center center" class="full-width">
                <img [src]="imageUrl" height="300">
                <h2 class="mat-h2">{{productName}}</h2>
                <p class="mat-body">{{productDesc}}</p>
            </div>

            <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="center center" class="full-width">
                <div id="rate-star" style="width: 75%;" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
                    <h3 class="no-margin mat-h3">Rate us</h3>
                    <app-rating [rating]='userRatings.rating' [itemId]='userRatings.id' [message]="userRatings.message" (ratingClick)='ratingComponentClick($event)'></app-rating>
                    <mat-error class="rate-err" *ngIf="!rated">Ratings required</mat-error>
                </div>
                <h4 style="line-height: 20px; height: 20px;" class="mat-h4"><b>{{userRatings?.message}} </b></h4>
                <form style="width: 75%;" [formGroup]="rateUsForm" (ngSubmit)="submitForm()">
<!--                <div fxLayout fxLayoutAlign="center center">
                        <div fxFlex="100" fxLayoutAlign="center center">
                            <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                <mat-label> Full Name</mat-label>
                                <input required formControlName="name" matInput minlength="3" maxlength="150">
                                <mat-error *ngIf="rateUsForm.controls.name?.errors?.required">Name is required</mat-error>
                                <mat-error *ngIf="rateUsForm.controls.name?.errors?.pattern">Only alphabters allowed</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout fxLayoutAlign="center center">
                        <div fxFlex="100" fxLayoutAlign="center center">
                            <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                <mat-label>Contact Number</mat-label>
                                <input required formControlName="mobile" type="tel" matInput minlength="6" maxlength="15">
                                <mat-error>Mobile number is required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>    -->
                    <br>
                    <div fxLayout fxLayoutAlign="center center">
                        <div fxFlex="100" fxLayoutAlign="center center">
                            <mat-form-field appearance="outline" [floatLabel]="'never'"
                                style="width: 100%;">
                                <mat-label>Describe your experience</mat-label>
                                <textarea matInput rows="2" cols="50" maxlength="250" formControlName="description"></textarea>
                                <mat-hint style="text-align: right; width: 100%;">{{ rateUsForm.value.description?.length ? rateUsForm.value.description?.length : 0}} \ 250</mat-hint>
                                <mat-error>Maximum 250 characters</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <br>
                    <div fxLayout fxLayoutAlign="center center">
                        <div fxFlex="100" fxLayoutAlign="center center">
                            <button style="font-weight: 600;" mat-raised-button color="primary" [disabled]="rateUsForm.invalid"
                                type="submit" class="submitButton">SUBMIT</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div style="height: 100px;"></div>