import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit {

  constructor(@Inject (MAT_DIALOG_DATA) public data: any, public matDialogRef: MatDialogRef<PromptComponent>) { }

  ngOnInit(): void {
  }
  yes() {
    this.matDialogRef.close(true)
  }

}
