import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-not-register',
  templateUrl: './not-register.component.html',
  styleUrls: ['./not-register.component.scss']
})
export class NotRegisterComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<NotRegisterComponent>) { }

  ngOnInit(): void {
  }
  okButton(){
    this.matDialogRef.close();
  }

}
