<div style="position: relative;" fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-display-1">
        Payment Successful!
    </h1>
    <h3 class="mat-h3">Your payment was successful! Explore more.</h3>
    <img [src]="imageUrl + img" width="280px" alt="home">
    <p>&nbsp;</p>
    <button style="width: 200px;" mat-raised-button class="whiteText" color="primary" (onclick)="CourseScreen()"
            [routerLink]="['/course']"><b> Explore More </b> </button>
            <br>
    <app-download-app [from]="'home'" [source]="utm_source" [medium]="utm_medium" [campaign]="campaignType"
    [publisher]="publisherId"></app-download-app>
</div>