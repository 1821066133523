<!-- <app-navigation></app-navigation> -->

<!-- <div class="loading-stage" @loadingAnimation *ngIf="!ready">
  <div class="text"> loading ({{ percentage }}%)</div>
</div>

<div class="main-stage">
  <div class="router-container" [@routeAnimation]="prepRouteAnimation(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>

<app-modal #modal></app-modal>

<app-tool-tip #tooltip></app-tool-tip>  -->

<router-outlet></router-outlet>