import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, UrlSegment } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { GetDataServiceService } from '../../../service/get-data-service.service';
import { base_url } from '../../../service/config';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { MatSidenav } from '@angular/material/sidenav';
import { PromptComponent } from '../prompt/prompt.component';
import { TimeupComponent } from '../timeup/timeup.component';
import { LocationStrategy } from '@angular/common';
import { AuthGuardGuard } from '../auth-guard.guard';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { DbService } from '../db.service';
import { urlencoded } from 'express';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-test-camp-test-screen',
  templateUrl: './test-camp-test-screen.component.html',
  styleUrls: ['./test-camp-test-screen.component.scss']
})
export class TestCampTestScreenComponent implements OnInit {
  /*
    campaignId:any;
    rollNo:any;
    mobile:any;
    prevPercent:any;
    answerAPI:any;
    public mobileDevice: boolean = false;
    userAnswer:any=[];
    answerList:any;
   public pdfSrc:any="https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  
    constructor(public router:Router,public route:ActivatedRoute,public matDialog: MatDialog,public ps: GetDataServiceService,public sanitizer: DomSanitizer) { 
      this.getAnswersList();
      
    }
  
    ngOnInit(): void {
      this.getWidth();
    }
  
    getAnswersList()
    {
      this.campaignId=this.route.snapshot.queryParamMap.get('campaignId');
      this.rollNo=this.route.snapshot.queryParamMap.get('roll');
      this.mobile=this.route.snapshot.queryParamMap.get('mobile');
      this.prevPercent=this.route.snapshot.queryParamMap.get('percentage');
      let postData={
        previousMarks:this.prevPercent+"",
        rollNumber:this.rollNo+"",
        mobile:this.mobile+"",
        campaignId:this.campaignId+""
      }
      let url=base_url+'RemoteMethods/getQuestions';
      this.ps.PostData(postData,url).subscribe((response:any)=>{
        console.log(response);
          this.answerAPI=response.data[0];
          this.answerList=this.answerAPI.testQuestions[0].testData;
          this.answersArray();
        //  this.pdfSrc=this.sanitizer.bypassSecurityTrustResourceUrl("https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf");
      })
      
    }
  
    getWidth(event?) {
      this.mobileDevice = event ? event.target.innerWidth <= 959 ? true : false : window.innerWidth <= 959 ? true : false
    }
  
    answersArray()
    {
        this.answerList.map(e=>{
          this.userAnswer.push({question:e.questionNumber,answer:"NOT ATTEMPTED"});
        });
        
    }
  
    selectedAnswer(event,i,value)
    {
      console.log(i);
      console.log(event);
      if(event.source.checked)
      {
        this.userAnswer[i]={question:i+1,answer:`${value}`};
      }
    }
    @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
      if (this.userAnswer.length > 0) {
     //   sessionStorage.setItem('selectedAnswer',JSON.stringify(this.userAnswer));
      }
    }
  
    smallCase(e)
    {
      
      let a:string="";
      a=e;
      return a.toLocaleLowerCase();
    }
  
  }  */

  @ViewChild('sidenav', { static: true }) public sidenav: MatSidenav;
  @ViewChild('btn', { static: true }) public btn: ElementRef;
  pdfUrl: SafeResourceUrl;
  pdfSrc;
  src: any;
  mobileDevice: boolean = false;
  totalQuestions: any = [];
  options: any = ['a', 'b', 'c', 'd']
  userId: any;
  campaignId: any;
  question: any = [];
  dataLoaded: boolean;
  constructor(public matDialog: MatDialog, public location: LocationStrategy, public router: Router, public activateGuard: AuthGuardGuard, public sanitizer: DomSanitizer,
    public route: ActivatedRoute, public ps: GetDataServiceService, public db: DbService, public matSnackBar: MatSnackBar) {
    if (this.route.snapshot.queryParamMap.get('restart') || sessionStorage.getItem('refreshed') === 'yes') {
      console.log('1st condition');
      this.getTestDetails();
      if (sessionStorage.getItem('refreshed') === 'yes') {
        this.testDetails = JSON.parse(sessionStorage.getItem('testDetails'))
        this.options = this.returnOptions(this.testDetails.options)
        // this.createAnswers(this.testDetails.totalQuestion, this.testDetails.options)
        console.log('2nd condition');
        // this.markCounter = (sessionStorage.getItem('counter'))
        if (this.route.snapshot.queryParamMap.get('roll') === sessionStorage.getItem('rollAnswer')) {
          console.log('3rd condition');
          this.allAnswers = JSON.parse(sessionStorage.getItem('answers')) || [];
          this.checkedAnswers(this.allAnswers)
          console.log(this.allAnswers);
          // this.allAnswers.forEach(element => {
          //   if (element.answer !== 'NOT ATTEMPTED') {
          //     this.markCounter = this.markCounter + this.testDetails.marks;
          //   }
          // });
          sessionStorage.setItem('refreshed', 'no');
          sessionStorage.removeItem('answers');
          //      sessionStorage.removeItem('rollAnswer');
        }
        if (this.route.snapshot.queryParamMap.get('roll') !== sessionStorage.getItem('rollAnswer')) {
          console.log('4th condition');
          sessionStorage.removeItem("totalQues");
          sessionStorage.removeItem('answers');
          sessionStorage.removeItem('rollAnswer');
          this.getTestDetails();
        }
      }
      console.log(this.pdfSrc)

    }
    else if (sessionStorage.getItem('mozillaRefresh') === 'yes') {
      sessionStorage.removeItem('mozillaRefresh');
      console.log('From Mozilla Refresh');
      this.router.navigate(['thank-you'], { queryParams: { test: 'done' } })
    }
    else {
      sessionStorage.removeItem("totalQues");
      sessionStorage.removeItem('answers');

      this.getTestDetails();

    }
  }

  checked: boolean = false;
  ngOnInit() {
    this.getWidth();
  }
  src1:any;
  testDetails: any
  testTime: any = '00'
  getTestDetails() {
    var roll = this.route.snapshot.queryParamMap.get('roll');
    var mobile = this.route.snapshot.queryParamMap.get('mobile');
    var perc = this.route.snapshot.queryParamMap.get('percentage');
    var campaignId = this.route.snapshot.queryParamMap.get('campaignId');
    this.campaignId = campaignId;
    var obj = {
      previousMarks: perc,
      rollNumber: roll,
      mobile: mobile,
      campaignId: campaignId
    }
    let url = base_url + 'RemoteMethods/getQuestions';
    this.ps.PostData(obj, url).subscribe((res: any) => {
      this.dataLoaded = true;
      console.log(res)
      this.testDetails = res.data[0]
      this.options = this.returnOptions(this.testDetails.options)
      !this.allAnswers.length ? this.createAnswers(this.testDetails.totalQuestion, this.testDetails.options) : ''
      sessionStorage.setItem('testDetails', JSON.stringify(this.testDetails))
      this.testTime = this.calculateTestTime(this.testDetails.startTime, this.testDetails.endTime)
      this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.db.fileBaseURL + res.data[0].fileName);
      this.src = this.pdfSrc.changingThisBreaksApplicationSecurity;
  //    this.src=(this.src1).split('.')[3];
      console.log(this.pdfSrc,(this.src).split('.')[3]);
      var response: any = res;
      this.question = response.data[0].testQuestions[0].testData;
      console.log(this.question.length);
      if (!sessionStorage.getItem('totalQues')) {

        sessionStorage.setItem('totalQues', this.question.length);
        // this.createAnswers();
      }
      this.totalQuestions=[];
      for (let i = 0; i < this.question.length; i++) {
        this.totalQuestions.push(i);
      }
      if (response.time >= 0) {
        var min = response.time / (1000 * 60)
        this.timeLeft = Math.floor(min);
        this.seconds = Math.floor((min - this.timeLeft) * 60)
        this.startTimer();
      }
      else {
        this.checked = true;
        console.log('From Response Time>=0');
        this.router.navigate(['thank-you'], { queryParams: { status: 'timeup' } })
      }
      this.db.testDetails = response.data[0];
      //     this.pdfSrc = this.db.fileBaseURL + response.data[0].url;
      this.db.userId = response.userId;
      console.log(this.pdfSrc)
      console.log(this.allAnswers)
    })

  }

  returnOptions(max: number) {
    // THE METHOD RETURNS OPTIONS FROM 'A' TO THE LENGTH PASSED AS ARGUEMENTS
    var asciiStarts = 65; //SINCE WE KNOW THAT A HAS ASCII CODE 65
    var options: any = []; //RETURN THIS ARRAY WITH OPTIONS ARRAY OF STRINGS ['A', 'B', 'C', 'D'] ; if max = 4;
    for (let i = 0; i < max; i++) {
      options.push(String.fromCharCode(asciiStarts + i).toLowerCase()) //CONVERTS THE NUMBSRS TO RESPECTIVE ALPHABETS ACCORDING TO THE ASCII CODE
    }
    return options; //RETURNS THE ARRAY
  }

  calculateTestTime(startDate, endDate) {
    let s = new Date(startDate).getTime() / 1000 / 60
    let e = new Date(endDate).getTime() / 1000 / 60
    let t = e - s
    return Math.floor(t)
  }

  // @HostListener('window:beforeunload', ['$event'])
  //   unloadNotification(event: any) {
  //     this.submit();
  //     // event.preventDefault();
  //     // event.returnValue = false;
  //     // if(event.returnValue) {
  //     //   console.log('h')
  //     // }
  //     // else {
  //     //   console.log('i')
  //     // }
  //   }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    sessionStorage.setItem('canActivate', 'true');
    sessionStorage.setItem('refreshed', 'yes');
    sessionStorage.setItem('counter', this.markCounter)

    if (this.allAnswers.length > 0) {

      sessionStorage.setItem('answers', JSON.stringify(this.allAnswers));
      sessionStorage.setItem('rollAnswer', this.route.snapshot.queryParamMap.get('roll'));

    }
  }


  // refreshRouteActivation() {
  //   if()
  // }

  canDeactivate() {
    return this.checked;
  }

  getThis(event: PDFProgressData) {
    console.log(event)
    if (event.total) {
      this.startTimer();
    }
  }

  promptDialog(): any {
    let d = { title: 'Submit Test', message: 'Are you sure to submit your test?', falsy: 'No', action: 'Submit' }
    this.matDialog.open(PromptComponent, {
      width: "500px",
      height: "auto",
      autoFocus: false,
      disableClose: true,
      data: d
    }).afterClosed().subscribe(data => {
      if (data) {
        console.log(data)
        this.checked = true;
        this.submit(true);
      }
      else {
        this.checked = false;
      }
    })
  }

  timeUpDialog() {
    this.submit(true);
    let d = { title: 'Time Up!', message: 'Uh ho! There is no time left, Test time is over. We have submitted your test.', action: 'OK' }
    this.matDialog.open(PromptComponent, {
      width: "500px",
      height: "auto",
      autoFocus: false,
      disableClose: true,
      data: d
    }).afterClosed().subscribe(() => {
      this.router.navigate(['test-campaign/result'], { queryParams: { success: sessionStorage.getItem('Succ') } });
    })
  }

  getWidth() {
    // IDENTIFY THE WIDTH OF THE SCREEN
    if (window.innerWidth < 600) {
      this.mobileDevice = true
      console.log('Mobile');
    }
    else {
      this.mobileDevice = false
      // this.sidenav.open();
    }
  }

  count = 0;
  showResult: boolean = false;
  iconName: string = 'chevron_left'
  openAnswers(panel: any) {
    if (this.mobileDevice) {
      this.count++;
      if (panel.opened) {
        document.getElementById('btn').className = 'btn-marks'
        this.iconName = 'chevron_left'
        this.showResult = false;
      }
      else {
        document.getElementById('btn').className = 'btn-marks-expanded'
        this.iconName = 'chevron_right';
        this.showResult = true;
      }
    }
  }

  openSidenav(sn) {
    this.openAnswers(sn)
    sn.toggle();
  }

  zoomPDF: number = 1.0;
  rotation: number = 0;
  pdfPage: number = 1;
  totalpages: number;
  zoomIn() {
    this.zoomPDF = this.zoomPDF + 0.1;
  }

  zoomOut() {
    this.zoomPDF = this.zoomPDF - 0.1;
  }

  rotations() {
    this.rotation += 90;
  }
  top() {
    this.pdfPage -= 1;
  }
  bottom() {
    this.pdfPage += 1;
  }

  totalPages(data: any) {
    this.totalpages = data.numPages;
  }

  // TIMER SET FUNCTION FOR 60 MINUTES
  interval: any;
  timeLeft: number = 0;
  seconds: number = 59;
  startTimer() {
    this.interval = setInterval(() => {
      if (this.seconds > 0) {
        this.seconds--;
      }
      else if (this.timeLeft === 0 && this.seconds === 0) {
        this.timeUpDialog();
        clearInterval(this.interval);
      }
      else {
        this.seconds = 59;
        this.timeLeft--;
      }
    }, 1000)
  }

  createAnswer() {
    //DECLARES THE FORMAT OF THE QUESTION AND ANSWER
    console.log(this.question.length);
    this.allAnswers = [];
    for (let index = 0; index < this.question.length; index++) {
      console.log('Hello');
      if (this.allAnswers.length < 50) {
        this.allAnswers.push({ question: index + 1, answer: 'NOT ATTEMPTED' })
      }
    }
  }

  // FUNCTION BELOW COUNTS THE CHECKED ANSWER AND ASSIGNS THE VALUE TO THE "allAnswers" ARRAY OF OBJECT
  allAnswers: any = [];
  markCounter: any = 0;
  selectedAnswer(event, index, ans) {
    // COUNTS THE VALUE OF SELECTED ANSWERS
    console.log(this.allAnswers)
    if (event.source.checked) {
      if (this.allAnswers[index].answer === 'NOT ATTEMPTED') {
      //  this.markCounter = this.markCounter + this.testDetails.marks;
      this.markCounter = this.markCounter + 1;
      }
      this.allAnswers[index].answer = ans.toUpperCase();
      console.log(this.allAnswers[index])
    }
  }

  getAnswer(event, answerIndex, questionIndex, type) {
    // LOGIC FOR MULTIPLE SELECTION AND SINGLE SELECTION
  //  console.log(answerIndex, questionIndex,this.testDetails)
    // BELOW CODE IS FOR RADIO BUTTONS
    
    if (type === 'single') {
      /*
      for (let index = 0; index < this.allAnswers[questionIndex].options.length; index++) {
        if (index === answerIndex) {
          // this.markCounter = this.markCounter + this.testDetails.marks;
          this.markCounter = this.markCounter + 1;
          console.log(this.markCounter)
          this.allAnswers[questionIndex].options[index].answer = true
        }
        else {
          // this.markCounter = this.markCounter - this.testDetails.marks;
          this.markCounter = this.markCounter - 1;
          this.allAnswers[questionIndex].options[index].answer = false
        }
      } */
      let count=0;
      this.allAnswers[questionIndex].options?.forEach((e:any)=>{
        if(e.answer===false){
          count++}
          e.answer=false;
        })
      if(event.source.checked){
        if(count===this.testDetails?.options){
          this.markCounter=this.markCounter+1;
        }
      }
      this.allAnswers[questionIndex].options[answerIndex].answer = true
      
    }
    //BELOW IS CODE FOR MULTIPLE SELECTION 
    else {
      let count=0;
      Array.from(this.allAnswers[questionIndex].options).forEach((e:any)=>{if(e.answer===false){count++;}})
  //    console.log(count,this.allAnswers)
      if (event.source.checked) {
        //  console.log(this.allAnswers)      
          if(count===this.testDetails?.options){
            this.markCounter = this.markCounter + 1;
          }
        this.allAnswers[questionIndex].options[answerIndex].answer = true
      }
      else {
        if(count===(this.testDetails?.options-1)){
          this.markCounter = this.markCounter - 1;
        }
        
        this.allAnswers[questionIndex].options[answerIndex].answer = false
      }
    }
  //  console.log(this.allAnswers)
  }

  checkedAnswers(allAnswers) {
  //  console.log('Hello')
    for (let i = 0; i < allAnswers.length; i++) {
      const answer = allAnswers[i];
      for (let j = 0; j < answer.options.length; j++) {
        const opt = answer.options[j];
        if (opt.answer) {
          this.markCounter = this.markCounter + 1;
          break;
        }
      }
    }
  }

  answerArray: any = [];
  createAnswers(max, opt) {
    // THE METHOD CREATES DATA FOR OBJECTIVE ANSWERS 
    this.allAnswers = [];
    for (let i = 0; i < max; i++) {
      let asciiStarts = 65
      this.allAnswers.push({ questionNumber: i + 1, options: [] })
      for (let j = 0; j < opt; j++) {
        this.allAnswers[i].options.push({ options: String.fromCharCode(asciiStarts + j), answer: false })
      }
    }
  }

  capital(val) {
    return val.toUpperCase();
  }

  submit(stopRoute?) {
    console.log(this.allAnswers);
    console.log(this.db.testDetails.testQuestions[0].testId);
    let postObject: any = {
      userId: this.db.userId,
      testId: this.db.testDetails.testQuestions[0].testId,
      campaignId: this.campaignId,
      data: this.allAnswers
    }
    console.log(postObject)
    let url = base_url + "RemoteMethods/submitResult";
    console.log(url);
    sessionStorage.removeItem('totalQues');
    sessionStorage.removeItem('answers');
    sessionStorage.removeItem('rollAnswer')
    this.ps.submitAnswer(postObject, url).subscribe((res: any) => {
      clearInterval(this.interval);
      var result: any = res;
      console.log(result);
      if (result.statusCode == 200) {
        sessionStorage.setItem('resultmsg', result.data.message);
        this.db.result = result.marks;
        this.db.name = result.name;
        this.checked = true;
        sessionStorage.setItem('Succ', 'success');
        if (!stopRoute)
          this.router.navigate(['test-campaign/result'], { queryParams: { id: this.campaignId, success: sessionStorage.getItem('Succ') } });
        else {
          this.router.navigate(['test-campaign/result'], { queryParams: { id: this.campaignId, success: sessionStorage.getItem('Succ') } });
        }
        //      this.router.navigate(['thank-you'], { queryParams: { test: 'done' } }) 
      }
      else {
        this.router.navigate(['test-campaign/start-test'], { queryParams: { id: sessionStorage.getItem('campId') } });
      }
    }, (err: any) => {
      this.matSnackBar.open('Please Try again to Submit', 'OK', {
        duration: 2000
      })
    })
  }

  testPagesLoaded(event) {
    if (event) {
      console.log('pdf loaded')
      // this.startTimer();
    }
  }
}