import { Component } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { GetDataServiceService } from './service/get-data-service.service';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'APT-Web';

	constructor(public router: Router, public gds: GetDataServiceService) {
		console.log(this.router)
		this.gds.getCountries();
		this.gds.getDefLangauge()
	}
}
