<div class="no-data full-width" *ngIf="!blog" fxLayoutAlign="center center">
    <ngx-loading [show]="true"
        [config]="{backdropBackgroundColour: 'rgba(0, 0, 0, 0)', primaryColour: '#54ACE4' , secondaryColour: '#2486C5', tertiaryColour:  '#0C5C8F' }">
    </ngx-loading>
</div>

<div #allBlogs *ngIf="blog" class="full-width" (window:resize)="getWidth($event)">
    <div fxLayout="column" fxLayoutAlign="center start" style="width: 100%; padding: 10px 20px;" *ngIf="mobileDevice">
        <div fxLayout fxLayoutAlign="start center">
            <p style="margin-left: 0px;" class="serchHeadings">
                SEARCH POSTS
            </p>
        </div>
        <div style="width: 100%; margin-bottom: 20px; margin-left: 0px;" class="blogDivider">

        </div>

        <mat-form-field style="width: 100%;" appearance="outline">
            <mat-label> Let's Search </mat-label>
            <input matInput autocomplete="off" type="search" [(ngModel)]="searchValue" id="search" name="search"
                (keyup)="searchBlogs(searchValue)">
            <mat-icon matSuffix *ngIf="searchValue == 'about'">search</mat-icon>
            <button *ngIf="searchValue" mat-button matSuffix mat-icon-button aria-label="Clear"
                (click)="searchBlogs(''); searchValue = ''">
                <mat-icon>close</mat-icon>
            </button>
            <mat-error>Not Found</mat-error>
        </mat-form-field>
    </div>
    <br>
    <div  class="full-width" fxLayout fxLayoutAlign="center center">
        <div fxFlex="80" fxFlex.lt-md="96" fxLayout fxLayout.sm="column" fxLayout.xs="column" fxLayout.md="column"
            fxLayoutAlign="space-between">
            <div fxLayout="column" fxFlex.lt-md="96" fxFlex.md="80" fxFlex="65">
                <div class="full-width" fxLayout="column" *ngIf="showPosts">
                    <div *ngIf="blogData && !blogData?.length" style="height: 500px;" class="full-width" fxLayout
                        fxLayoutAlign="center center">
                        <h2 class="mat-h2">NO POSTS FOUND</h2>
                    </div>
                    <all-blogs [allBlogs]="allPosts" (open)="openBlog($event)"></all-blogs>
                </div>
                <div class="full-width" fxLayout="column" *ngIf="!showPosts">
                    <div fxFlex="100" fxLayout="column" fxLayoutGap.xs="40px" fxLayoutGap="80px" *ngIf="blog">
                        <div fxLayout fxLayoutAlign="center center" *ngIf="blog.categories?.length">
                            <div fxFlex="100">
                                <div fxLayout fxLayoutAlign="start center">
                                    <h1 [style.marginBottom]="mobileDevice ? '10px' : '15px'"
                                        class="courseHeading no-margin">
                                        {{blog?.title}}
                                    </h1>
                                </div>
                                <div [style]="mobileDevice ? 'padding: 5px 0;' : 'padding: 0'" fxLayout
                                    fxLayoutAlign="start center">
                                    <div fxFlex="50" fxLayoutAlign="start center" fxFlex.sm="40" fxFlex.xs="40">
                                        <div class="BlogsHeading cut-text">
                                            <button style="pointer-events: none;" mat-icon-button>
                                                <mat-icon class="BlogsIcon">people_outline</mat-icon>
                                            </button> By {{blog?.staff?.name}}
                                        </div>
                                    </div>
                                    <div fxFlex="50" fxLayoutAlign="start center" fxFlex.sm="60" fxFlex.xs="60">
                                        <div class="BlogsHeading cut-text">
                                            <button style="pointer-events: none;" mat-icon-button>
                                                <mat-icon class="BlogsIcon">list_alt</mat-icon>
                                            </button> {{blog?.categories[0].title}} <span
                                                *ngIf="blog?.categories.length > 1" #tooltip="matTooltip"
                                                [matTooltip]="returnValName(blog?.categories, 'cat')"
                                                style="color: #1565c0;">+
                                                {{blog?.categories?.length - 1}} </span>
                                        </div>
                                    </div>
                                </div>
                                <div fxLayout fxLayoutAlign="center center">
                                    <div fxFlex="100">
                                        <img [src]="imageUrl + blog?.cover " class="blogsImage" alt="home">
                                    </div>
                                </div>
                                <br>
                                <div fxLayout fxLayoutAlign="center center" #blogdata>
                                    <div fxFlex="100" [innerHTML]="blog?.content">
                                    </div>
                                </div>
                                <br>
                                <br>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                    <div *ngIf="blog?.comments?.length > 0" fxLayout fxLayoutAlign="start center">
                        <p class="serchHeadings">
                            {{blog?.comments?.length}}&nbsp;{{blog?.comments?.length === 1 ? 'COMMENT' : 'COMMENTS'}}
                        </p>
                    </div>
                    <div *ngIf="blog?.comments?.length > 0" class="blogDivider">
                    </div>
                    <br>
                    <div fxLayout fxLayoutAlign="start center" *ngFor="let comment of blog?.comments;let k = index">
                        <div fxFlex="100" *ngIf="comment.status === 'active'">
                            <div fxLayout fxLayoutAlign="start start" fxLayoutGap="10px" *ngIf="!comment.replyId">
                                <div>
                                    <ngx-avatar [size]="mobileDevice ? '35' : '45'" initialsSize="1"
                                        [name]="comment.user.name"></ngx-avatar>
                                </div>
                                <div class="angular-with-newlines" fxFlex="100">
                                    <div style="position: relative;" fxLayout fxLayoutAlign="start center">
                                        <div class="triangle-left23"></div>
                                        <div fxLayout fxLayoutAlign="start center" class="MainChat">
                                            <p class="ptag"> {{comment.comment}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout fxLayoutAlign="start center" fxLayoutGap="20px" *ngIf="!comment.replyId">
                                <div fxLayout fxFlex="100">
                                    <span style="width: 50px;"></span>
                                    <div fxFlex="fill" fxLayout fxLayoutAlign="space-between center" fxLayoutGap="20px">
                                        <p class="adName">{{comment.user.name}} </p>
                                        <button mat-button color="primary"
                                            (click)="replyMessage(comment,k)">REPLY</button>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout fxLayoutAlign="start center" fxLayoutGap="20px" *ngIf="!comment.replyId">
                                <div fxFlex="10">
                                </div>
                                <div fxFlex="90">
                                    <div fxLayout fxLayoutAlign="start center" fxLayoutGap="20px"
                                        *ngIf="comment.showReplyForm && comment.showReplyForm">
                                        <div fxFlex="100">
                                            <form [formGroup]="replyForm" (ngSubmit)="ReplyForm()">
                                                <div fxLayout fxLayoutAlign="center center">
                                                    <div fxFlex="5" fxLayoutAlign="start center"></div>
                                                    <div fxFlex="95" fxLayoutAlign="start center">
                                                        <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                                            <mat-label> Comment </mat-label>
                                                            <textarea maxlength="500" matInput formControlName="query"
                                                                required></textarea>
                                                            <mat-error></mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div fxLayout fxLayoutAlign="center center">
                                                    <div fxFlex="5" fxLayoutAlign="start center"></div>
                                                    <div fxFlex="95" fxLayoutAlign="start center">
                                                        <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                                            <mat-label> Name</mat-label>
                                                            <input required formControlName="name" matInput minlength="3" maxlength="150">
                                                            <mat-error *ngIf="replyForm.controls.name.invalid && (replyForm.controls.name.touched || replyForm.controls.name.dirty)">
                                                                <mat-error *ngIf="replyForm.controls.name.errors.pattern" style="color:red;font-size:medium">
                                                                    Only Alphabets are allowed
                                                                </mat-error>
                                                                <mat-error>
                                                                </mat-error>
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div fxLayout fxLayoutAlign="center center">
                                                    <div fxFlex="5" fxLayoutAlign="start center"></div>
                                                    <div fxFlex="15" fxFlex.sm="25" fxFlex.xs="25"
                                                        fxLayoutAlign="center center">
                                                        <mat-form-field appearance="standard" style="width: 100%;">
                                                            <mat-label>Country Code</mat-label>
                                                            <mat-select required formControlName="country"
                                                                (ngModelChange)="onCountryChange($event)"
                                                                [(ngModel)]="defaultCountryCode">
                                                                <div *ngFor="let country of allCountries">
                                                                    <mat-option [value]="country">
                                                                        +{{country.dial_code}}
                                                                    </mat-option>
                                                                </div>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div fxFlex="80" fxFlex.sm="75" fxFlex.xs="75"
                                                        fxLayoutAlign="center center">
                                                        <mat-form-field appearance="standard" style="width: 100%;">
                                                            <mat-label>Contact Number</mat-label>
                                                            <input required formControlName="mobile" type="tel" matInput
                                                                [maxlength]="mobileMax">
                                                            <mat-error
                                                                *ngIf="replyForm.controls.mobile.invalid && (replyForm.controls.mobile.touched || replyForm.controls.mobile.dirty)">
                                                                <mat-error
                                                                    *ngIf="replyForm.controls.mobile.errors.pattern"
                                                                    style="color:red;font-size:medium">
                                                                    Please enter Numbers only
                                                                </mat-error>
                                                                <mat-error
                                                                    *ngIf="replyForm.controls.mobile.errors.required">
                                                                </mat-error>
                                                                <mat-error
                                                                    *ngIf="replyForm.controls.mobile.errors.minlength">
                                                                </mat-error>
                                                                <mat-error
                                                                    *ngIf="replyForm.controls.mobile.errors.maxlength">
                                                                </mat-error>
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div fxLayout fxLayoutAlign="center center">
                                                    <div fxFlex="5" fxLayoutAlign="start center"></div>
                                                    <div fxFlex="95" fxLayoutAlign="start center">
                                                        <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                                            <mat-label>Email Id</mat-label>
                                                            <input required matInput type="email"
                                                                formControlName="email" matInput>
                                                            <mat-error></mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div fxLayout fxLayoutAlign="end center">
                                                    <div fxFlex="100" fxLayoutAlign="end center">
                                                        <button mat-button color="primary" style="border: 2px solid;" [disabled]="replyForm.invalid"
                                                            type="submit">REPLY
                                                            COMMENT</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <br>
                                    <div *ngIf="comment?.Reply?.length > 0">
                                        <div fxLayout fxLayoutAlign="start center" fxLayoutGap="20px"
                                            *ngFor="let reply of comment?.Reply">
                                            <div fxFlex="100" *ngIf="reply.status === 'active'">
                                                <div fxLayout fxLayoutAlign="start start" fxLayoutGap="10px">
                                                    <div>
                                                        <ngx-avatar [size]="mobileDevice ? '35' : '45'" initialsSize="1"
                                                            *ngIf="!reply?.user" [name]="reply?.admin?.name">
                                                        </ngx-avatar>
                                                        <ngx-avatar [size]="mobileDevice ? '35' : '45'" initialsSize="1"
                                                            *ngIf="reply?.user" [name]="reply.user.name">
                                                        </ngx-avatar>
                                                    </div>
                                                    <div fxFlex="100">
                                                        <div style="position: relative;" fxLayout
                                                            fxLayoutAlign="start center">
                                                            <div class="triangle-left23"></div>
                                                            <div fxLayout fxLayoutAlign="start center"
                                                                class="ReplyChat">
                                                                <p class="ptag"> {{reply.comment}} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div fxLayout fxLayoutAlign="start center" fxLayoutGap="20px">
                                                    <div fxFlex="100">
                                                        <div fxLayout fxLayoutAlign="start center" fxLayoutGap="20px">
                                                            <p class="chatName">
                                                                {{!reply.user ? reply?.admin?.name : reply?.user?.name}}
                                                            </p>
                                                            <span class="spacer"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="blogDivider">

                    </div>

                    <br>
                    <div fxLayout fxLayoutAlign="center center" fxLayoutGap="10px" fxLayout.sm="column"
                        fxLayout.xs="column">
                        <div fxFlex="10" fxFlex.sm="100" fxFlex.xs="100">
                        </div>
                        <div fxFlex="90" [style.width]="mobileDevice ? '80%' : '90%'">
                            <form [formGroup]="commentsForm" (ngSubmit)="submitForm()">
                                <div fxLayout fxLayoutAlign="start start">
                                    <div class="angular-with-newlines" fxFlex="100" fxLayoutAlign="start center">
                                        <mat-form-field [floatLabel]="'never'" style="width:100%;">
                                            <mat-label> Comment </mat-label>
                                            <textarea maxlength="500" matInput formControlName="query" required></textarea>
                                            <mat-error></mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout fxLayoutAlign="center center">
                                    <div fxFlex="100" fxLayoutAlign="start center">
                                        <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                            <mat-label> Name</mat-label>
                                            <input required formControlName="name" matInput minlength="3"
                                                maxlength="150">
                                            <mat-error
                                                *ngIf="commentsForm.controls.name.invalid && (commentsForm.controls.name.touched || commentsForm.controls.name.dirty)">
                                                <mat-error *ngIf="commentsForm.controls.name.errors.pattern"
                                                    style="color:red;font-size:small;font-weight:600">
                                                    Only Alphabets are allowed
                                                </mat-error>
                                                <mat-error>
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout fxLayoutAlign="center center">
                                    <div fxFlex="15" fxFlex.sm="25" fxFlex.xs="25" fxLayoutAlign="center center">
                                        <mat-form-field appearance="standard" style="width: 100%;">
                                            <mat-label>Country Code</mat-label>
                                            <mat-select required formControlName="country"
                                                (ngModelChange)="onCountryChange($event)"
                                                [(ngModel)]="defaultCountryCode">
                                                <div *ngFor="let country of allCountries">
                                                    <mat-option [value]="country">+{{country.dial_code}}
                                                    </mat-option>
                                                    <mat-divider></mat-divider>
                                                </div>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="85" fxFlex.sm="75" fxFlex.xs="75" fxLayoutAlign="center center">
                                        <mat-form-field appearance="standard" style="width: 100%;">
                                            <mat-label>Contact Number</mat-label>
                                            <input required formControlName="mobile" type="tel" matInput
                                                [maxlength]="mobileMax">
                                            <mat-error
                                                *ngIf="commentsForm.controls.mobile.invalid && (commentsForm.controls.mobile.touched || commentsForm.controls.mobile.dirty)">
                                                <mat-error *ngIf="commentsForm.controls.mobile.errors.required">
                                                </mat-error>
                                                <mat-error *ngIf="commentsForm.controls.mobile.errors.minlength">
                                                </mat-error>
                                                <mat-error *ngIf="commentsForm.controls.mobile.errors.maxlength">
                                                </mat-error>
                                                <mat-error *ngIf="commentsForm.controls.mobile.errors.pattern"
                                                    style="color:red;font-size:medium">
                                                    Please enter Numbers only
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>


                                <div fxLayout fxLayoutAlign="center center">

                                    <div fxFlex="100" fxLayoutAlign="start center">
                                        <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                            <mat-label>Email Id</mat-label>
                                            <input required matInput type="email" formControlName="email" matInput>
                                            <mat-error></mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout fxLayoutAlign="start center">
                                    <div fxFlex="100" fxLayoutAlign="end end">
                                        <button mat-raised-button color="primary" style="color:whitesmoke" [disabled]="commentsForm.invalid"
                                            type="submit"> <b>ADD COMMENT </b></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
                <br>
            </div>

            <div fxLayout fxLayoutAlign="end start" *ngIf="!mobileDevice" fxFlex.md="60" fxFlex="30">
                <div fxLayout fxFlex="100">
                    <div fxLayout fxLayoutAlign="center center" fxFlex="100">
                        <div fxFlex="100">
                            <div fxLayout fxLayoutAlign="start center">
                                <p class="serchHeadings">SEARCH POSTS</p>
                            </div>
                            <div class="blogDivider">

                            </div>
                            <p>&nbsp;</p>

                            <div fxLayout fxLayoutAlign="start center">
                                <div fxFlex="100" fxLayoutAlign="center center">
                                    <mat-form-field appearance="outline" class="Sarchmargin">
                                        <mat-label> Let's Search </mat-label>
                                        <input matInput autocomplete="off" type="search" [(ngModel)]="searchValue"
                                            id="search" name="search" (keyup)="searchBlogs(searchValue)">
                                        <mat-icon matSuffix *ngIf="searchValue == 'about'">search</mat-icon>
                                        <button *ngIf="searchValue" mat-button matSuffix mat-icon-button
                                            aria-label="Clear"
                                            (click)="searchBlogs(''); searchValue = ''; showPosts = false">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                        <mat-error>Not Found</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <br>
                            <div fxLayout fxLayoutAlign="start center">
                                <p class="serchHeadings">
                                    CATEGORIES
                                </p>
                            </div>
                            <div class="blogDivider">

                            </div>

                            <p>&nbsp;</p>

                            <div fxLayout fxLayoutAlign="start center">
                                <div fxFlex="100" fxLayoutAlign="start center">
                                    <div fxFlex="100">
                                        <div style="height: 180px; overflow: auto;" fxLayout="column">
                                            <button *ngIf="showPosts" mat-button class="example-item" fxLayout
                                                fxLayoutAlign="start center" (click)="resetFilter()">
                                                <mat-icon style="height: 8px; width: 8px; font-size: 8px !important;"
                                                    mat-list-icon>close
                                                </mat-icon>
                                                &nbsp;
                                                <span mat-line>Reset Filter</span>
                                            </button>
                                            <button mat-button class="example-item" fxLayout
                                                fxLayoutAlign="start center"
                                                (click)="showAll(allBlogs)">
                                                <mat-icon style="height: 8px; width: 8px; font-size: 8px !important;"
                                                    mat-list-icon>panorama_fish_eye
                                                </mat-icon>
                                                &nbsp;
                                                <span mat-line>All</span>
                                            </button>
                                            <button mat-button *ngFor="let category of allcategory" class="example-item"
                                                fxLayout fxLayoutAlign="start center" (click)="filterByCat(category)">
                                                <mat-icon style="height: 8px; width: 8px; font-size: 8px !important;"
                                                    mat-list-icon>panorama_fish_eye
                                                </mat-icon>
                                                &nbsp;
                                                <span mat-line>{{category.title}}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>&nbsp;</p>
                            <div fxLayout fxLayoutAlign="start center">
                                <p class="serchHeadings">
                                    RECENT POSTS
                                </p>
                            </div>
                            <div class="blogDivider">

                            </div>
                            <br>
                            <div fxLayout="column" fxLayoutGap="15px">
                                <div style="padding: 10px;" hovFocus fxLayout fxLayoutGap="10px"
                                    fxLayoutAlign="start center" *ngFor="let blog of recentBlogs;"
                                    (click)="openBlog(blog)">
                                    <img class="preview-cover" [src]="imageUrl + blog?.cover" alt="b">
                                    <div fxFlex="70" fxLayout="column" fxLayoutGap="0px" fxFlex="100">
                                        <h3 class="mat-h3"><b style="font-weight: 500;">{{blog?.title}}</b></h3>
                                        <div fxLayout fxLayoutGap="15px" fxLayoutAlign="start center">
                                            <mat-icon>people_outline</mat-icon>
                                            <div class="cut-text">By {{blog?.staff.name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div fxLayout fxLayoutAlign="center center" *ngIf="mobileDevice">
                <div fxFlex="100" fxLayout="column" fxLayoutAlign="start start">
                    <div fxLayout fxLayoutAlign="start center">
                        <p class="serchHeadings">
                            CATEGORIES
                        </p>
                    </div>
                    <div class="blogDivider">

                    </div>
                    <p>&nbsp;</p>
                    <div style="width: 100%;" fxLayout fxLayoutAlign="start center">
                        <div fxFlex="100" fxLayoutAlign="start center">
                            <div fxFlex="100">
                                <div style="height: 180px; overflow: auto;" fxLayout="column">
                                    <button *ngIf="showPosts" mat-button class="example-item" fxLayout
                                        fxLayoutAlign="start center" (click)="resetFilter()">
                                        <mat-icon style="height: 8px; width: 8px; font-size: 8px !important;"
                                            mat-list-icon>close
                                        </mat-icon>
                                        &nbsp;
                                        <span mat-line>Reset Filter</span>
                                    </button>
                                    <button mat-button class="example-item" fxLayout fxLayoutAlign="start center"
                                        (click)="showAll(allBlogs)">
                                        <mat-icon style="height: 8px; width: 8px; font-size: 8px !important;"
                                            mat-list-icon>panorama_fish_eye
                                        </mat-icon>
                                        &nbsp;
                                        <span mat-line>All</span>
                                    </button>
                                    <button mat-button *ngFor="let category of allcategory" class="example-item"
                                        fxLayout fxLayoutAlign="start center" (click)="filterByCat(category)">
                                        <mat-icon style="height: 8px; width: 8px; font-size: 8px !important;"
                                            mat-list-icon>panorama_fish_eye
                                        </mat-icon>
                                        &nbsp;
                                        <span mat-line>{{category.title}}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                    <div fxLayout fxLayoutAlign="start center">
                        <p class="serchHeadings">
                            RECENT POSTS
                        </p>
                    </div>
                    <div class="blogDivider">

                    </div>
                    <br>
                    <div class="full-width" fxLayout="column" fxLayoutGap="15px">
                        <div style="padding: 10px;" hovFocus fxLayout fxLayoutGap="10px" fxLayoutAlign="start center"
                            *ngFor="let blog of recentBlogs;" (click)="openBlog(blog)">
                            <img class="preview-cover" [src]="imageUrl + blog?.cover" alt="b">
                            <div fxFlex="70" fxLayout="column" fxLayoutGap="0px" fxFlex="100">
                                <h3 class="mat-h3"><b style="font-weight: 500;">{{blog?.title}}</b></h3>
                                <div fxLayout fxLayoutGap="15px" fxLayoutAlign="start center">
                                    <mat-icon>people_outline</mat-icon>
                                    <div class="cut-text">By {{blog?.staff.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    </div>
</div>



<div style="display: none;" id="b-content"></div>