<!--<div class="full-width">
    <mat-toolbar>
		<img src="https://v2.aptstudy.com/wp-content/uploads/2018/11/logo.png" height="50">
    </mat-toolbar>
    <div class="full-width" fxLayout="column" fxLayoutAlign="center center">
        <div fxFlex="80" fxFlex.xs="96" fxFlex.sm="90" fxLayout="column" fxLayoutAlign="center center">
            <div class="inner-text">
                <h1 class="mat-display-1" style="color:blue;font-size:xx-large">RESULT</h1>

                <div fxLayout="column" fxLayoutColumn="center center">
                    <p class="mat-body">{{msg}}</p>
                </div>
            </div>
        </div>
    </div>            
</div> -->
<div fxLayout="column" class="full-width" (window:resize)="getWidth($event)">
    <div style="padding: 20px 0;" fxLayout fxLayoutAlign="center center">
        <div fxFlex="80" fxLayout>
            <img [src]="dir + logo" height="75">
        </div>
    </div>
    <div fxFlex="500px" fxFlex.xs="350px" fxFlex.md="450px" fxFlex.sm="400px" fxLayout fxLayoutAlign="center center">
        <div fxFlex="70" fxFlex.xs="96" fxFlex.sm="85" fxFlex.md="80" class="full-width" fxLayout="column"
            fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="center center">
                <div class="inner-text">
                    <h1 [style.color]="resultResponse?.resultTitle[0].color"
                        [class]="mobileDevice ? 'mat-display-3' : 'mat-display-4'">
                        {{resultResponse?.resultTitle[0].text}}
                    </h1>
                </div>
            </div>
            <div fxLayout.xs="column" fxLayout.sm="column" fxLayout fxLayoutAlign="center center" fxLayoutGap="80px">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
                    <div class="inner-text">
                        <p class="mat-body text-c">{{this.resultResponse?.resultMessage}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="no-data">

</div>
<div style="height: 50px;" fxLayout fxLayoutAlign="center center" class="footer">
    <span style="color: #bcbcbc">&#9400; &nbsp;</span>
    <p class="footer-text"> Copyright APT. Handcrafted by <a href="https://eulogik.com" target="_blank">Eulogik</a>,
        India &nbsp;</p><span style="font-size: 16px; height: 16px; color: #fff;">&#10084;</span>
</div> 