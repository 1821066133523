<!--<mat-toolbar style="background: white;" fxLayout fxLayoutAlign="space-between center">
    <p class="primary">LEAVE TEST?</p>
    <button mat-icon-button matDialogClose>
        <mat-icon color="primary">close</mat-icon>
    </button>
</mat-toolbar>
<mat-divider></mat-divider>
<div class="padding">
    <p class="mat-h3">Do your really want to quit test?</p>
    <div fxLayout fxLayoutAlign="end center" fxLayoutGap="10px">
        <button mat-stroked-button matDialogClose color="primary">NO</button>
        <button mat-raised-button color="primary" (click)="yes()"><span style="color: white;">YES & SUBMIT TEST</span></button>
    </div>
</div> -->
<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
    <p class="mat-body-strong">{{data.message}}</p>
</div>
<div mat-dialog-actions align="end">
    <button *ngIf="data.falsy" mat-button matDialogClose>{{data.falsy}}</button>
    <button mat-flat-button color="primary" matDialogClose="true"><b style="color: white;">{{data.action}}</b></button>
</div> 