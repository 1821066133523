<!-- <br><br> -->
<div fxLayout="column">
    <div class="no-data" *ngIf="!productData || !allClassData" fxLayoutAlign="center center" fxFlex>
        <ngx-loading [show]="true"
            [config]="{backdropBackgroundColour: 'rgba(0, 0, 0, 0)', primaryColour: '#54ACE4' , secondaryColour: '#2486C5', tertiaryColour:  '#0C5C8F' }">
        </ngx-loading>
    </div>

    <div fxLayout fxLayoutAlign="center center" *ngIf="productData && allClassData" (window:resize)="getWidth($event)"
        class="full-width">
        <div fxLayout="column" fxLayoutGap="80px" fxFlex="80" fxFlex.xs="94" fxFlex.md="85" fxFlex.sm="90">
            <div class="full-width no-margin" style="padding: 20px ;" fxLayout fxLayoutAlign="center center">
                <h1 class="mat-display-1">
                    <b>{{ClassName}}</b>
                </h1>
            </div>

            <div style="height: 200px;" fxLayout fxLayoutAlign="center center"  class="full-width" *ngIf="!productData?.productContent && !productData?.courseVideoContent">
                <h2 class="mat-h2">Product data not found!</h2>
            </div>

            <div fxLayout="column" fxLayoutGap="50px" class="full-width" *ngIf="productData?.productContent && productData?.courseVideoContent">
                <div class="full-width" fxLayout fxLayoutAlign="center center" fxLayoutAlign.xs="start start"
                    fxLayoutAlign.sm="start start" fxLayoutGap="20px" fxLayout.sm="column" fxLayout.xs="column">
                    <div fxLayout fxFlex="33" fxLayoutAlign="start start" fxFlex.sm="100" fxFlex.xs="100"
                        *ngFor="let feature of features;let k = index;">
                        <div fxFlex="100" fxLayout fxLayoutAlign="center center" fxLayoutGap="10px">
                            <div fxFlex="5" fxLayoutAlign="center center">
                                <p style="margin-bottom: 0;" class="Number"> {{k+1}}. </p>
                            </div>
                            <div fxFlex="5" fxLayoutAlign="start center">
                                <div class="verticalLine"> </div>
                            </div>
                            <div fxFlex="85" fxLayoutAlign="start center">
                                <p class="mat-body">
                                    {{feature}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="full-width" fxLayout fxLayoutGap="20px" fxLayout.lt-md="column" fxLayoutAlign="center">
                    <div class="full-width" fxLayoutGap="15px" fxLayout="column" fxLayoutAlign="center center">
                        <img [src]="imageUrl + img" alt="not-found" height="225">
                        <div *ngIf="allClassData?.ratingCount" class="full-width" fxLayout fxLayoutGap="15px"
                            fxLayoutAlign="center center">
                            <mat-icon style="color: #0C5C8F;" *ngFor="let star of showRating(allClassData?.ratingValue)">
                                {{star}}</mat-icon>
                        </div>
                    </div>
                    <div class="full-width" fxLayout="column" fxLayoutAlign.lt-md="center center" fxLayoutAlign="start"
                        fxLayoutGap="20px">
                        <h2 class="mat-h2"><b>{{productData?.productContent?.title}}</b></h2>
                        <p class="mat-body-strong">{{productData?.productContent?.description}}</p>
                        <div>
                            <button mat-raised-button color="primary" (click)="BuyNow()">
                                <b class="whiteText">BUY NOW</b>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="full-width" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
                    <div class="full-width" fxLayoutAlign="center center">
                        <div fxFlex="70" fxFlex.sm="100" fxFlex.xs="100">
                            <iframe [src]="link" [height]="mobileDevice ? '250px' : '450px'" width="100%"></iframe>
                        </div>
                    </div>
                    <div class="full-width" fxLayout fxLayoutAlign.lt-md="center center" fxLayoutAlign="center"
                        fxLayoutGap="20px">
                        <div fxFlex="70" fxFlex.sm="100" fxFlex.xs="100">
                            <h2 class="mat-h2 text-c"><b>{{productData?.courseVideoContent?.title}}</b></h2>
                            <p class="mat-body-strong">{{productData?.courseVideoContent?.description}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="full-width" fxLayout="column" fxLayoutAlign="start center">
                <h3 class="mat-h3"><b>Fix An Appointment</b></h3>
                <div class="full-width" fxLayout fxLayoutAlign="center">
                    <form fxFlex="40" fxFlex.lt-md="90" fxFlex.md="60" fxLayout="column" fxLayoutGap="20px"
                        [formGroup]="appointmentsForm" (ngSubmit)="submitForm()">

                        <mat-form-field [floatLabel]="'never'">
                            <mat-label> Name</mat-label>
                            <input required formControlName="name" matInput minlength="3" maxlength="150">
                            <mat-error *ngIf="appointmentsForm.controls.name?.errors?.required"> Name is required
                            </mat-error>
                            <mat-error *ngIf="appointmentsForm.controls.name?.errors?.pattern">
                                Only alphabets are allowed
                            </mat-error>
                        </mat-form-field>

                        <div class="full-width" fxLayout fxLayoutAlign="start center">
                            <mat-form-field [floatLabel]="'never'" fxFlex="20" appearance="standard">
                                <mat-label>Country Code</mat-label>
                                <mat-select required formControlName="country" [(ngModel)]="defaultCountry"
                                    (ngModelChange)="onChange($event)">
                                    <div *ngFor="let contry of AllCoutryName">
                                        <mat-option [value]="contry">+{{contry.dial_code}}</mat-option>
                                        <mat-divider></mat-divider>
                                    </div>
                                </mat-select>
                                <mat-error></mat-error>
                            </mat-form-field>
                            <mat-form-field [floatLabel]="'never'" style="line-height: 1.9;" fxFlex="80">
                                <mat-label>Contact Number</mat-label>
                                <input required formControlName="mobile" type="tel" matInput [maxlength]="mobileMax">
                                <mat-error *ngIf="appointmentsForm.controls.mobile?.errors?.required">
                                    Contact Number is required
                                </mat-error>
                                <mat-error *ngIf="appointmentsForm.controls.mobile?.errors?.maxlength">
                                    Contact number length not valid
                                </mat-error>
                                <mat-error *ngIf="appointmentsForm.controls.mobile?.errors?.pattern">
                                    Invalid contact number
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <mat-form-field [floatLabel]="'never'">
                            <mat-label>Email Id</mat-label>
                            <input required type="email" formControlName="email" matInput>
                            <mat-error *ngIf="appointmentsForm.controls.email?.errors?.required">
                                Email is required
                            </mat-error>
                            <mat-error *ngIf="appointmentsForm.controls.email?.errors?.pattern">
                                Invalid email address
                            </mat-error>
                        </mat-form-field>

                        <div fxLayout fxLayoutAlign="center center">
                            <button mat-flat-button color="primary" type="submit">
                                <b class="whiteText">FIX APPOINTMENT</b>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
    <br>
    <!-- app downloads section -->
    <app-download-app [from]="ClassName" [source]="utm_source" [medium]="utm_medium" [campaign]="campaignType"
        [publisher]="publisherId">
    </app-download-app>

    <!-- TOPPER SPEAKS BELOW -->

    <app-topper-speaks *ngIf="classData1?.toppers?.length" [toppersData]="classData1" [topperImage]="TooperimageUrl" [mobileDevice]="mobileDevice">
    </app-topper-speaks>
</div>