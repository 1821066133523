import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogs-home',
  templateUrl: './blogs-home.component.html',
  styleUrls: ['./blogs-home.component.scss']
})
export class BlogsHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
