import { Component, Inject, OnInit } from '@angular/core';
import { base_url } from '../../../service/config';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { GetDataServiceService } from '../../../service/get-data-service.service';
import { ActivatedRoute, Router } from '@angular/router'
import { DOCUMENT, Location } from "@angular/common";
import { NotRegisterComponent } from '../../../not-register/not-register.component';
import { MatDialog } from '@angular/material/dialog';
import { InvalidCampaignComponent } from '../../invalid-campaign/invalid-campaign.component';
const EMAIL_REGEX = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

@Component({
  selector: 'app-test-camp-main',
  templateUrl: './test-camp-main.component.html',
  styleUrls: ['./test-camp-main.component.scss']
})
export class TestCampMainComponent implements OnInit {

  id: any
  normalCampaignData: any;
  dir = base_url + 'uploads/testCampaign/download/';
  mobileDevice: boolean = false;
  mobileNumber: number;
  public utm_source: string = '';
  public utm_medium: string = '';
  public campaignType: string = '';
  public publisherId: string = '';
  public invalidcampaign: boolean = true;
  campForm = new FormGroup({});
  formLoaded: boolean = false;
  public minLength: number;
  public maxLength: number = 10;

  isObject(val): boolean {
    return typeof val === 'object';
  }

  isString(val): boolean {
    return typeof val === 'string';
  }

  formData: any = [
    {
      key: "name",
      label: "Name",
      required: true,
      subtype: "text",
      type: "Textinput"
    },
    {
      key: "country",
      label: "Country",
      multiple: false,
      options: ["India", "UAE", "Bangladesh", "Singapore", "Nepal"],
      required: true,
      subtype: "single",
      type: "Dropdown"
    },
    {
      key: "city",
      label: "City",
      required: true,
      subtype: "text",
      type: "Textinput"
    },
    {
      key: "mobile",
      label: "Contact Number",
      required: true,
      subtype: "number",
      type: "Textinput"
    },
    {
      key: "email",
      label: "Email",
      required: true,
      subtype: "email",
      type: "Textinput"
    }
  ]

  constructor(public ps: GetDataServiceService, public route: ActivatedRoute, public location: Location,
    public router: Router, public dialog: MatDialog, @Inject(DOCUMENT) private document: Document) {
    this.id = this.route.snapshot.queryParamMap.get('id')
    this.utm_source = this.route.snapshot.queryParamMap.get('utm_source');
    if (this.utm_source !== null) {
      this.utm_medium = this.route.snapshot.queryParamMap.get('utm_medium');
      this.campaignType = this.route.snapshot.queryParamMap.get('campaigntype');
      this.publisherId = this.route.snapshot.queryParamMap.get('publisherid');
    } else {

    }
    this.getData(this.id)
  }

  ngOnInit(): void {
    this.getWidth()
    this.deleteStorages()
  }

  deleteStorages(){
    sessionStorage.removeItem('rollAnswer');
    sessionStorage.removeItem('counter');
    sessionStorage.removeItem('testDetails');
    sessionStorage.removeItem('totalQues');
    sessionStorage.removeItem('refreshed');
    sessionStorage.removeItem('campId');
  //  sessionStorage.removeItem('testLogo');
    sessionStorage.removeItem('canActivate');
    sessionStorage.removeItem('Succ');
  }


  getNormalCampaign(id) {
    let url = base_url + 'TestCampaigns/';
    return this.ps.getData(url + id)
  }

  getForm(id) {
    let url = base_url + 'Forms/'
    let filter = '?filter={"where": {"campaignId": "' + id + '"}}'
    return this.ps.getData(url + filter)
  }


  createForm(data) {
    data.forEach((element, index) => {
      this.campForm.addControl(
        element.key, element.key === 'email' ? (
          new FormControl("", element.required ? [Validators.pattern(EMAIL_REGEX)] : [])) : (
        new FormControl("", element.required ? [Validators.required] : []))
      );
      if (index === data.length - 1) {
        this.formLoaded = true
      }
    });
  }

  countryCheck(country) {
    if (country === 'country') {
      if (this.campForm.value.country === "India") {
        console.log(this.campForm.value.country)
        this.minLength = 10;
        this.maxLength = 10;
      }
      else {
        console.log(this.campForm.value.country)
        this.minLength = 5;
        this.maxLength = 16;
      }
    }
  }

  getData(id) {
    this.getNormalCampaign(id).subscribe((data: any) => {
      console.log('Test Campaign Data', data);
      if (data.statusCode !== 200 || !data.data || !data.data.status || data.data.status !== 'published') {
        this.invalidcampaign = false;
        const dialogRef1 = this.dialog.open(InvalidCampaignComponent, {
          width: '500px',
          disableClose: true,
          data: { screen: " Invalid Campaign", action: "", heading: "This campaign is not available" }
        }).afterClosed().subscribe(e => {
          this.redirect();
        });
      }
      else if ((data.statusCode === 200 && !data.data) || (data.statusCode === 200 && !data.data.status) || (data.statusCode === 200 && data.data.status !== 'published')) {
        this.invalidcampaign = false;
        const dialogRef1 = this.dialog.open(InvalidCampaignComponent, {
          width: '500px',
          disableClose: true,
          data: { screen: " Invalid Campaign", action: "", heading: "This campaign is not available" }
        }).afterClosed().subscribe(e => {
          this.redirect();
        });
      }
      else {
        this.normalCampaignData = data.data
        sessionStorage.setItem('testLogo', this.normalCampaignData.logo)
        this.getForm(id).subscribe((form: any) => {
          console.log('Form from Test Campaign', form)
          if (form.statusCode === 200) {
            if (form.data.length > 0) {
              this.formData = [...this.formData, ...form.data[0].requestData.fields]
              console.log(this.formData)
              this.createForm(this.formData)
              
            }
            else {
              this.createForm(this.formData)
            }
            if (form.statusCode === 200) {
              this.normalCampaignData.form = form.data[0]
              // setForm(form.data[0])
              console.log(this.normalCampaignData)
            }
          }
        })
      }
    })
  }

  redirect() {
    this.document.location.href = 'https://aptstudy.com/';
  }

  getWidth(event?) {
    this.mobileDevice = event ? event.target.innerWidth <= 959 ? true : false : window.innerWidth <= 959 ? true : false
  }

  submit() {
    let url = base_url + 'UserAccounts';
    let obj = {};
    if (this.utm_source !== null) {
      obj = {
        ...this.campForm.value,
        ...{
          username: this.campForm.value.mobile,
          campaignId: this.id,
          password: "apt#123",
          credits: 0,
          utm_medium: this.utm_medium,
          campaigntype: this.campaignType,
          publisherid: this.publisherId,
          utm_source: this.utm_source,
          source: this.campaignType,
        }
      };
    }
    else {

      obj = {
        ...this.campForm.value,
        ...{
          username: this.campForm.value.mobile,
          campaignId: this.id,
          credits: 0,
          password: "apt#123",
          source: this.campaignType,
        }
      };

    }
    console.log('The request is', obj);
    this.ps.PostData(obj, url).subscribe((response: any) => {
      console.log('The response is', response);
      if (response.statusCode === 200) {
        this.campForm.reset();
        this.router.navigate(['test-campaign/thank-you'], { queryParams: { name: this.campForm.value.name, id: this.id } });
      }
      else {
        console.log('Failed');
        this.dialog.open(NotRegisterComponent, {
          width: '500px',
          disableClose: true,
          data: { screen: "Not Registered", action: "", heading: "Thank You For Registering" }
        });
      }

    })
  }

}