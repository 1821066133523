<div class="no-data" *ngIf="showloader" fxLayoutAlign="center center" fxFlex>
    <ngx-loading [show]="showloader"
        [config]="{ backdropBackgroundColour: 'rgba(0, 0, 0, 0)', primaryColour: '#54ACE4' , secondaryColour: '#2486C5', tertiaryColour:  '#0C5C8F' }">

    </ngx-loading>
</div>

<div *ngIf="!showloader">
    <div fxLayout fxLayoutAlign="start center">
        <div fxFlex="100">
            <div fxLayout fxLayoutAlign="center center">
                <h1 class="mat-h1 text-c"><b>Work as a language translator</b></h1>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div fxLayout fxLayoutAlign="start center " fxLayout.sm="column" fxLayout.xs="column" class="MainDiv">
        <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
            <div fxLayout fxLayoutAlign="center center">
                <img src="assets/images/language.png" class="marginButtom" alt="home">
            </div>
        </div>
    </div>
    <div fxLayout fxLayoutAlign="start center " fxLayout.sm="column" fxLayout.xs="column" class="MainDiv">
        <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
            <div fxLayout fxLayoutAlign="start center">
                <div fxFlex="100">
                    <div fxLayout fxLayoutAlign="center center">
                        <p class="courseHeading">
                            About Work as a language translator
                        </p>
                    </div>
                    <br>
                    <div fxLayout fxLayoutAlign="center center">
                        <h4 fxFlex="60" fxFlex.sm="75" fxFlex.xs="96" class="courseDis text-c">
                            Our courses are designed to clarify all the topics, be it basic or advanced.
                            We make sure that students get basic strength that they need in all subjects
                            by giving them the highest quality of content available.
                            There is no point in building a monument on a weak foundation.
                        </h4>
                    </div>
                    <div fxLayout fxLayoutAlign="center center">
                        <h4 fxFlex="60" fxFlex.sm="75" fxFlex.xs="96" class="courseDis text-c">
                            When you provide the service at the root level, Angular creates a single,
                            shared instance of HeroService and injects into any class that asks for it.
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div fxLayout fxLayoutAlign="start center">
        <div fxFlex="100">
            <br><br>
            <div fxLayout fxLayoutAlign="start center">
                <div fxFlex="100">
                    <div fxLayout fxLayoutAlign="center center">
                        <p class="registraion"> Register Now </p>
                    </div>
                </div>
            </div>
            <br><br>
            <div fxLayout fxLayoutAlign="start center">
                <div fxFlex="100" fxLayoutAlign="center center">

                    <form fxFlex="60" fxFlex.sm="75" fxFlex.xs="96" [formGroup]="LanguageForm" (ngSubmit)="SAVEDATA()">
                        <div fxLayout fxLayoutAlign="center center">
                            <div fxFlex="100" fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                    <mat-label> Name</mat-label>
                                    <input required formControlName="name" required matInput minlength="3"
                                        maxlength="50">
                                    <mat-error
                                        *ngIf="LanguageForm.controls.name.invalid && (LanguageForm.controls.name.touched || LanguageForm.controls.name.dirty)">
                                        <mat-error *ngIf="LanguageForm.controls.name.errors.pattern"
                                            style="color:red;font-size:small;font-weight:600">
                                            Only Alphabets are allowed
                                        </mat-error>
                                        <mat-error>
                                        </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout fxLayoutAlign="center center">
                            <div fxFlex="15" fxFlex.sm="25" fxFlex.xs="25" fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                    <mat-label>Country Code</mat-label>
                                    <mat-select required formControlName="country" (ngModelChange)="onChange($event)"
                                        [(ngModel)]="defaultCountryCode">
                                        <div *ngFor="let contry of AllCoutryName">
                                            <mat-option [value]="contry">+{{contry.dial_code}}</mat-option>
                                            <mat-divider></mat-divider>
                                        </div>
                                    </mat-select>
                                    <!-- <mat-error>Country name is Required</mat-error> -->
                                </mat-form-field>
                            </div>
                            <div fxFlex="85" fxFlex.sm="75" fxFlex.xs="75" fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                    <mat-label>Contact Number</mat-label>
                                    <input formControlName="mobile" required type="tel" matInput numbersOnly="true"
                                        [maxlength]="mobileMax">
                                    <mat-error
                                        *ngIf="LanguageForm.controls.mobile.invalid && (LanguageForm.controls.mobile.touched || LanguageForm.controls.mobile.dirty)">
                                        <mat-error *ngIf="LanguageForm.controls.mobile.errors.required"></mat-error>
                                        <mat-error *ngIf="LanguageForm.controls.mobile.errors.minlength">
                                        </mat-error>
                                        <mat-error *ngIf="LanguageForm.controls.mobile.errors.maxlength">

                                        </mat-error>
                                        <mat-error *ngIf="LanguageForm.controls.mobile.errors.pattern">
                                        </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout fxLayoutAlign="center center">
                            <div fxFlex="100" fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                    <mat-label>Email</mat-label>
                                    <input type="email" formControlName="email" required matInput>
                                    <mat-error
                                        *ngIf="LanguageForm.controls.email.invalid && (LanguageForm.controls.email.touched || LanguageForm.controls.email.dirty )"
                                        style="color: #f44336">
                                        <mat-error *ngIf="LanguageForm.controls.email.errors.required"> </mat-error>
                                        <mat-error *ngIf="LanguageForm.controls.email.errors.pattern"> </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout fxLayoutAlign="center center">
                            <div fxFlex="100" fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                    <mat-label>State</mat-label>
                                    <mat-select required formControlName="state" *ngIf="flag">
                                        <div *ngFor="let state of allState">
                                            <mat-option [value]="state.state">{{state.state}}</mat-option>
                                            <mat-divider></mat-divider>
                                        </div>
                                    </mat-select>
                                    <input matInput formControlName="state" *ngIf="!flag">
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="!flag">
                            <h5>*Not required for people registering outside India</h5>
                        </div>
                        <div fxLayout fxLayoutAlign="center center">
                            <div fxFlex="100" fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                    <mat-label>City</mat-label>
                                    <input formControlName="city" matInput required maxlength="150">
                                    <mat-error></mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout fxLayoutAlign="center center">
                            <div fxFlex="100" fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                    <mat-label>Language Comfort</mat-label>
                                    <!-- <input required formControlName="comfortLanguage" matInput >
                                    <mat-error>Comfort Language is Required</mat-error> -->
                                    <mat-select required formControlName="comfortLanguage" multiple>
                                        <!-- <div *ngFor="let state of allState.states"> -->
                                        <div *ngFor="let lan of AllLanguage">
                                            <mat-option [value]="lan.title">
                                                {{lan.title}} </mat-option>
                                            <mat-divider></mat-divider>
                                        </div>
                                    </mat-select>
                                    <mat-error></mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout fxLayoutAlign="center center">
                            <div fxFlex="100" fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                    <mat-label>Highest Qualification</mat-label>
                                    <input formControlName="qualification" required matInput>
                                    <mat-error></mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout fxLayoutAlign="center center">
                            <div fxFlex="100" fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                    <mat-label>Experience As a Translator</mat-label>
                                    <!-- <input required formControlName="exprience" matInput > -->
                                    <mat-select required formControlName="exprience">
                                        <!-- <div *ngFor="let state of allState.states"> I am a student-->
                                        <mat-option value="Less than 1 yr">Less than 1 yr</mat-option>
                                        <mat-divider></mat-divider>
                                        <mat-option value="1-3 yrs">1-3 yrs</mat-option>
                                        <mat-divider></mat-divider>
                                        <mat-option value="More than 3yrs">More than 3yrs</mat-option>
                                        <mat-divider></mat-divider>
                                        <!-- </div> -->
                                    </mat-select>
                                    <mat-error></mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout fxLayoutAlign="center center">
                            <div fxFlex="100" fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                    <mat-label>Suitable Time For Contact</mat-label>
                                    <mat-select required formControlName="TimeContact">
                                        <!-- <div *ngFor="let state of allState.states"> -->
                                        <mat-option value=" 8am - 10am">8am - 10am</mat-option>
                                        <mat-divider></mat-divider>
                                        <mat-option value="10am - 6pm">10am - 6pm</mat-option>
                                        <mat-divider></mat-divider>
                                        <mat-option value="6pm - 9pm">6pm - 9pm</mat-option>
                                        <mat-divider></mat-divider>
                                        <!-- </div> -->
                                    </mat-select>
                                    <mat-error></mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout fxLayoutAlign="center center">
                            <div fxFlex="100" fxLayoutAlign="center center">
                                <button mat-raised-button color="primary" type="submit"
                                    [disabled]="LanguageForm.invalid" class="submitButton">SUBMIT</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
            <br><br>
        </div>
    </div>
    <br><br><br>
</div>



<br><br>