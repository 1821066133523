import { Injectable } from '@angular/core';
import { base_url } from '../../service/config';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  testDetails: any;
  userId: any;
  result: any;
  name: any;

  fileBaseURL = base_url+'uploads/testCampaign/download/';

  constructor() { }
}
