import { Component, OnInit } from '@angular/core';
import { GetDataServiceService } from '../../../service/get-data-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { base_url } from '../../../service/config';
import { DbService } from '../db.service';
import { AuthGuardGuard } from '../auth-guard.guard';
@Component({
  selector: 'app-test-camp-timer',
  templateUrl: './test-camp-timer.component.html',
  styleUrls: ['./test-camp-timer.component.scss']
})
export class TestCampTimerComponent implements OnInit {


  dir = base_url + 'uploads/testCampaign/download/';

  public id: string;
  public mobileDevice: boolean = false;
  public timerResponse: any;
  public timerTimeResponse: any;
  public startDate: Date;
  public CurrentTime: any;
  public hourLeft: any;
  public startForm: FormGroup;
  public timerOff: boolean = false;
  public dataLoaded: boolean;
  public daysLeft: any;
  public daysBool: boolean = false;
  public dayBool: boolean = false;
  isFormVisible: boolean = false
  minLength: number;
  maxLength: number;

  constructor(public ps: GetDataServiceService, public route: ActivatedRoute,
    public fb: FormBuilder, public router: Router, public matSnackBar: MatSnackBar, public db: DbService, public activate: AuthGuardGuard) {
    this.id = this.route.snapshot.queryParamMap.get('id');
    this.getTest();
    this.getWidth();
  }

  ngOnInit(): void {
    this.startForm = this.fb.group({
      mobile: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(16)]],
      rollNumber: ['', Validators.required],
      previousMarks: ['', Validators.required]
    });

  }
  get mobile() {
    return this.startForm.get('mobile');
  }
  get rollNumber() {
    return this.startForm.get('rollNumber');
  }
  get previousMarks() {
    return this.startForm.get('previousMarks');

  }
  getWidth(event?) {
    this.mobileDevice = event ? event.target.innerWidth <= 959 ? true : false : window.innerWidth <= 959 ? true : false
  }

dataLoaded1:boolean=false;
formData:any;

formsData: any = [
  {
    key: "name",
    label: "Name",
    required: true,
    subtype: "text",
    type: "Textinput"
  },
  {
    key: "country",
    label: "Country",
    multiple: false,
    options: ["India", "UAE", "Bangladesh", "Singapore", "Nepal"],
    required: true,
    subtype: "single",
    type: "Dropdown"
  },
  {
    key: "city",
    label: "City",
    required: true,
    subtype: "text",
    type: "Textinput"
  },
  {
    key: "mobile",
    label: "Contact Number",
    required: true,
    subtype: "number",
    type: "Textinput"
  },
  {
    key: "email",
    label: "Email",
    required: true,
    subtype: "email",
    type: "Textinput"
  }
]

  getTest() {
    let url = base_url + 'TestCampaigns?filter={"where":{"id":"' + this.id + '"},"fields":["timerDescription","timerTitle", "logo"]}';
    let testUrl = base_url + 'Tests/?filter={"where":{"campaignId":"' + this.id + '"}}';
    
    
    this.ps.getData(url).subscribe((response: any) => {
      // console.log('TestCampaign---', response);
      this.timerResponse = response.data[0];
      sessionStorage.setItem('testLogo', this.timerResponse.logo)
      console.log('Timer Response is', this.timerResponse);
      this.ps.getData(testUrl).subscribe((data: any) => {
        this.dataLoaded = true;
        
        console.log('Data for Test time', data);
        this.timerTimeResponse = data.data[0];
        this.getForms(this.timerTimeResponse?.id);
        this.testLogic();
      })
    })
  }

  getForms(id:any){
    
    let formUrl=base_url+ '/Forms/?filter={"where": {"testId":"'+id+'"}}';
    this.ps.getData(formUrl).subscribe((data:any)=>{
      console.log(data);
      this.formData=data?.data[0]?.requestData?.fields;
  //    this.formData=[...this.formData,...this.formsData];
      if(this.formData?.length>0){
        this.formData?.forEach((e:any)=>{
          this.startForm.addControl(e?.key,new FormControl('',(e?.required)?[Validators.required]:[]));
          
        })
      }
    })
  }



  testLogic() {
    let url = base_url + 'RemoteMethods/currentTime?campaignId=' + this.id;
    this.ps.getData(url).subscribe((data: any) => {
      console.log(data);
    this.getInterval(data.data.response.timediff, data.data.response.endTime);  //CHANGE THIS BEFORE BUILD
    //  this.timerOff = true
    })

  }
  timer: any;
  getInterval(startTime, endTime) {
    console.log(startTime, endTime)
    let i = 0;
    if (startTime <= 0 && endTime > 0) {
      this.timerOff = true;
      console.log('Start Time')
    }
    else {
      if (endTime <= 0) {
        //TEST START TIME IS OVER
      this.router.navigate(['thank-you'], { queryParams: { status: 'timeup' } })
        console.log('HELLO THIS IS BEYOND TIME')
      }
      else {
        // TIME LEFT FOR TEST
        this.timerOff = false;
        this.calculateTime(startTime); //THIS METHOD CALCULATES TIME LEFT FOR TEST
        console.log('End Time')
      }
    }
  }
  calculateTime(startTime) {
    let dif = 0;

    this.timer = () => {
      setInterval(() => {


        // Find the distance between now and the count down date
        var distance = startTime - dif;
        //    var distance=10800-dif;
        //    var distance=111110800-dif;

        // Time calculations for days, hours, minutes and seconds
        var days: any = Math.floor(distance / (1000 * 60 * 60 * 24));
        if (days > 0) {
          if (days == 1) {
            this.dayBool = true;
          }
          else {
            this.daysBool = true;
          }
        }
        else {
          this.daysBool = false;
        }
        if (days < 10) {
          days = '0' + days;
        }
        var hours: any = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        if (hours < 10) {
          hours = '0' + hours.toString();
        }
        var minutes: any = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        if (minutes < 10) {
          minutes = '0' + minutes;
        }
        var seconds: any = Math.floor((distance % (1000 * 60)) / 1000);
        if (seconds < 10) {
          seconds = '0' + seconds;
        }
        this.daysLeft = days;

        this.CurrentTime = hours + "H : " + minutes + "M : " + seconds + "S";
        dif += 1000;
        if (distance > 0) {
          // this.timerOff=true;
          this.timerOff = false;
        }
        else {
          this.timerOff = true;
          clearInterval(this.timer);
        }

      }, 1000)
    }
    if (!this.timerOff) {
      this.timer();
    }

  }
  testForm() {

  }

  countryCheck(country) {
    if (country === 'country') {
      if (this.startForm.value.country === "India") {
        console.log(this.startForm.value.country)
        this.minLength = 10;
        this.maxLength = 10;
      }
      else {
        console.log(this.startForm.value.country)
        this.minLength = 5;
        this.maxLength = 16;
      }
    }
  }

  isObject(val): boolean {
    return typeof val === 'object';
  }

  isString(val): boolean {
    return typeof val === 'string';
  }


  submit() {
    let url = base_url + 'RemoteMethods/getQuestions';
    /*
    let postData = {
      previousMarks: this.startForm.value.prevPercent + "",
      rollNumber: this.startForm.value.rollNo + "",
      mobile: this.startForm.value.contact + "",
      campaignId: this.id + "",
    } */
    let postData={...this.startForm.value,campaignId: this.id};
    console.log(this.startForm.value.prevPercent);
    sessionStorage.setItem('campId', this.id);
    this.ps.PostData(postData, url).subscribe((data: any) => {
      console.log('Response from post', data);
      if (data.statusCode === 200) {
        this.router.navigate(['test-campaign/instructions'], {
          queryParams: {
            roll: this.startForm.value.rollNumber,
            mobile: this.startForm.value.mobile, percentage: this.startForm.value.previousMarks, campaignId: this.id
          }
        })
      }
      else if (data.statusCode === 406) {
        this.matSnackBar.open('TEST ALREADY GIVEN', 'OK', {
          duration: 2000
        })
      }
      else if (data.statusCode === 408) {
        // USER LEFTS TEST IN BETWEEN AND RETURNED BEFORE THE TIME SPAN ENDS
        this.db.testDetails = data.data[0];
        this.db.userId = data.userId;
        this.activate.setCanActivate(true)
        this.router.navigate(['test-campaign/test-screen'], {
          queryParams: {
            roll: this.startForm.value.rollNumber,
            mobile: this.startForm.value.mobile, percentage: this.startForm.value.previousMarks, campaignId: this.id
          }
        })
        var time = data.time / (1000 * 60)
        if (time > 0) {
          this.matSnackBar.open('You still have ' + Math.floor(time).toString() + ' mins left', '', {
            duration: 2000
          })
        }
      }
      else {
        this.matSnackBar.open('USER NOT FOUND', 'OK', {
          duration: 2000
        })
      }
    })
  }
  
}