import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ActivationEnd } from "@angular/router";
import { GetDataServiceService } from '../../../service/get-data-service.service';
import { base_url, apt_url, Paytm_url } from "../../../service/config";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-paytm-gateway',
  templateUrl: './paytm-gateway.component.html',
  styleUrls: ['./paytm-gateway.component.scss']
})
export class PaytmGatewayComponent implements OnInit {
  public paymentURL: any;
  public amount: string = '';
  public classId: string = '';
  public fileId: string = '';
  public userId: string = '';
  public transectionType: string = '';
  public coupans: string = '';
  public url: string = '';
  public coupanId: string = '';
  public requestFrom: string = '';
  public productType: string = '';
  public subjectIds: string = '';
  public allSelected: string = '';

  constructor(public router: Router, public route: ActivatedRoute, private sanitizer: DomSanitizer) {
    this.transectionType = this.route.snapshot.queryParamMap.get('transactionType');
    if (this.transectionType == 'buyDownload') {
      this.fileId = this.route.snapshot.queryParamMap.get('fileId');
      this.classId = this.route.snapshot.queryParamMap.get('classId');
      this.amount = this.route.snapshot.queryParamMap.get('price');
      this.userId = this.route.snapshot.queryParamMap.get('userId');
    } else {
      this.classId = this.route.snapshot.queryParamMap.get('classId');
      this.amount = this.route.snapshot.queryParamMap.get('price');
      this.userId = this.route.snapshot.queryParamMap.get('userId');
      this.coupans = this.route.snapshot.queryParamMap.get('coupon');
      this.coupanId = this.route.snapshot.queryParamMap.get('couponId');
      this.subjectIds = this.route.snapshot.queryParamMap.get('subjectids');
      this.allSelected = this.route.snapshot.queryParamMap.get('allSelected');
    }

  }

  ngOnInit(): void {
    if (this.transectionType == 'buyDownload') {
      this.url = Paytm_url + 'downloadPaymentProcess?transactionType=buyDownload&&classId=' + this.classId + '&&fileId=' + this.fileId + '&&userId=' + this.userId + '&&credit=' + this.amount;
    } else {
      console.log(this.amount);
      if (this.allSelected == 'true') {
        this.url = Paytm_url + 'paymentProcess?transactionType=buyProduct&&productId=' + this.classId + '&&userId=' + this.userId + '&&credit=' + this.amount + '&&coupon=' + this.coupans + '&&couponId=' + this.coupanId + '&&requestFrom=aptwebsite&&productType=class';
      }
      else {
        this.url = Paytm_url + 'paymentProcess?transactionType=buyProduct&&productId=' + this.classId + '&&userId=' + this.userId + '&&credit=' + this.amount + '&&coupon=' + this.coupans + '&&couponId=' + this.coupanId + '&&requestFrom=aptwebsite&&productType=class&&subjectIds=' + this.subjectIds + '';
      }
    }


    console.log("paytm url ", this.url);
    this.paymentURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    console.log(this.paymentURL)
    setTimeout(() => {
      console.log(document.getElementById("paytmGate"));
      document.getElementById("paytmGate").click();
    }, 1000);
  }

}
