import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
	selector: "[hovFocus]",
	host: {
		"(mouseenter)": "onMouseEnter()",
		"(mouseleave)": "onMouseLeave()"
	}
})
export class HovFocusDirective {
	constructor(private _el: ElementRef) { }
	onMouseEnter() {
		this._el.nativeElement.classList.add('mat-elevation-z8')
		this._el.nativeElement.style.transition = '0.5s'
		this._el.nativeElement.style.borderRadius = '5px'
		this._el.nativeElement.style.cursor = 'pointer'
	}
	onMouseLeave() {
		this._el.nativeElement.classList.remove('mat-elevation-z8')
		this._el.nativeElement.style.borderRadius = '0px'
		this._el.nativeElement.style.cursor = 'normal'
	}
}
