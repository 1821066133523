import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ActivationEnd } from "@angular/router";
import { GetDataServiceService } from '../../../service/get-data-service.service';
import { base_url, apt_url, Paytm_url } from "../../../service/config";
import { MatSnackBar } from "@angular/material/snack-bar";
import { interval, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadsAppComponent } from '../../../dialog/downloads-app/downloads-app.component';
import { FormControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatOption } from '@angular/material/core/option';
import { MatSelect } from '@angular/material/select';
import { identifierModuleUrl, isNgTemplate } from '@angular/compiler';

const NUM_REGEX = /^[0-9]\d{9}$/g;
const NAME_REGEX = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
const EMAIL_REGEX = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

@Component({
  selector: 'app-payment-faild',
  templateUrl: './payment-faild.component.html',
  styleUrls: ['./payment-faild.component.scss']
})
export class PaymentFaildComponent implements OnInit {
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChild('subSelected') private subSelected: MatOption;
  @ViewChild('individual1') public indiv: MatSelect;

  public loading1 = true;
  public loading2 = true;
  public loading3 = true;

  public CoursePurchasesedForm: FormGroup;
  public classId: string = '';
  public ClassName: string = '';
  public userId: string = '';
  public allSubject: any = [];
  public CoursePrice: number = 0;
  public discountPrice: number;
  public SeniorBullet: any = ["Lorem ipsum, or lipsum as it is sometimes known.", " graphic or web designs.", "The purpose of lorem ipsum is to create.", "focus is meant to be on design, not content.", "The passage experienced"];
  public timerData: any = [];
  public ratingCount: number = 0;
  public utm_source: string = '';
  public utm_medium: string = '';
  public campaignType: string = '';
  public publisherId: string = '';
  public imageUrl: string = apt_url + 'uploads/Class/download/';
  public img: any;
  public credits: any = 0;
  public discPrice: any;
  public serverName: any;
  public bool: boolean;
  public coupcode: any;
  public coup: any;
  public count: number;
  /* ----------------------------------------------------------------------- */
  public productId: any;
  public orderId: any;
  public subjectIds: any = [];
  public selectedSubjectname: any = [];
  public validCount: number = 0;
  public paymentSubjectId: any = [];
  public rom: string = "[";
  public discountedPrice: number = 0;
  public allSubjectedSelected: boolean;
  public allSubjectunderline: boolean;
  public startAllSubject: boolean;
  public beforeCoupAmt: number;
  public couponCheck: boolean;
  public couponId: any;
  public couponAmount: number = 0;
  public couponDetail:any;
  public couponValue:number=0;
  public sendCouponValue:number;

  constructor(public fb: FormBuilder, public getTopicService: GetDataServiceService, public router: Router,
    public snakBar: MatSnackBar, public route: ActivatedRoute, public dialog: MatDialog) {
    this.orderId = this.route.snapshot.queryParamMap.get('orderId');

    this.utm_source = this.route.snapshot.queryParamMap.get('utm_source');
    //       console.log("subject name & id is here ",this.userId,this.classId);

    if (this.utm_source !== null) {
      //console.log("get more paramettre here ***********************");
      this.utm_medium = this.route.snapshot.queryParamMap.get('utm_medium');
      this.campaignType = this.route.snapshot.queryParamMap.get('campaigntype');
      this.publisherId = this.route.snapshot.queryParamMap.get('publisherid');
      // console.log("get more paramettre here ***********************", this.utm_medium, this.campaignType, this.publisherId);
    } else {
      // console.log("Do nothigs");
    }

  }

  ngOnInit(): void {
    //this.SettimeInterval();
    // this.inilizeForm();

    //   this.GetProductData();
    //    this.getAllSubject();

    this.failedPayment();

    //   this.getAllSubject();


  }
  failedPayment() {
    console.log('OrderId', this.orderId)
    let url = Paytm_url + 'api/Orders/' + this.orderId;
    console.log(url);
    this.getTopicService.getData(url).subscribe((data: any) => {
      console.log(data);
      this.classId = data.classId;
      this.productId = data.productId;
      this.userId = data.userId;
      this.couponId=data.couponId;
      this.couponDetail=data.couponDetail;
      this.loading1 = false;
      this.initialValue();
      if (!data.subjectIds || data.subjectIds == undefined) {
        this.startAllSubject = true;
        this.getAllSubject();
        this.getClassData();
      }
      else {
        this.subjectIds = data.subjectIds;
        this.loading1 = false;
        this.initialSubjectsId();
        console.log(this.subjectIds.length);
        console.log('SubjectIds', this.subjectIds);
        console.log('ClassId', this.classId);
        console.log('userId', this.userId);
        this.startAllSubject = false;
        this.getAllSubject();
        this.getClassData();
      }
    });

  }
  initialValue() {
    this.CoursePurchasesedForm = this.fb.group({
      course: new FormControl('', [Validators.required]),
      coupons: new FormControl(''),
    });
  }
  someMethod(event) {
    console.log("event value is", event);
    // event[0].
  }
  // devigate payments screen 
  PAYNOW() {
    let arr = this.CoursePurchasesedForm.value.course;

    if (arr.length > 0) {
      console.log("do nothigs", this.CoursePurchasesedForm.value);
      console.log('1st Router', this.rom)
      if (!this.couponCheck) {
      
        console.log('From', this.couponId);
        console.log('From', this.sendCouponValue);
        console.log('From', this.CoursePrice);
        this.router.navigate(['/downloads/paytm'], { queryParams: { classId:(this.classId)?this.classId:this.productId,userId:  this.userId, price: this.CoursePrice, coupon: '0' ,transactionType: 'buyproduct',couponId: '',subjectids:this.rom,allSelected:this.allSubjectedSelected} });
        console.log('PAYNOW() with ROM', this.rom);
      } else {
        console.log("send with coupans");
        console.log(this.couponId);
        console.log(this.sendCouponValue);
        console.log(this.CoursePrice);
        this.router.navigate(['/downloads/paytm'], { queryParams: { classId:(this.classId)?this.classId:this.productId, userId: this.userId, price: this.CoursePrice, coupon: this.sendCouponValue, transactionType: 'buyproduct', couponId: this.couponId, subjectids: this.rom, allSelected: this.allSubjectedSelected } });
      // this.router.navigate(['/course/payments-faild'], { queryParams: { id: "5fsf4sd54gdsg4ssdd",name: "8th Class" } });
    }}
    else {
      this.snakBar.open('You have not selected any subjects !', 'OK', {
        duration: 3000
      });
    }


  }



  public features: any = [];
  public contentTitle: string = '';
  public contentDescription: string = '';
  public rawImage: string = '';
  public courseVideoURL: string = '';
  public courseVideoTitle: string = '';
  public courseVideoDesc: string = '';
  public TooperimageUrl: string = apt_url + 'Uploads/products/download/'




  getAllSubject() {
    this.selectedSubjectname = [];
    let county = 0;
    let url = apt_url + 'Subjects/getAllSubject';
    let postData: any;
    if (this.startAllSubject) {
      console.log(this.startAllSubject)
      postData = {
        classId: this.productId,
        userId: this.userId
      };
    }
    else {
      postData = {
        classId: this.classId,
        userId: this.userId
      };
    }
    this.getTopicService.PostData(postData, url).subscribe((response: any) => {
      console.log("all subject id here data is **** ", response);
      this.loading2 = false;
      this.allSubject = response.data;
      this.loading3 = false;
      if (!this.startAllSubject) {
        this.allSubject.forEach(e => {
          if (e.order === false && this.subjectIds.includes(e._id)) {
            this.selectedSubjectname.push(e.name);
            this.credits += e.credits;
            this.discountedPrice += e.credits;
            this.CoursePrice += e.credits;
          };
      })
      this.assignOptions();
      }
      else {
        setTimeout(() => {
          this.allSubjectedSelected = true;
          this.allSelected.select();
          for (let i = 0; i < this.allSubject.length; i++) {
            this.indiv.options.forEach((e: MatOption) => {
              if (e.value == this.allSubject[i].name && this.allSubject[i].order === false) {
                e.select();
              }
            })
          }
          console.log("Check1");
          this.toggleAllSelection();
          console.log("Check2");
          this.initialCouponCheck();
        }, 0)

      }
      

    });
   
  }

  initialCouponCheck()
  {
    if(this.couponId)
      {
        console.log("Check");
        this.couponMessage = 'Applied'
        this.beforeCoupAmt = this.discountedPrice;
        this.couponAmount=this.couponDetail.amount;
        this.couponValue = (this.couponAmount / 100) * this.discountedPrice;
        this.sendCouponValue=this.couponValue;
  //      this.CoursePrice -= this.couponAmount;
  //      this.discountedPrice = this.beforeCoupAmt - this.couponAmount;
          this.discountedPrice-=this.couponValue;
        this.hasError = false;
        this.couponCheck = true;
        this.CoursePurchasesedForm.patchValue({
          coupons:this.couponDetail.code
        })
      }
  }

  initialSubjectsId() {
    console.log(this.subjectIds);
    this.rom = "[";
    for (let i = 0; i < this.subjectIds.length; i++) {
      if (this.subjectIds.length == (i + 1)) {
        this.rom = this.rom + `"` + this.subjectIds[i] + `"` + "]";
      }
      else {
        this.rom = this.rom + `"` + this.subjectIds[i] + `"` + ",";
      }
    }

  }

  assignOptions() {
    setTimeout(() => {
      console.log(this.selectedSubjectname);
      this.indiv.options.forEach((e: MatOption) => {
        if (this.selectedSubjectname.includes(e.value)) {
          e.select();
        }
      })
      this.initialCouponCheck();
    }, 0);   
  }
  toggleAllSelection() {
    if (this.allSelected.selected) {
      //       this.allSujectsSelected=true;
      this.indiv.options.forEach((item: MatOption) => {
        for (let i = 0; i < this.allSubject.length; i++) {
          if (item.value == this.allSubject[i].name && this.allSubject[i].order == false) {
            item.select();
          }
        }
      });
      this.getDiscount();
      this.allSubjectedSelected = true;
      this.allSubjectunderline = true;

    }
    else {
      this.indiv.options.forEach((e: MatOption) => {
        e.deselect();
        this.allSubjectedSelected = false;
        this.allSubjectunderline = false;
        this.discountedPrice = 0;
        this.credits = 0;
        if (this.couponCheck) {
          this.couponMessage = '';
          (<HTMLInputElement>document.getElementById("value")).value = "";
          this.afterCoupon = false;
          this.hasError = true;
          this.couponAmount = 0;
          this.couponCheck = false;
          this.couponId = '';
          this.sendCouponValue=0;
          this.couponValue=0;
        }
      })
    }
  }
  toggleOne(subject) {
    if (subject.order === false) {
      this.allSubjectedSelected = false;
      this.validCount = 0;
      this.allSubject.forEach((e: any) => {
        if (e.order === false) {
          this.validCount++;
        }
      });
      this.paymentSubjectId = [];
      this.getIndividualId();
      if (this.allSelected.selected) {
        this.allSelected.deselect();
        this.paymentSubjectId = [];
        this.allSubjectedSelected = false;
        this.allSubjectunderline = false;
      }
      if (this.CoursePurchasesedForm.value.course.length == this.validCount) {
        this.paymentSubjectId = [];
        this.allSelected.select();
        this.getDiscount()
        this.allSubjectedSelected = true;
        this.allSubjectunderline = true;
      }
      if (this.CoursePurchasesedForm.value.course.length === 0) {
        console.log('val');
        if (this.couponCheck) {
          this.couponMessage = '';
          (<HTMLInputElement>document.getElementById("value")).value = "";
          this.afterCoupon = false;
          this.hasError = true;
          this.couponAmount = 0;
          this.sendCouponValue=0;
          this.couponValue=0;
          this.couponCheck = false;
          this.couponId = '';
        }
      }
    }
  }
  getIndividualId() {
    this.paymentSubjectId = [];
    let products = this.CoursePurchasesedForm.value.course;
    let convert: string = ''; let sum = 0; this.credits = 0;
    this.rom = "[";
    for (let i = 0; i < products.length; i++) {
      for (let j = 0; j < this.allSubject.length; j++) {
        if (products[i] == this.allSubject[j].name) {
          sum = sum + this.allSubject[j].credits;
          convert = this.allSubject[j]._id.toString();
          console.log('convert value', convert);
          this.paymentSubjectId.push(convert);
        }
      }
    }
    this.credits = sum;
    if (this.couponCheck) {
      this.couponValue = (this.couponAmount / 100) * this.credits;
      this.discountedPrice = this.credits;
      this.CoursePrice = this.discountedPrice;
      this.sendCouponValue=this.couponValue;
      this.discountedPrice=this.credits-this.couponValue;
    }
    else {
      this.discountedPrice = this.credits;
      this.CoursePrice = this.discountedPrice;
    } 
  //  this.discountedPrice = this.credits;
  //  this.CoursePrice = this.credits;
    for (let i = 0; i < this.paymentSubjectId.length; i++) {
      if (this.paymentSubjectId.length == (i + 1)) {
        this.rom = this.rom + `"` + this.paymentSubjectId[i] + `"` + "]";
      }
      else {
        this.rom = this.rom + `"` + this.paymentSubjectId[i] + `"` + ",";
      }
    }
    console.log('Rom from individual', this.rom);
    console.log('Payment Subject Id from individual', this.paymentSubjectId);
  }

  getDiscount() {
    let discCount = 0; let counting = 0;
    //let products=this.purchaseForm.value.course;
    for (let i = 0; i < this.allSubject.length; i++) {
      if (this.allSubject[i].order == false) {
        discCount += this.allSubject[i].discountPrice;
      }
    }

    this.getIndividualId();
    if (this.couponCheck) {
      this.couponValue = (this.couponAmount / 100) * discCount;
      this.discountedPrice = discCount;
      this.CoursePrice = this.discountedPrice;
      this.sendCouponValue=this.couponValue;
      this.discountedPrice=discCount-this.couponValue;
    }
    else {
      this.discountedPrice = discCount;
      this.CoursePrice = this.discountedPrice;
    } 
  //  this.discountedPrice = discCount;
  //  this.CoursePrice = this.discountedPrice;
    //this.originalPrice=this.credits;
    console.log('Purchase value from getDiscount()', this.CoursePurchasesedForm.value.course);
    console.log('Payment Subject Id', this.paymentSubjectId);
  }

  afterCoupon: boolean;
  clickbutton: boolean;
  coupon(value) {
  
    let arr = this.CoursePurchasesedForm.value.course;
    if (arr.length > 0) {
      this.serverName = value;
      console.log(this.serverName);
      this.afterCoupon = false;
 //     this.discountedPrice = this.discountedPrice + this.couponAmount;
 //     this.couponAmount = 0;
      this.couponCheck = false;
      this.couponMessage='';
      this.couponId='';
      this.sendCouponValue=0;
      this.couponValue=0;
      // if (this.clickbutton === true) {
      //   document.getElementById("coupon1").innerHTML = "";
      //   document.getElementById("coupon2").innerHTML = "";
      //   document.getElementById("coupon3").innerHTML = "";
      //   this.clickbutton = false;
      // }
      // if (this.serverName.length >= 3)
      //   this.bool = true;
      // else
      //   this.bool = false;
    }
  
  }

  hasError: boolean;
  couponMessage: any = ''
  applyCoupon(value) {
    
      this.coupcode = value
      let data = {
        coupon: this.coupcode,
        userId: this.userId
      };
      let url = apt_url + 'Coupons/check?access_token=' + sessionStorage.getItem('aptAccessToken');
      this.getTopicService.PostData(data, url).subscribe((response: any) => {
        console.log("hello", response);
        if (response.statusCode === 200) {
         
          this.couponMessage = 'Applied'
     //     this.beforeCoupAmt = this.discountedPrice;
          this.couponId = response.data.id;
          this.couponAmount = response.data.amount;
          this.couponValue = (this.couponAmount / 100) * this.discountedPrice;
        this.sendCouponValue=this.couponValue;
   //       this.CoursePrice -= this.couponAmount;
   //       this.discountedPrice = this.beforeCoupAmt - this.couponAmount;
          this.hasError = false;
          this.couponCheck = true;
          this.discountedPrice-=this.couponValue;
          
        }
        else if(response.statusCode===402 && response.message==='coupon is already in used')
      {
        this.couponMessage = 'Coupon already used'
        this.hasError = true;
      }
      else if(response.statusCode===402 && response.message==='coupon is expired')
      {
        this.couponMessage = 'Coupon Expired'
        this.hasError = true;
      }
        else {
          this.couponMessage = 'Invalid Coupon'
          this.hasError = true;
        }
        this.coup = response.message;
        this.afterCoupon = true;
      }, err => {
        console.log(" error ", err);
      });
    // let url = apt_url + 'Coupons';
    
  }
 
  couponmessage() {
    this.clickbutton = true;
  }

  togglecoupon() {
    
    this.couponMessage = '';
    (<HTMLInputElement>document.getElementById("value")).value = "";
    this.afterCoupon = false;
    this.hasError = true;
//    this.discountedPrice = this.discountedPrice + this.couponAmount;
    this.couponAmount = 0;
    this.sendCouponValue=0;
    this.discountedPrice = this.discountedPrice + this.couponValue;
    this.couponCheck = false;
    
  }

  getClassData() {
    let url: any;
    if (this.startAllSubject) {
      url = apt_url + 'Classes?filter={"where": {"id": "' + this.productId + '"}}';
    }
    else {
      url = apt_url + 'Classes?filter={"where": {"id": "' + this.classId + '"}}';
    }

    this.getTopicService.getData(url).subscribe((response: any) => {
      console.log(response);
      let temp: any = response[0];
      this.ClassName = temp.name;
      this.img = temp.image;
      this.ratingCount = 5 //temp.ratingValue;
      this.loading1 = false;
      console.log('Loading1', this.loading1);
    }, err => {
      console.log("get about course content error ", err);
    });

  }

}