import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  constructor(public router: Router) { }
	private can: boolean = false;

	canActivate() {
		console.log('ActivateGuard#canActivate called, can: ', this.can);
		if(sessionStorage.getItem('canActivate') === 'true') {
			sessionStorage.setItem('canActivate', 'false')
			return true
		}
		else if(this.can) {
			console.log('Value of can',this.can);
			return true
		}
		else {
			if(sessionStorage.getItem('campId'))
			{
				this.router.navigate(['test-campaign/start-test'],{queryParams:{id:sessionStorage.getItem('campId')}})
			}
			else
			{
				this.router.navigate(['']);
			
			return false
		}
	}
}

	setCanActivate(can) {
		this.can = can;
	}
  
}
