import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { base_url } from '../../service/config';

@Component({
	selector: 'all-blogs',
	templateUrl: './all-blogs.component.html',
	styleUrls: ['./all-blogs.component.scss', '../blogs/blogs.component.scss']
})
export class AllBlogsComponent implements OnInit {

	@Input() allBlogs: any = [];
	@Output() open = new EventEmitter();

	public imageUrl: string = base_url + 'uploads/blogs/download/';

	constructor() { }

	ngOnInit(): void {
		this.getWidth();
	}
	

	mobileDevice: boolean = false
	getWidth() {
		this.mobileDevice = window.innerWidth < 600 ? true : false
	}

	readBlog(blog) {
		this.open.emit(blog)
	}


	returnValName(array: any, type: string) {
		var returnArray: any = [];
		if (type === 'cat') {
			if (array.length > 0) {
				for (let index = 1; index < array.length; index++) {
					returnArray.push(array[index].title)
				}
				return returnArray.toString().replace(/,/g, ", ")
			}
			else
				return ''
		}
		else {
			if (array.length > 0) {
				for (let index = 1; index < array.length; index++) {
					returnArray.push(array[index])
				}
				return returnArray.toString().replace(/,/g, ", ")
			}
			else
				return ''
		}
	}

}
