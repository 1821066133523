import { Component, HostListener, OnInit } from '@angular/core';
import { GetDataServiceService } from '../../service/get-data-service.service';
import { base_url, apt_url } from "../../service/config";
import { ActivatedRoute, Router, ActivationEnd } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadsAppComponent } from '../../dialog/downloads-app/downloads-app.component';
import { trigger, transition, style, animate, stagger, query, useAnimation } from '@angular/animations';
import {
	slideInDown,
	slideInUp,
	slideInLeft,
	slideInRight,
	slideOutDown,
	slideOutUp,
	slideOutLeft,
	slideOutRight,
} from 'ng-animate';

import { Observable, Subscription, timer } from 'rxjs';
import { flyInOut, expand } from '../../animation-details/animation-details.component';
import { type } from 'jquery';

@Component({
	selector: 'app-downloads',
	templateUrl: './downloads.component.html',
	styleUrls: ['./downloads.component.scss'],
	animations: [
		flyInOut(),
		expand(),
		trigger('slideInDown', [transition('* => *', useAnimation(slideInDown))]),
		trigger('slideInUp', [transition('* => *', useAnimation(slideInUp))]),
		trigger('slideInLeft', [transition('* => *', useAnimation(slideInLeft))]),
		trigger('slideInRight', [transition('* => *', useAnimation(slideInRight))])
	],
})
export class DownloadsComponent implements OnInit {

	public showloader: boolean = false;
	private subscription: Subscription;
	private timer: Observable<any>;

	public loading1: boolean = true;
	public loading2: boolean = true;
	public loading3: boolean = true;

	public AllClass: any;
	public slideInDown: any;
	public slideInUp: any;
	public slideInLeft: any;
	public slideInRight: any;
	public classData: any = [];
	public classObejct: any;
	public cols: number = 4;
	public ImageUrl: string = apt_url + 'uploads/Class/download/';
	// urm source related variables
	public utm_source: string = '';
	public utm_medium: string = '';
	public campaignType: string = '';
	public publisherId: string = '';
	public ar: any = [false, false, false];
	public arr: any = [];
	public flag: boolean;
	public title: any = [];
	public titleDetermine: any = [];
	public displayData: any = [];
	public showData: any = [];
	public loading4: boolean = true;

	constructor(public route: ActivatedRoute, public getClassService: GetDataServiceService, public router: Router,
		public snakBar: MatSnackBar, public dialog: MatDialog) {
		this.utm_source = this.route.snapshot.queryParamMap.get('utm_source');
		if (this.utm_source !== null) {
			this.utm_medium = this.route.snapshot.queryParamMap.get('utm_medium');
			this.campaignType = this.route.snapshot.queryParamMap.get('campaigntype');
			this.publisherId = this.route.snapshot.queryParamMap.get('publisherid');
		} else {
			console.log('NO UTM SOURCE');
		}
	}

	ngOnInit(): void {
		this.getAboutCourse();
		this.getCategoriesClass();
		this.getWidth();
	}

	@HostListener('window:beforeunload', ['$event'])
beforeUnloadHander(event) {
  console.log("Leaving site, beforeunload!")
    return false;
}

	public ngOnDestroy() {
		if (this.subscription && this.subscription instanceof Subscription) {
			this.subscription.unsubscribe();
		}
	}

	aboutCourse: any;
	getAboutCourse() {
		//GET ABOUT COURSE HERE 
		let url = apt_url + 'DownloadContents?filter={"where":%20{"type":%20"course"}}'
		this.getClassService.getData(url).subscribe((response: any) => {
			if (response.length) {
				this.aboutCourse = {
					title: response[0].title,
					description: response[0].description
				}
			}
		})
	}

	allClasses: any;
	getCategoriesClass() {
		let url = apt_url + 'Classes/getCategoryClasses?type=download'
		this.getClassService.getData(url).subscribe((data: any) => {
			if (data.statusCode === 200) {
				this.allClasses = data.data
				console.log(this.allClasses)
			}
			else {
				this.snakBar.open('Failed to get courses', 'OK', { duration: 3000 })
			}
		})
	}

	getWidth() {
		if (window.innerWidth < 600)
			this.cols = 2;
		else if (window.innerWidth >= 600 && window.innerWidth < 900)
			this.cols = 2
		else if (window.innerWidth >= 900 && window.innerWidth < 1200)
			this.cols = 4;
		else
			this.cols = 4;
	}

	navigate(menu) {
		if (this.utm_source !== null) {
			this.router.navigate([]).then(result => { console.log(result); window.open('/downloads/Subjects?id=' + menu._id + '&name=' + menu.name, '_blank'); })
		} else {
			this.router.navigate([]).then(result => { console.log(result); window.open('/downloads/Subjects?id=' + menu._id + '&name=' + menu.name, '_blank'); })
		}
	}

	// public setTimer() {

	// 	// set showloader to true to show loading div on view
	// 	this.showloader = true;

	// 	this.timer = timer(3000); // 5000 millisecond means 5 seconds
	// 	this.subscription = this.timer.subscribe(() => {
	// 		// set showloader to false to hide loading div from view after 5 seconds
	// 		this.showloader = false;
	// 	});
	// }



	// public getClassCategoriesData() {
	// 	let url = apt_url + 'ClassCategories';
	// 	this.getClassService.getData(url).subscribe(response => {
	// 		console.log("get class categories data is ", response);
	// 		this.classData = response;
	// 		console.log(this.classData);
	// 		this.loading1 = false;
	// 	})
	// }



	// public commonfunc() {
	// 	//	let url = apt_url + 'ClassCategories';    

	// 	let url = apt_url + 'Classes/getCategoryClasses?access_token=7zHS4FZt37rdMw8qy8qHJNsItWhMaUfEaHABGCcgrG34F99klffwCpf7hMUri8JG';
	// 	let url1 = apt_url + 'Classes?filter={"where":{"or":[{"deleted":false},{"purchased":{"gte":1}}]},"include": ["onlinedata","products"]}'
	// 	this.getClassService.getData(url1).subscribe(response => {
	// 		console.log("all class data is here ", response);
	// 		var temp: any = response;
	// 		this.AllClass = temp;
	// 		console.log(this.AllClass);
	// 		this.getClassService.getData(url).subscribe((response: any) => {
	// 			console.log("get class categories data is ", response);
	// 			this.classData = response.data;
	// 			var val = this.classData;
	// 			this.titleDetermine = [];
	// 			for (let i = 0; i < this.classData.length; i++) {
	// 				for (let j = 0; j < this.AllClass.length; j++) {
	// 					if (this.AllClass[j].products.length > 0 && this.AllClass[j].status == 'published' && this.classData[i]._id === this.AllClass[j].classcatId) {
	// 						this.flag = true;
	// 						console.log(this.flag);
	// 						console.log(this.AllClass[j]);
	// 						this.titleDetermine.push(this.AllClass[j]);
	// 						this.title[i] = this.classData[i].title;
	// 						console.log(this.title[i])
	// 					}
	// 				}
	// 			}
	// 			this.displayFunc();
	// 			/*		  function onlyUnique(value, index, self) {
	// 						return self.indexOf(value) === index;
	// 					  } */

	// 			// usage example:
	// 			console.log('Check', this.title)
	// 			// var unique = a.filter(onlyUnique);
	// 		})
	// 		this.loading3 = false;
	// 	}, error => {
	// 		console.log("blogs geting error ", error);
	// 	});
	// 	//  while(this.loading3){
	// 	//    console.log("still loading")
	// 	//  } 

	// }
	// displayFunc() {
	// 	console.log(this.titleDetermine);

	// 	this.displayData = [];
	// 	for (let i = 0; i < this.titleDetermine.length; i++) {
	// 		let url = apt_url + 'Classes/' + this.titleDetermine[i].id + '/subjects?filter={"where":{"status": "published", "or":[{"deleted":false},{"purchased":{"gte":1}}]},"include": "downloadContents"}';
	// 		this.getClassService.getData(url).subscribe((e: any) => {

	// 			e.forEach((e1: any) => {

	// 				if (e1.downloadContents || e1.downloadContents[0].description !== undefined) {
	// 					if (e1.downloadContents[0].description.length > 0) {
	// 						let id1 = e1.downloadContents[0].subjectId;

	// 						let url = apt_url + 'Subjects/' + id1 + '/subjectTopics?filter={"where":{"or":[{"deleted":false},{"purchased":{"gte":1}}]}}&access_token=z2SOHSzXqKNmD597iPJokOG2Pud8CyCqFUEl8iW2L3LrWD1kbA5ljZRjwhn8Ii8Z';
	// 						this.getClassService.getData(url).subscribe((e2: any) => {
	// 							let topics = e2;

	// 							for (let a = 0; a < topics.length; a++) {
	// 								let url2 = apt_url + 'Downloads?filter={"where":{"topicId":"' + topics[a].id + '"}}';
	// 								this.getClassService.getData(url2).subscribe((res: any) => {
	// 									console.log(res.title, res);

	// 									if (res.length > 0) {
	// 										this.displayData.push(this.titleDetermine[i]);

	// 									}
	// 								});

	// 							}
	// 						});

	// 					}
	// 				}

	// 			});
	// 		});

	// 	}
	// 	console.log(this.displayData);
	// 	this.func();
	// }
	// func() {
	// 	setTimeout(e => {
	// 		this.showData = [];
	// 		this.showData = new Set(this.displayData);
	// 		this.loading4 = false;
	// 	}, 2000)

	// }

	// DownloadsApp() {
	// 	console.log("do nothings");
	// 	const dialogRef = this.dialog.open(DownloadsAppComponent, {
	// 		width: '500px',
	// 		disableClose: true,
	// 		data: { screen: "home", action: "download App", heading: "User Information" },
	// 	});

	// 	dialogRef.afterClosed().subscribe(result => {
	// 		console.log('The dialog was closed', result);
	// 		if (result !== undefined) {
	// 			console.log("post data");
	// 			let url = base_url + 'CampaignUsers';
	// 			let postData: any = {};
	// 			if (this.utm_source !== null) {
	// 				postData = {
	// 					name: result.name,
	// 					mobile: result.mobile,
	// 					password: 'apt#123',
	// 					createdAt: new Date(),
	// 					"utm_medium": this.utm_medium,
	// 					"compaigntype": this.campaignType,
	// 					"publisherid": this.publisherId,
	// 					"utm_source": this.utm_source,
	// 				}

	// 			} else {
	// 				postData = {
	// 					name: result.name,
	// 					mobile: result.mobile,
	// 					password: 'apt#123',
	// 					createdAt: new Date()
	// 				}
	// 			}
	// 			this.getClassService.PostData(postData, url).subscribe(response => {
	// 				console.log("all class data is here ", response);
	// 				// let temp: any = response;
	// 				this.snakBar.open('Thanks for sharing your information with us', 'OK', {
	// 					duration: 3000
	// 				})
	// 			}, error => {
	// 				console.log("app downlods  geting error ", error);
	// 			});
	// 		}
	// 	});
	// }

	// getAllClaases() {
	// 	let url = apt_url + 'Classes?filter={"where":{"or":[{"deleted":false},{"purchased":{"gte":1}}]},"include": ["onlinedata","products"]}'
	// 	this.getClassService.getData(url).subscribe(response => {
	// 		console.log("all class data is here ", response);
	// 		let temp: any = response;
	// 		this.AllClass = temp;
	// 		console.log(this.AllClass);
	// 		console.log('Hey 2')

	// 		this.loading3 = false;
	// 	}, error => {
	// 		console.log("blogs geting error ", error);
	// 	});
	// }


	// allClassData: any = []
	// mergeTitleClass() {
	// 	new Promise((resolve, reject) => {
	// 		// GET CLASS CATEGORIES
	// 		let url = apt_url + 'Classes/getCategoryClasses?access_token=7zHS4FZt37rdMw8qy8qHJNsItWhMaUfEaHABGCcgrG34F99klffwCpf7hMUri8JG';
	// 		this.getClassService.getData(url).subscribe((data: any) => {
	// 			console.log('mergeTitleClass()', data);
	// 			this.classData = data.data;
	// 			console.log(this.classData);
	// 			console.log(this.classData[0])
	// 			this.loading1 = false;
	// 		})
	// 		resolve(this.classData)
	// 		console.log('Hey 1')
	// 	}).then(response => {
	// 		new Promise((res, rej) => {
	// 			// GET ALL CLASSES
	// 			let url = apt_url + `Classes?filter={"where":{"or":[{"deleted":false},{"purchased":{"gte":1}}]},"include": ["onlinedata","products"]}`
	// 			this.getClassService.getData(url).subscribe((data: any) => {
	// 				this.AllClass = data;
	// 				this.loading3 = false;
	// 				res(data)
	// 				console.log('mergeTitleClass() nested Promise', data)
	// 			}, error => {
	// 				console.log("class data error ", error);
	// 			});
	// 		}).then(response => {
	// 			console.log('Hey 3')
	// 			// MERGE HERE
	// 			for (let i = 0; i < this.classData.length; i++) {
	// 				this.classData[i].classData = [];
	// 				console.log('Hello');
	// 				for (let j = 0; j < this.AllClass.length; j++) {
	// 					if (this.classData[i]._id === this.AllClass[j].classcatId) {
	// 						console.log('World');
	// 						this.classData[i].classData.push(this.AllClass[j])
	// 						console.log('adding all data with class data', this.classData[i].classData);
	// 						// let obj = { ...this.classData[i], ...{ classData: this.AllClass[j] } }
	// 						// this.allClassData.push(obj)
	// 					}
	// 				}
	// 			}
	// 			console.log('At last of Merge ()', this.classData);
	// 		})
	// 	})
	// }
}