<div class="no-data" *ngIf="loading1 && loading2 && loading3 && loading4 && loading5" fxLayoutAlign="center center"
    fxFlex>
    <ngx-loading [show]="loading1 && loading2 && loading3 && loading4 && loading5"
        [config]="{backdropBackgroundColour: 'rgba(0, 0, 0, 0)', primaryColour: '#54ACE4' , secondaryColour: '#2486C5', tertiaryColour:  '#0C5C8F' }">

    </ngx-loading>
</div>

<div class="full-width" *ngIf="!loading1 || !loading2 || !loading3 || !loading4 || !loading5">
    <div class="full-width" fxLayout fxLayoutAlign="center center">
        <h1 class="mat-h1 text-c"><b>{{ClassName}}</b></h1>
    </div>
    <br>

    <div class="full-width" fxLayout fxLayoutAlign="center center">
        <div style="padding: 10px;" fxFlex="70" fxFlex.md="75" fxFlex.sm="85" fxFlex.xs="96"
            *ngIf="features?.length > 2" fxLayout fxLayoutGap="10px" fxLayoutAlign="center" fxLayout.sm="column"
            fxLayout.xs="column" fxLayoutGap="20px">
            <div fxFlex="25" fxLayoutAlign="start start" fxFlex.sm="90" fxFlex.xs="96"
                *ngFor="let feture of features;let k = index;">
                <div class="full-width">
                    <div fxLayout fxLayoutAlign="center center" fxLayoutGap="10px">
                        <div fxFlex="10" fxLayoutAlign="end center">
                            <p class="Number"> {{k+1}}. </p>
                        </div>
                        <div style="margin-left: 5px;" fxFlex="10" fxLayoutAlign="center center">
                            <div class="verticalLine"> </div>
                        </div>
                        <div fxFlex="80" fxLayoutAlign="start center">
                            <p class="paragraph">
                                {{feture}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="features?.length==1" fxLayout fxLayoutAlign="center center" class="MainDiv" fxLayout.sm="column"
            fxLayout.xs="column" fxLayoutGap="20px">
            <div fxFlex="100" fxLayoutAlign="start start" fxFlex.sm="100" fxFlex.xs="100"
                *ngFor="let feture of features;let k = index;">
                <div fxLayout fxLayoutAlign="center center">
                    <div fxFlex="100">
                        <br>
                        <div fxLayout fxLayoutAlign="center center" fxLayoutGap="10px">
                            <div fxFlex="5" fxLayoutAlign="center center">
                                <p class="Number"> {{k+1}}. </p>
                            </div>
                            <div style="margin-left: 5px;" fxFlex="10" fxLayoutAlign="center center">
                                <div class="verticalLine"> </div>
                            </div>
                            <div fxFlex="85" fxLayoutAlign="center center">
                                <p class="paragraph">
                                    {{feture}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="features?.length==2" fxLayout fxLayoutAlign="center start" class="MainDiv" fxLayout.sm="column"
            fxLayout.xs="column"  fxLayoutGap="20px">
            <div fxFlex="50" fxLayoutAlign="start start" fxFlex.sm="100" fxFlex.xs="100"
                *ngFor="let feture of features;let k = index;">
                <div fxLayout fxLayoutAlign="center center">
                    <div fxFlex="100">
                        <br>
                        <div fxLayout fxLayoutAlign="center center" fxLayoutGap="50px">
                            <div fxFlex="5" fxLayoutAlign="center center">
                                <p class="Number"> {{k+1}}. </p>
                            </div>
                            <div style="margin-left: 5px;" fxFlex="10" fxLayoutAlign="center center">
                                <div class="verticalLine"> </div>
                            </div>
                            <div fxFlex="85" fxLayoutAlign="center center">
                                <p class="paragraph">
                                    {{feture}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <br><br>
    <div fxLayout fxLayoutAlign="start start" class="MainDiv" fxLayout.sm="column" fxLayout.xs="column" class="MainDiv">
        <div fxFlex="50" fxLayoutAlign="center center" fxFlex.sm="100" fxFlex.xs="100" style="margin-left: auto; margin-right: auto;">
            <div class="full-width" fxLayout fxLayoutAlign="center center">
                <img [src]="imageUrl + img" height="300px" alt="home" class="img-product">
            </div>
        </div>
        <br>
        <div style="margin-left: 25px; margin-right: 24px;" fxFlex="50" fxLayoutAlign="start center" fxFlex.sm="100"
            fxFlex.xs="100">
            <div fxLayout fxLayoutAlign="start center" style="margin-left: auto; margin-right: auto;">
                <div fxFlex="100">
                    <div fxLayout fxLayoutAlign="start center">
                        <div fxFlex="100" fxLayoutAlign="start center">
                            <h3 class="completed"><b> Complete Course </b></h3>
                        </div>
                    </div>
                    <div fxLayout fxLayoutAlign="start center">
                        <div fxFlex="100" fxLayoutAlign="start center">
                            <h4 class="mrp"><b>M.R.P.</b> </h4> &nbsp; &nbsp;<h4 class="mrp"
                                [style.text-decoration]="allSubjectunderline? 'line-through':'none'">RS. {{credits}}.00
                            </h4>
                        </div>
                    </div>
                    <br>
                    <div fxLayout fxLayoutAlign="start center">
                        <div fxFlex="100" fxLayoutAlign="start center">
                            <h4 class="price"> <b>PRICE</b> </h4> &nbsp;&nbsp; <h4 class="price">RS.
                                {{discountedPrice}}.00 </h4>
                        </div>
                    </div>
                    <div style="margin-left: 58px;" fxLayout fxLayoutAlign="start center">
                        <div fxFlex="100" fxLayoutAlign="start center">
                            <h5 style="color: E40000;"> * &nbsp; </h5>
                            <h6 class="gst"><b> GST Included </b></h6>
                        </div>
                    </div>
                    <form [formGroup]="purchaseForm">
                        <mat-form-field style="width: 100%;">
                            <mat-label class="full"> FULL COURSE</mat-label>
                            <mat-select multiple (selectionChange)="someMethod($event.value)" placeholder="Full Course"
                                formControlName="course" #individual>
                                <mat-select-trigger>
                                    <span *ngFor="let op of purchaseForm.value.course;let i=index;">
                                        <span *ngIf="op!==0 && (i+1)!==purchaseForm.value.course.length">
                                            {{op}},
                                        </span> 
                                        <span *ngIf="op!==0 && (i+1)===purchaseForm.value.course.length">
                                            {{op}}
                                        </span>      
                                    </span>
                                </mat-select-trigger>
                                <mat-option formfiltros-inmuebles matOption #allSelected (click)="toggleAllSelection()"
                                    [value]="0">
                                    <p class="courses selectall" style="margin-bottom: 0px; color: black">Full Course
                                        <span
                                            style="color:#0C5C8F;; text-align: center; margin-left: 140px; margin-bottom: 0px;"
                                            class="not-empty-select"> <b>Rs. {{credits - discountedPrice}} discount
                                            </b></span>
                                    </p>
                                </mat-option>
                                <mat-option matOption class="mat-primary courses" 
                                    style="margin-left: 25px; color: black" *ngFor="let subject of allSubject"
                                    [value]="subject.name" (click)="tosslePerOne(allSelected.viewValue,subject)"
                                    [disabled]="subject.order==true">
                                    {{subject.name}}
                                </mat-option>
                                
                            </mat-select>

                        </mat-form-field>
                        <mat-form-field style="width: 100%;">
                            <mat-label> COUPON CODE</mat-label>
                            <input id="value" matInput formControlName="coupons" minlength="3" maxlength="15" #myInput
                                (keyup)="coupon(myInput.value)" (keydown.backspace)="coupon(myInput.value)" 
                                [readonly]="couponMessage === 'Applied'|| purchaseForm.value.course.length<1" autocomplete="off" >
                            <button *ngIf="myInput?.value?.length > 2 && couponMessage !== 'Applied'" matSuffix mat-button color="primary"
                                (click)="couponmessage(); applyCoupon(myInput.value)">
                                APPLY
                            </button>
                            <button style="color: #0C5C8F;" *ngIf="couponMessage === 'Applied'" matSuffix mat-button color="primary"
                                (click)="togglecoupon()">
                                APPLIED
                                <mat-icon color="primary">close</mat-icon>
                            </button>
                            <mat-hint [style.color]="hasError ? 'red' : 'green'">{{couponMessage}}</mat-hint>
                        </mat-form-field>

                        <!-- <div *ngIf="afterCoupon" style="display:flex;font-size: large;"
                            [style.color]="hasError?'red':'green'">
                            {{coup}}
                        </div> -->

                        <br>
                        <div fxLayout fxLayoutAlign="center center">
                            <div style="padding: 20px 0;" fxFlex="100" fxLayoutAlign.xs="center center"
                                fxLayoutAlign="start center">
                                <button (click)="PAYNOW()" mat-raised-button color="primary">
                                    <b style="color: white;">PROCEED TO PAY</b>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
    <!-- app downloads section -->
    <br><br>
    <!-- app downloads section -->
    <br>

</div>