import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<ThanksComponent>) { }

  ngOnInit(): void {
  }
  okButton(){
    this.matDialogRef.close();
  }

}
