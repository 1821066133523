<div class="no-data" *ngIf="showloader" fxLayoutAlign="center center" fxFlex>
    <ngx-loading [show]="showloader"
        [config]="{backdropBackgroundColour: 'rgba(0, 0, 0, 0)', primaryColour: '#54ACE4' , secondaryColour: '#2486C5', tertiaryColour:  '#0C5C8F' }">

    </ngx-loading>
</div>

<div *ngIf="!showloader" (window:resize)="getWidth($event)">
    <div fxLayout fxLayoutAlign="start center">
        <div fxFlex="100">
            <div fxLayout fxLayoutAlign="center center">
                <h1 class="mat-h1 text-c"><b>Join Team APT</b></h1>
            </div>
        </div>
    </div>
    <div fxLayout fxLayoutAlign="start center">
        <div fxLayout fxLayoutAlign="center center" fxFlex="100">
            <img fxFlex="75" fxFlex.xs="100" src="assets/image/Joinapt.png" alt="home">
        </div>
    </div>
    <br>
    <br>
    <br>
    <div fxLayout fxLayoutAlign="start center" fxLayout.sm="column" fxLayout.xs="column" class="MainDiv">
        <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
            <div fxLayout fxLayoutAlign="start center" style="padding:0 20px">
                <div fxFlex="100">
                    <div fxLayout fxLayoutAlign="center center">
                        <h1 class="mat-h1"><b>Become A Faculty</b></h1>
                    </div>
                    <div fxLayout fxLayoutAlign="center center">
                        <h3 fxFlex="60" fxFlex.sm="75" fxFlex.xs="96" class="mat-h3 text-c">
                            Our courses are designed to clarify all the topics, be it basic or advanced.
                            We make sure that students get basic strength that they need in all subjects.
                        </h3>
                    </div>
                    <div fxLayout fxLayoutAlign="center center">
                        <h3 fxFlex="60" fxFlex.sm="75" fxFlex.xs="96" class="mat-h3 text-c">
                            When you provide the service at the root level, Angular creates a single,
                            shared instance of HeroService and injects into any class that asks for it.
                            Registering the provider in the @Injectable metadata also allows.
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div *ngIf="!mobileDevice" style="width: 100%;" fxLayout fxLayoutAlign="start center">
        <div fxLayout="column" fxFlex="100" class="formRemove">
            <br><br>
            <div fxLayout fxLayoutAlign="start center">
                <div fxFlex="100">
                    <div fxLayout fxLayoutAlign="center center">
                        <h1 class="mat-h1" style="font-size: 24px; font-weight: 600;"><b>Register Now</b></h1>
                    </div>
                </div>
            </div>
            <br><br>
            <div fxLayout fxLayoutAlign="start center">
                <div fxFlex="100" fxLayout fxLayoutAlign="center center">
                    <mat-card class="cardWith" fxFlex="60" fxFlex.sm="75" fxFlex.xs="100">
                        <form [formGroup]="JointAptForm" (ngSubmit)="SAVEDATA()">
                            <div fxLayout fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                    <mat-label> Job Title </mat-label>
                                    <input required formControlName="jobTitle" matInput minlength="3" maxlength="150">
                                    <mat-error></mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'"  style="width: 100%;">
                                    <mat-label> Name </mat-label>
                                    <input required formControlName="name" matInput minlength="3" maxlength="50">
                                    <mat-error *ngIf="JointAptForm.controls.name.invalid && (JointAptForm.controls.name.touched || JointAptForm.controls.name.dirty)">
                                        <mat-error *ngIf="JointAptForm.controls.name.errors.pattern" style="color:red;font-size:small;font-weight:600">
                                            Only Alphabets are allowed
                                        </mat-error>
                                        <mat-error>
                                        </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                    <mat-label>Email Id</mat-label>
                                    <input required type="email" formControlName="email" matInput>
                                    <mat-error
                                        *ngIf="JointAptForm.controls.email.invalid && (JointAptForm.controls.email.touched || JointAptForm.controls.email.dirty )"
                                        style="color: #f44336">
                                        <mat-error *ngIf="JointAptForm.controls.email.errors.required"> 
                                            </mat-error>
                                        <mat-error *ngIf="JointAptForm.controls.email.errors.pattern">
                                            </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout fxLayoutAlign="center center">
                                <div fxFlex="15" fxFlex.sm="25" fxFlex.xs="25" fxLayoutAlign="center center">
                                    <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                        <mat-label>Country Code</mat-label>
                                        <mat-select required formControlName="country"
                                            (ngModelChange)="onChange($event)" [(ngModel)]="defaultCountryCode">
                                            <div *ngFor="let contry of AllCoutryName">
                                                <mat-option [value]="contry">+{{contry.dial_code}}</mat-option>
                                                <mat-divider></mat-divider>
                                            </div>
                                        </mat-select>
                                        <!-- <mat-error>Country name is Required</mat-error> -->
                                    </mat-form-field>
                                </div>
                                <div fxFlex="85" fxFlex.sm="75" fxFlex.xs="75" fxLayoutAlign="center center">
                                    <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                        <mat-label>Contact Number</mat-label>
                                        <input required formControlName="mobile" type="tel" matInput numbersOnly="true"
                                            [maxlength]="mobileMax">
                                        <mat-error
                                            *ngIf="JointAptForm.controls.mobile.invalid && (JointAptForm.controls.mobile.touched || JointAptForm.controls.mobile.dirty)">
                                            <mat-error *ngIf="JointAptForm.controls.mobile.errors.required">
                                                </mat-error>
                                            <mat-error *ngIf="JointAptForm.controls.mobile.errors.minlength">
                                                </mat-error>
                                            <mat-error *ngIf="JointAptForm.controls.mobile.errors.maxlength">
                                                </mat-error>
                                            <mat-error *ngIf="JointAptForm.controls.mobile.errors.pattern">
                                                </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div  fxLayout fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                 
                                    <mat-label>State

                                        <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker ng-tns-c99-10 ng-star-inserted"> *</span>

                                    </mat-label>
                                    <mat-select *ngIf="showState" formControlName="state">
                                        <div *ngFor="let state of allState">
                                            <mat-option [value]="state.state">{{state.state}}</mat-option>
                                            <mat-divider></mat-divider>
                                        </div>
                                    </mat-select>
                                    <input *ngIf="!showState" matInput type="text" formControlName="state">
                                </mat-form-field>
                            </div>
                            <div *ngIf="!flag && showState">
                                <h5>*Not required for people registering outside India</h5>
                            </div>
                            <div *ngIf="showState" fxLayout fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                    <mat-label>City <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker ng-tns-c99-8 ng-star-inserted"> *</span></mat-label>
                                    <input formControlName="city" matInput maxlength="150">
                                    <mat-error></mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                    <mat-label>Work Experience</mat-label>
                                    <!-- <input required placeholder="exprience" formControlName="exprience" matInput > -->
                                    <mat-select required formControlName="exprience">
                                        <!-- <div *ngFor="let state of allState.states"> -->
                                        <mat-option value="Less than 1 yr">Less than 1 yr</mat-option>
                                        <mat-divider></mat-divider>
                                        <mat-option value="1-3 yrs">1-3 yrs</mat-option>
                                        <mat-divider></mat-divider>
                                        <mat-option value="More than 3yrs">More than 3yrs</mat-option>
                                        <mat-divider></mat-divider>
                                        <!-- </div> -->
                                    </mat-select>
                                    <mat-error></mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout fxLayoutAlign="center center">
                                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                                    <mat-label>Suitable time to contact</mat-label>
                                    <!-- <input required placeholder="Suitable time for contact" formControlName="TimeContact" matInput > -->
                                    <mat-select required formControlName="TimeContact">
                                        <!-- <div *ngFor="let state of allState.states"> -->
                                        <mat-option value=" 8am - 10am">8am - 10am</mat-option>
                                        <mat-divider></mat-divider>
                                        <mat-option value="10am - 6pm">10am - 6pm</mat-option>
                                        <mat-divider></mat-divider>
                                        <mat-option value="6pm - 9pm">6pm - 9pm</mat-option>
                                        <mat-divider></mat-divider>
                                        <!-- </div> -->
                                    </mat-select>
                                    <mat-error></mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout fxLayoutAlign="center center">
                                <div fxFlex="100" fxLayoutAlign="center center">
                                    <button mat-raised-button color="primary" type="submit" [disabled]="JointAptForm.invalid"
                                        class="submitButton">SUBMIT</button>
                                </div>
                            </div>
                        </form>
                    </mat-card>
                </div>
            </div>
            <br><br>
        </div>
    </div>

    <div fxLayout="column" style="width: 100%;" *ngIf="mobileDevice">
        <div fxLayout fxLayoutAlign="start center">
            <div fxFlex="100">
                <div fxLayout fxLayoutAlign="center center">
                    <h1 class="mat-h1">Register Now</h1>
                </div>
            </div>
        </div>
        <form fxFlex="100" style="padding: 20px;" [formGroup]="JointAptForm" (ngSubmit)="SAVEDATA()">
            <div fxLayout fxLayoutAlign="center center">
                <mat-form-field [floatLabel]="'never'"  style="width: 100%;">
                    <mat-label> Job Title </mat-label>
                    <input required formControlName="jobTitle" matInput minlength="3" maxlength="150">
                    <mat-error></mat-error>
                </mat-form-field>
            </div>
            <div fxLayout fxLayoutAlign="center center">
                <mat-form-field [floatLabel]="'never'"  style="width: 100%;">
                    <mat-label> Name </mat-label>
                    <input required formControlName="name" matInput minlength="3" maxlength="150">
                    <mat-error></mat-error>
                </mat-form-field>
            </div>
            <div fxLayout fxLayoutAlign="center center">
                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                    <mat-label>Email Id</mat-label>
                    <input required type="email" formControlName="email" matInput>
                    <mat-error
                        *ngIf="JointAptForm.controls.email.invalid && (JointAptForm.controls.email.touched || JointAptForm.controls.email.dirty )"
                        style="color: #f44336">
                        <mat-error *ngIf="JointAptForm.controls.email.errors.required">
                            </mat-error>
                        <mat-error *ngIf="JointAptForm.controls.email.errors.pattern">
                            </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout fxLayoutAlign="center center">
                <mat-form-field [floatLabel]="'never'"  style="width: 100%">
                    <mat-label>Country Code</mat-label>
                    <mat-select required formControlName="country" (ngModelChange)="onChange($event)" [(ngModel)]="defaultCountryCode">
                        <mat-option *ngFor="let contry of AllCoutryName" [value]="contry">+{{contry.dial_code + ' - ' + contry.code}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxLayout fxLayoutAlign="center center">
                <mat-form-field [floatLabel]="'never'"  style="width: 100%;">
                    <mat-label>Contact Number</mat-label>
                    <input required formControlName="mobile" type="tel" matInput [maxlength]="mobileMax">
                    <mat-error
                        *ngIf="JointAptForm.controls.mobile.invalid && (JointAptForm.controls.mobile.touched || JointAptForm.controls.mobile.dirty)">
                        <mat-error *ngIf="JointAptForm.controls.mobile.errors.required">
                            </mat-error>
                        <mat-error *ngIf="JointAptForm.controls.mobile.errors.minlength">
                            </mat-error>
                        <mat-error *ngIf="JointAptForm.controls.mobile.errors.maxlength">
                            </mat-error>
                        <mat-error *ngIf="JointAptForm.controls.mobile.errors.pattern">
                            </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div *ngIf="showState" fxLayout fxLayoutAlign="center center">
                <mat-form-field [floatLabel]="'never'"  style="width: 100%;">
                    <mat-label>State</mat-label>
                    <mat-select [required]="showState  ? true : false " formControlName="state">
                        <div *ngFor="let state of allState">
                            <mat-option [value]="state.state">{{state.state}}</mat-option>
                            <mat-divider></mat-divider>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="!flag && showState">
                <h5>*Not required for people registering outside India</h5>
            </div>
            <div *ngIf="showState" fxLayout fxLayoutAlign="center center">
                <mat-form-field [floatLabel]="'never'"  style="width: 100%;">
                    <mat-label>City <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker ng-tns-c99-8 ng-star-inserted"> *</span></mat-label>
                    <input formControlName="city" matInput maxlength="150">
                    <mat-error></mat-error>
                </mat-form-field>
            </div>
            <div fxLayout fxLayoutAlign="center center">
                <mat-form-field [floatLabel]="'never'" style="width: 100%;">
                    <mat-label>Work Experience</mat-label>
                    <!-- <input required placeholder="exprience" formControlName="exprience" matInput > -->
                    <mat-select required formControlName="exprience">
                        <!-- <div *ngFor="let state of allState.states"> -->
                        <mat-option value="Less than 1 yr">Less than 1 yr</mat-option>
                        <mat-divider></mat-divider>
                        <mat-option value="1-3 yrs">1-3 yrs</mat-option>
                        <mat-divider></mat-divider>
                        <mat-option value="More than 3yrs">More than 3yrs</mat-option>
                        <mat-divider></mat-divider>
                        <!-- </div> -->
                    </mat-select>
                    <mat-error></mat-error>
                </mat-form-field>
            </div>
            <div fxLayout fxLayoutAlign="center center">
                <mat-form-field [floatLabel]="'never'"  style="width: 100%;">
                    <mat-label>Suitable time for contact</mat-label>
                    <!-- <input required placeholder="Suitable time for contact" formControlName="TimeContact" matInput > -->
                    <mat-select required formControlName="TimeContact">
                        <!-- <div *ngFor="let state of allState.states"> -->
                        <mat-option value=" 8am - 10am">8am - 10am</mat-option>
                        <mat-divider></mat-divider>
                        <mat-option value="10am - 6pm">10am - 6pm</mat-option>
                        <mat-divider></mat-divider>
                        <mat-option value="6pm - 9pm">6pm - 9pm</mat-option>
                        <mat-divider></mat-divider>
                        <!-- </div> -->
                    </mat-select>
                    <mat-error></mat-error>
                </mat-form-field>
            </div>
            <div fxLayout fxLayoutAlign="center center">
                <div fxFlex="100" fxLayoutAlign="center center">
                    <button mat-raised-button color="primary" type="submit" class="submitButton" [disabled]="JointAptForm.invalid">SUBMIT</button>
                </div>
            </div>
        </form>
    </div>


    <br><br><br>
</div>