// export const base_url = 'http://192.241.203.148:3010/api/' /* STAGING SERVER  --->  aptWeb*/



// export const apt_url = 'http://142.93.212.14:3025/api/' /* STAGING SERVER ---> apt*/
// export const Paytm_url =  'http://142.93.212.14:3025/';


  // export const apt_url = 'http://143.110.242.130:3025/api/' /* STAGING SERVER ---> apt OTHER */
   // export const Paytm_url =  'http://143.110.242.130:3025/'; //OTHER






// LOCAL
// export const web_url = 'http://localhost:4300/#/'
// export const base_url = `http://192.168.1.12:3010/api/`
// export const apt_url = `http://192.168.1.12:3025/api/`
// export const Paytm_url = `http://192.168.1.12:3025/`
// export const base_domain = 'http://192.168.1.12:3010/'



// STAGING
// export const web_url = 'http://143.110.242.130:4000/#/'
// export const base_url = `http://192.241.203.148:3010/api/`
// // export const base_url = `http://localhost:3010/api/`
// export const apt_url = `http://143.110.242.130:3025/api/`
// export const Paytm_url = `http://143.110.242.130:3025/`
// export const base_domain = 'http://143.110.242.130:3010/'




// PRODUCTION
export const web_url = 'https://beta.aptstudy.com/'
export const base_url = `https://web-apis.aptstudy.com/api/`
// export const base_url = `http://localhost:3010/api/`
export const apt_url = `https://app-apis.aptstudy.com/api/`
export const Paytm_url = `https://app-apis.aptstudy.com/`
export const base_domain = 'https://web-apis.aptstudy.com/'