import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-invalid-campaign',
  templateUrl: './invalid-campaign.component.html',
  styleUrls: ['./invalid-campaign.component.scss']
})
export class InvalidCampaignComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<InvalidCampaignComponent>) { }

  ngOnInit(): void {
  }
  okButton(){
    this.matDialogRef.close();
  }

}
