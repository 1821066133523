<div class="full-width" fxLayout="column"  class="full-width" (window:resize)="getWidth($event)">
    <div style="padding: 20px 0;" fxLayout fxLayoutAlign="center center">
        <div fxFlex="80" fxLayout>
            <img [src]="dir + thankResponse?.logo" height="75">
        </div>
    </div>
    <br>
    <br>
    <br>
    <div style="flex-grow: 1;" fxFlex="500px" fxFlex.xs="350px" fxFlex.md="450px" fxFlex.sm="400px" fxLayout fxLayoutAlign="center center">
        <div fxFlex="70" fxFlex.xs="96" fxFlex.sm="85" fxFlex.md="80" class="full-width" fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="center center">
                <div class="inner-text">
                    <h1 [style.color]="thankResponse?.thankTitle[0].color" [class]="mobileDevice ? 'mat-display-3' : 'mat-display-4'" style="font-family: 'Kurale', serif !important;letter-spacing: normal !important;"  [style.font-size]="mobileDevice ? '50px' : '100px'">
                        {{thankResponse?.thankTitle[0].text}}
                    </h1>

                    <h1 class="mat-h1"><b>{{name}}</b></h1>
                </div>
            </div>
            <div fxLayout.xs="column" fxLayout.sm="column" fxLayout fxLayoutAlign="center center" fxLayoutGap="80px">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
                    <div class="inner-text">
                        <p class="mat-body text-c">{{thankResponse?.thanksDescription}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div style="height: 50px;" fxLayout fxLayoutAlign="center center" class="footer">
        <span style="color: #bcbcbc">&#9400; &nbsp;</span>
        <p class="footer-text"> Copyright APT. Handcrafted by <a href="https://eulogik.com" target="_blank">Eulogik</a>,
            India &nbsp;</p><span style="font-size: 16px; height: 16px; color: #fff;">&#10084;</span>
    </div>

</div>