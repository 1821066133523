import { Component, OnInit } from '@angular/core';
import { GetDataServiceService } from '../../../service/get-data-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { base_url } from '../../../service/config';
import { AuthGuardGuard } from '../auth-guard.guard';

@Component({
  selector: 'app-test-camp-instructions',
  templateUrl: './test-camp-instructions.component.html',
  styleUrls: ['./test-camp-instructions.component.scss']
})
export class TestCampInstructionsComponent implements OnInit {

  dir = base_url + 'uploads/testCampaign/download/';
  logo = sessionStorage.getItem('testLogo')
  
  public mobileDevice: boolean = false;
  public campaignId:any;
  public rollNo:any;
  public prevPercent:any;
  public mobile:any;
  public data:any;
  public instructionsBool:boolean=false;
  public dataLoaded:boolean;

  constructor(public ps: GetDataServiceService, public route: ActivatedRoute,public router: Router,public activate:AuthGuardGuard) { 
      this.campaignId=this.route.snapshot.queryParamMap.get('campaignId');
      this.rollNo=this.route.snapshot.queryParamMap.get('roll');
      this.mobile=this.route.snapshot.queryParamMap.get('mobile');
      this.prevPercent=this.route.snapshot.queryParamMap.get('percentage');
      
  }

  ngOnInit(): void {
    this.getWidth();
    let url=base_url+'RemoteMethods/getQuestions';
    let postData={
      previousMarks:this.prevPercent,
      rollNumber:this.rollNo,
      mobile:this.mobile,
      campaignId:this.campaignId
    }
    this.ps.PostData(postData,url).subscribe((response:any)=>{
      console.log(response);
      this.dataLoaded=true;
      if(response.statusCode===408){

        this.data=response.data[0];
        this.instructionsBool=true;
        this.activate.setCanActivate(true)
      }

      else
      {
        this.router.navigate(['test-campaign/start-test'],{queryParams:{id:sessionStorage.getItem('campId')}});
      }

    })
  }
  getWidth(event?) {
    this.mobileDevice = event ? event.target.innerWidth <= 959 ? true : false : window.innerWidth <= 959 ? true : false
  }

  submit()
  {
    this.router.navigate(['test-campaign/test-screen'],{ queryParams: {roll: this.rollNo, 
      mobile:this.mobile,percentage:this.prevPercent,campaignId:this.campaignId}})
  }

}