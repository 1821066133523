import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { GetDataServiceService } from '../../service/get-data-service.service';
import { base_url, apt_url } from "../../service/config";
import { DOCUMENT } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, Subscription, timer } from 'rxjs';
import { flyInOut, expand } from '../../animation-details/animation-details.component';
const NUM_REGEX = /^[1-9][0-9]{5,19}$/;
const NAME_REGEX = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
const Name_REGEX = /^[A-Za-z ]+$/;
const EMAIL_REGEX = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
const text_regex = /^[a-zA-Z,'-'' '\.0-9\n]+$/
@Component({
	selector: 'app-blogs-detail',
	templateUrl: './blogs-detail.component.html',
	styleUrls: ['./blogs-detail.component.scss', '../blogs/blogs.component.scss'],
	animations: [
		flyInOut(),
		expand(),
	]
})
export class BlogsDetailComponent implements OnInit {

	public showloader: boolean = false;
	private subscription: Subscription;
	private timer: Observable<any>;

	public loading1: boolean = true;
	public loading2: boolean = true;
	public loading3: boolean = true;
	public loading4: boolean = true;
	public loading5: boolean = true;
	public searchPost: any = [];
	public searchValue: string = '';
	public details: string = '';
	public allcategory: any = [];
	public AllBlogs: any = [];
	public blogDetail: any = {};
	public dataSource: any;
	public blogsId: any = '';
	public commentsForm: FormGroup;
	public replyForm: FormGroup;
	public adminname: string = '';
	public imageUrl: string = base_url + 'uploads/blogs/download/';
	// urm source related variables
	public utm_source: string = '';
	public utm_medium: string = '';
	public campaignType: string = '';
	public publisherId: string = '';
	public commentsCount: number = 0;
	public len: number = 0;
	public bool1: boolean;
	public value: any;
	public mobileMax: any;
	public flag: boolean;
	public allCountries: any;
	public count: number = 0;
	public keycount: number = 0;
	public categcount: number = 0;
	public apiBlogData: any;
	public defaultCountryCode: any;

	public slug: string = ''
	constructor(public fb: FormBuilder, public route: ActivatedRoute, public router: Router, public blogService: GetDataServiceService, @Inject(DOCUMENT) document, public snakBar: MatSnackBar) {
		this.getCountries();
	}


	fetchBlogId = (slug: string) => {
		return new Promise((resolve, reject) => {
			let url = base_url + `Blogs?filter={"where": {"status": "published", "slug": "${slug}"}}`;
			this.blogService.getData(url).subscribe((data: any) => {
				resolve(data.data[0].id)
			});
		})
	}

	blogPredefinedData() {
		let url = base_url + 'Blogs?filter={"where": {"status": "published"},"include": ["categories","staff"],"order" : "createdAt ASC"}';
		this.blogService.getData(url).subscribe((data: any) => {
			console.log('Blog Data is', data);
			this.apiBlogData = data.data;
		});
	}

	ngOnInit(): void {
		this.route.params.subscribe(async (res) => {
			console.log(res)
			this.slug = res.title
			this.fetchBlog(this.slug)
			this.blogsId = await this.fetchBlogId(this.slug);
			this.utm_source = this.route.snapshot.queryParamMap.get('utm_source');
			if (this.utm_source !== null) {
				this.utm_medium = this.route.snapshot.queryParamMap.get('utm_medium');
				this.campaignType = this.route.snapshot.queryParamMap.get('campaigntype');
				this.publisherId = this.route.snapshot.queryParamMap.get('publisherid');
			} else { }
			this.blogPredefinedData();
		})
		this.getRecentBlogs();
		this.getCategories();
		this.getAllBlogs();
		this.inilizeForm();
		this.getBlogsCommentsCount();
		this.getWidth();
		let userValues = sessionStorage.getItem('user_comment') ? JSON.parse(sessionStorage.getItem('user_comment')) : null
		if (userValues) {
			this.setFormValue(userValues)
		}
	}

	inilizeForm() {
		// INILIZE FORM GROUP HERE 
		this.commentsForm = this.fb.group({
			name: new FormControl('', [Validators.required, Validators.pattern(NAME_REGEX)]),
			email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)]),
			mobile: new FormControl('', [Validators.required, Validators.pattern(NUM_REGEX)]),
			query: new FormControl('', [Validators.required, Validators.maxLength(500)]),
			country: new FormControl('', [Validators.required]),
		});

		this.replyForm = this.fb.group({
			name: new FormControl('', [Validators.required, Validators.pattern(NAME_REGEX)]),
			email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)]),
			mobile: new FormControl('', [Validators.required, Validators.pattern(NUM_REGEX)]),
			query: new FormControl('', [Validators.required, Validators.maxLength(500)]),
			country: new FormControl('', [Validators.required]),
		});
	}


	blogData: any;
	blog: any;
	comments: any;
	getBlogData(id?) {
		//	let url = base_url + 'Blogs/' + (id ? id : this.blogsId) + '?filter={"where": {"status": "published"},"include": [{"relation":"comments","scope":{"include":{"relation":"Reply"}},"status":"active"},"categories","staff"]}'
		//	let url = base_url + 'Blogs/' + (id ? id : this.blogsId) + '?filter={"where": {"status": "published"},"include": [{"relation":"comments","scope":{"where": {"status": "active"}, "include":{"relation":"Reply"}}},"categories","staff"]}'
		let url = base_url + 'Blogs/' + (id ? id : this.blogsId) + '?filter={"where": {"status": "published"},"include": [{"relation":"comments","scope":{"where": {"status": "active"}, "include":{"relation":"Reply", "scope":{"where": {"status": "active"}}}}},"categories","staff"]}';
		this.blogService.getData(url).subscribe((data: any) => {
			console.log('Particular blog', data);
			this.blog = data.data;
		})
	}

	fetchBlog(slug?) {
		let url = base_url + `Blogs/?filter={"where": { "slug": "${slug}", "status": "published"},"include": [{"relation":"comments","scope":{"where": {"status": "active"}, "include":{"relation":"Reply", "scope":{"where": {"status": "active"}}}}},"categories","staff"]}`;
		this.blogService.getData(url).subscribe((data: any) => {
			console.log('Particular blog', data);
			this.blog = data.data[0];
		})
	}



	recentBlogs: any = []
	getRecentBlogs() {
		let url = base_url + 'Blogs?filter={"limit": 3, "fields": ["title", "cover", "id", "createdBy", "slug"] , "where": {"status": "published"},"include": { "relation": "staff", "scope": {"fields": ["name"]}},"order" : "createdAt DESC"}'
		this.blogService.getData(url).subscribe((data: any) => {
			this.recentBlogs = data.data
		})
	}


	getCategories() {
		// GET BLOGS CATEGORY HERE 
		let url = base_url + 'Categories?filter={"where": {"status": "published"}}'
		this.blogService.getData(url).subscribe(response => {
			console.log("all blogs category is here ", response);
			let temp: any = response;
			this.allcategory = temp.data;
			this.loading2 = false;
		}, error => {
			console.log("blogs category geting error ", error);
		});
	}


	mobileDevice: boolean = false
	getWidth(event?) {
		this.mobileDevice = event ? event.target.innerWidth <= 600 ? true : false : window.innerWidth <= 600 ? true : false
	}


	showPosts: boolean = false
	searchBlogs(text: string) {
		if (text.length) {
			this.showPosts = true
			this.dataSource.filter = text
			this.allPosts = this.dataSource.filteredData
		}
		else this.showPosts = false
	}

	filterByCat(category) {
		this.showPosts = true
		this.allPosts = this.dataSource.filteredData.filter(blog => blog.categoryIds.includes(category.id))
		if (this.mobileDevice) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	}

	resetFilter() {
		this.showPosts = false;
		if (this.mobileDevice) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	}

	showAll(element) {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
		this.showPosts = true;
		this.allPosts = this.dataSource.filteredData;
	}


	allPosts: any = []
	async getAllBlogs() {
		let url = base_url + 'Blogs?filter={"where": {"status": "published"},"include": ["categories","staff"],"order" : "createdAt DESC"}'
		this.blogService.getData(url).subscribe(async (response: any) => {
			this.getRecentBlogs();
			console.log("all blogs is here ", response);
			console.log(response);
			response.data = await this.genPreviewData(response.data)
			this.dataSource = new MatTableDataSource(response.data);
			this.allPosts = this.dataSource && this.dataSource.filteredData ? this.dataSource.filteredData : [];
		}, error => {
			console.log("blogs geting error ", error);
		});
	}

	genPreviewData(allBlogs) {
		return new Promise((resolve) => {
			for (let i = 0; i < allBlogs.length; i++) {
				const blog = allBlogs[i];
				blog.preview = this.showdata(blog.content, i)
				if (i === allBlogs.length - 1) resolve(allBlogs)
			}
		})
	}

	showdata(data, index) {
		document.getElementById('b-content').innerHTML = data;
		let text = document.getElementById('b-content').innerText.substring(0, 500)
		return text
	}

	openBlog(data) {
		this.showPosts = false
		this.router.navigate(['/blogs', data.slug], { replaceUrl: true })
		this.blog = null;
		this.getBlogData(data.id)
	}


	returnValName(array: any, type: string) {
		var returnArray: any = [];
		if (type === 'cat') {
			if (array.length > 0) {
				for (let index = 1; index < array.length; index++) {
					returnArray.push(array[index].title)
				}
				return returnArray.toString().replace(/,/g, ", ")
			}
			else
				return ''
		}
		else {
			if (array.length > 0) {
				for (let index = 1; index < array.length; index++) {
					returnArray.push(array[index])
				}
				return returnArray.toString().replace(/,/g, ", ")
			}
			else
				return ''
		}
	}


	public ngOnDestroy() {
		if (this.subscription && this.subscription instanceof Subscription) {
			this.subscription.unsubscribe();
		}
	}


	onCountryChange(event) {
		if (event.dial_code == 91) {
			this.mobileMax = 10;
			this.flag = true;
		} else {
			this.mobileMax = 15;
			this.flag = false;
		}
	}

	getCountries() {
		let url = 'assets/jsonFile/allCountryCodes.json'
		console.log(url);
		this.blogService.getData(url).subscribe((response: any) => {
			response.forEach((e: any) => {
				if (e.name === 'India') {
					this.defaultCountryCode = e;
				}
			});
			this.allCountries = response;
		})
	}

	setFormValue(values) {
		// SETS VALUE FOR THE FORM
		this.commentsForm.patchValue({
			name: values.name,
			email: values.email,
			mobile: values.mobile,
			countrycode: values.countrycode
		})
		this.replyForm.patchValue({
			name: values.name,
			email: values.email,
			mobile: values.mobile,
			countrycode: values.countrycode
		})
	}



	getBlogsCommentsCount() {
		let url = base_url + 'Comments/count?"where": { "blogId": "' + this.blogsId + '", "status": "active"}'
		this.blogService.getData(url).subscribe((response: any) => {
			console.log("Comments counts ", response);
			let temp: any = response;
			this.commentsCount = temp.data.count;
			console.log(this.commentsCount);
		}, error => {
			console.log("blogs category geting error ", error);
		});
	}

	returnTime(timestamp: any) {
		// COMMENTS TIME SHOW 
		var hour = (Math.abs(Date.now() - Date.parse(timestamp)) / 36e5);
		var mins = Math.round(hour * 60);
		var days = Math.round(hour / 24);
		var month = Math.round(days / 24);
		if (mins <= 60) {
			if (mins <= 1) {
				return timestamp = 'Just now'
			}
			else if (mins > 1 && mins < 2) {
				return timestamp = 'About a min ago'
			}
			else if (mins === 60 || hour === 1) {
				return timestamp = 'About an hour ago'
			}
			else {
				return timestamp = Math.round(mins) + ' mins ago'
			}
		}
		else if (mins > 60 && hour <= 24) {
			if (hour < 24) {
				return timestamp = Math.round(hour) + ' hours ago'
			}
			else if (hour === 24) {
				return timestamp = 'About a day ago'
			}
		}
		else if (hour > 24 && hour < 720) {
			return timestamp = Math.round(days) + ' days ago'
		}
		else if (hour >= 720) {
			return timestamp = Math.round(month) + ' months ago'
		}
	}


	submitForm() {
		console.log("do nothigs", this.commentsForm.value);
		console.log(this.commentsForm.touched);
		if (this.commentsForm.valid) {
			sessionStorage.setItem('user_comment', JSON.stringify(this.commentsForm.value))
			let postData: any = {};
			if (this.utm_source !== null) {
				postData = {
					"blogId": this.blogsId,
					"createdAt": new Date(),
					"comment": this.commentsForm.value.query.toString().trim(),
					"status": "active",
					"user": {
						"name": this.commentsForm.value.name,
						"country": this.commentsForm.value.country.name,
						"countryCode": this.commentsForm.value.country.dial_code,
						"mobile": this.commentsForm.value.mobile,
						"email": this.commentsForm.value.email
					},
					"utm_medium": this.utm_medium,
					"compaigntype": this.campaignType,
					"publisherid": this.publisherId,
					"utm_source": this.utm_source,
				}

			} else {
				postData = {
					"blogId": this.blogsId,
					"createdAt": new Date(),
					"comment": this.commentsForm.value.query.toString().trim(),
					"status": "active",
					"user": {
						"name": this.commentsForm.value.name,
						"country": this.commentsForm.value.country.name,
						"countrycode": this.commentsForm.value.country.dial_code,
						"mobile": this.commentsForm.value.mobile,
						"email": this.commentsForm.value.email
					}
				}
			}

			let url = base_url + 'Comments'
			this.blogService.PostData(postData, url).subscribe(res => {
				console.log("Comments updated ", res);
				this.snakBar.open('Comments Sent successfully !', 'OK', {
					duration: 3000
				})
				//this.commentsForm.reset();
				this.commentsForm.patchValue({
					query: "",
				});
				Object.keys(this.commentsForm.controls).forEach(key => {
					this.commentsForm.get(key).setErrors(null);
				})
				this.getBlogData()
				// this.TopicData = res;
			}, error => {
				console.log("commets add error", error);
				this.snakBar.open('Failed to send comments', 'OK', {
					duration: 3000
				})
			});
		}
		else {
			this.snakBar.open('Please enter required fields', 'OK', {
				duration: 3000
			})
		}
	}


	public showReplyForm: number;
	public replyData: any = {};
	// reply function start here
	replyMessage(data, index) {
		this.showReplyForm = index;
		this.replyData = data;
		this.blog.comments[index].showReplyForm = true;
	}

	// reply methof call api here
	ReplyForm() {
		console.log("reply thread method is here", this.replyForm.value);
		console.log()
		if (this.replyForm.valid) {
			let postData: any = {};
			if (this.utm_source !== null) {
				postData = {
					"blogId": this.blogsId,
					"createdAt": new Date(),
					"replyId": this.replyData.id,
					"comment": this.replyForm.value.query.toString().trim(),
					"status": "active",
					"user": {
						"name": this.replyForm.value.name,
						"country": this.replyForm.value.country.name,
						"countrycode": this.replyForm.value.country.dial_code,
						"mobile": this.replyForm.value.mobile,
						"email": this.replyForm.value.email
					},
					"utm_medium": this.utm_medium,
					"compaigntype": this.campaignType,
					"publisherid": this.publisherId,
					"utm_source": this.utm_source,
				}

			} else {
				postData = {
					"blogId": this.blogsId,
					"createdAt": new Date(),
					"replyId": this.replyData.id,
					"comment": this.replyForm.value.query.toString().trim(),
					"status": "active",
					"user": {
						"name": this.replyForm.value.name,
						"country": this.replyForm.value.country.name,
						"countrycode": this.replyForm.value.country.dial_code,
						"mobile": this.replyForm.value.mobile,
						"email": this.replyForm.value.email
					},
				}
			}

			let url = base_url + 'Comments'
			this.blogService.PostData(postData, url).subscribe(res => {
				console.log("Comments updated ", res);
				this.snakBar.open('Comment Sent successfully !', 'OK', {
					duration: 3000
				})
				//this.commentsForm.reset();
				this.replyForm.patchValue({
					query: "",
				});
				Object.keys(this.replyForm.controls).forEach(key => {
					this.replyForm.get(key).setErrors(null);
				})
				this.getBlogData();
				this.blogDetail.comments[this.showReplyForm].showReplyForm = false;
				// this.TopicData = res;
			}, error => {
				console.log("comments add error", error);
				this.snakBar.open('Failed to send comment', 'OK', {
					duration: 3000
				})
			});
		} else {

			this.snakBar.open('Please Enter required fields', 'OK', {
				duration: 3000
			})
		}
	}
}



// THERE ARE SO MANY UNUSED FUNCTIONS AND VARIABLES - I HAD NO TIME TO REMOVE THEM