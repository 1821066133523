
<div class="no-data full-width" *ngIf="!allBlogs" fxLayoutAlign="center center">
    <ngx-loading [show]="true"
        [config]="{backdropBackgroundColour: 'rgba(0, 0, 0, 0)', primaryColour: '#54ACE4' , secondaryColour: '#2486C5', tertiaryColour:  '#0C5C8F' }">
    </ngx-loading>
</div>

<div *ngIf="allBlogs" class="full-width" (window:resize)="getWidth($event)">
    <div class="full-width" fxLayout fxLayoutAlign="center center">
        <h1 class="courseHeading1">
            News & Views
        </h1>
    </div>
    <br>
    <div fxLayout="column" fxLayoutAlign="center start" style="width: 100%; padding: 10px 20px;" *ngIf="mobileDevice">
        <div fxLayout fxLayoutAlign="start center">
            <p style="margin-left: 0px;" class="serchHeadings">
                SEARCH POSTS
            </p>
        </div>
        <div style="width: 100%; margin-bottom: 20px; margin-left: 0px;" class="blogDivider">

        </div>

        <mat-form-field style="width: 100%;" appearance="outline">
            <mat-label> Let's Search </mat-label>
            <input matInput autocomplete="off" type="search" [(ngModel)]="searchValue" id="search" name="search"
                (keyup)="applyFilter(searchValue)">
            <mat-icon matSuffix *ngIf="searchValue == 'about'">search</mat-icon>
            <button *ngIf="searchValue" mat-button matSuffix mat-icon-button aria-label="Clear"
                (click)="applyFilter(searchValue)">
                <mat-icon (click)="OnSearch()">close</mat-icon>
            </button>
            <mat-error>Not Found</mat-error>
        </mat-form-field>
    </div>
    <br>
    <div class="full-width" fxLayout fxLayoutAlign="center center">
        <div fxFlex="80" fxFlex.lt-md="96" fxLayout fxLayout.sm="column" fxLayout.xs="column" fxLayout.md="column"
            fxLayoutAlign="space-between">
            <div fxLayout="column" fxFlex.lt-md="96" fxFlex.md="80" fxFlex="65">

                <div *ngIf="allBlogs && !allBlogs?.length" style="height: 500px;" class="full-width" fxLayout
                    fxLayoutAlign="center center">
                    <h2 class="mat-h2">NO POSTS FOUND</h2>
                </div>

                <div fxFlex="100" fxLayout="column" fxLayoutGap.xs="40px" fxLayoutGap="80px" *ngIf="allBlogs?.length">
                    <all-blogs [allBlogs]="allBlogs" (open)="BlogDetail($event)"></all-blogs>
                </div>

                <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
            </div>

            <div fxLayout fxLayoutAlign="end start" *ngIf="!mobileDevice" fxFlex.md="60" fxFlex="30">
                <div fxLayout fxFlex="100">
                    <div fxLayout fxLayoutAlign="center center" fxFlex="100">
                        <div fxFlex="100">
                            <div fxLayout fxLayoutAlign="start center">
                                <p class="serchHeadings">SEARCH POSTS</p>
                            </div>
                            <div class="blogDivider">

                            </div>
                            <p>&nbsp;</p>

                            <div fxLayout fxLayoutAlign="start center">
                                <div fxFlex="100" fxLayoutAlign="center center">
                                    <mat-form-field appearance="outline" class="Sarchmargin">
                                        <mat-label> Let's Search </mat-label>
                                        <input matInput autocomplete="off" type="search" [(ngModel)]="searchValue"
                                            id="search" name="search" (keyup)="applyFilter(searchValue)">
                                        <mat-icon matSuffix *ngIf="searchValue == 'about'">search</mat-icon>
                                        <button *ngIf="searchValue" mat-button matSuffix mat-icon-button
                                            aria-label="Clear" (click)="applyFilter(searchValue)">
                                            <mat-icon (click)="OnSearch()">close</mat-icon>
                                        </button>
                                        <mat-error>Not Found</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <br>
                            <div fxLayout fxLayoutAlign="start center">
                                <p class="serchHeadings">
                                    CATEGORIES
                                </p>
                            </div>
                            <div class="blogDivider">

                            </div>

                            <p>&nbsp;</p>

                            <div fxLayout fxLayoutAlign="start center">
                                <div fxFlex="100" fxLayoutAlign="start center">
                                    <div fxFlex="100">
                                        <div style="height: 180px; overflow: auto;" fxLayout="column">
                                            <button mat-button class="example-item" fxLayout
                                                fxLayoutAlign="start center" (click)="GetBlogs()">
                                                <mat-icon style="height: 8px; width: 8px; font-size: 8px !important;"
                                                    mat-list-icon>panorama_fish_eye
                                                </mat-icon>
                                                &nbsp;
                                                <span mat-line>All</span>
                                            </button>
                                            <button mat-button *ngFor="let category of Allcategory" class="example-item"
                                                fxLayout fxLayoutAlign="start center" (click)="filterByCat(category)">
                                                <mat-icon style="height: 8px; width: 8px; font-size: 8px !important;"
                                                    mat-list-icon>panorama_fish_eye
                                                </mat-icon>
                                                &nbsp;
                                                <span mat-line>{{category.title}}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>&nbsp;</p>
                            <div fxLayout fxLayoutAlign="start center">
                                <p class="serchHeadings">
                                    RECENT POSTS
                                </p>
                            </div>
                            <div class="blogDivider">

                            </div>
                            <br>
                            <div fxLayout="column" fxLayoutGap="15px">
                                <div style="padding: 10px;" hovFocus fxLayout fxLayoutGap="10px" fxLayoutAlign="start center" *ngFor="let blog of recentBlogs;" (click)="BlogDetail(blog)">
                                    <img class="preview-cover" [src]="imageUrl + blog.cover" alt="b">
                                    <div fxFlex="70" fxLayout="column" fxLayoutGap="0px" fxFlex="100">
                                        <h3 class="mat-h3"><b style="font-weight: 500;">{{blog.title}}</b></h3>
                                        <div fxLayout fxLayoutGap="15px" fxLayoutAlign="start center">
                                            <mat-icon>people_outline</mat-icon>
                                            <div class="cut-text">By {{blog.staff.name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div fxLayout fxLayoutAlign="center center" *ngIf="mobileDevice">
                <div fxFlex="100" fxLayout="column" fxLayoutAlign="start start">
                    <div fxLayout fxLayoutAlign="start center">
                        <p class="serchHeadings">
                            CATEGORIES
                        </p>
                    </div>
                    <div class="blogDivider">

                    </div>
                    <p>&nbsp;</p>
                    <div style="width: 100%;" fxLayout fxLayoutAlign="start center">
                        <div fxFlex="100" fxLayoutAlign="start center">
                            <div fxFlex="100">
                                <div style="height: 180px; overflow: auto;" fxLayout="column">
                                    <button mat-button class="example-item" fxLayout fxLayoutAlign="start center"
                                        (click)="GetBlogs()">
                                        <mat-icon style="height: 8px; width: 8px; font-size: 8px !important;"
                                            mat-list-icon>panorama_fish_eye
                                        </mat-icon>
                                        &nbsp;
                                        <span mat-line>All</span>
                                    </button>
                                    <button mat-button *ngFor="let category of Allcategory" class="example-item"
                                        fxLayout fxLayoutAlign="start center" (click)="filterByCat(category)">
                                        <mat-icon style="height: 8px; width: 8px; font-size: 8px !important;"
                                            mat-list-icon>panorama_fish_eye
                                        </mat-icon>
                                        &nbsp;
                                        <span mat-line>{{category.title}}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                    <div fxLayout fxLayoutAlign="start center">
                        <p class="serchHeadings">
                            RECENT POSTS
                        </p>
                    </div>
                    <div class="blogDivider">

                    </div>
                    <br>
                    <div class="full-width" fxLayout="column" fxLayoutGap="15px">
                        <div style="padding: 10px;" hovFocus fxLayout fxLayoutGap="10px" fxLayoutAlign="start center" *ngFor="let blog of recentBlogs;" (click)="BlogDetail(blog)"> 
                            <img class="preview-cover" [src]="imageUrl + blog.cover" alt="b">
                            <div fxFlex="70" fxLayout="column" fxLayoutGap="0px" fxFlex="100">
                                <h3 class="mat-h3"><b style="font-weight: 500;">{{blog.title}}</b></h3>
                                <div fxLayout fxLayoutGap="15px" fxLayoutAlign="start center">
                                    <mat-icon>people_outline</mat-icon>
                                    <div class="cut-text">By {{blog.staff.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    </div>
</div>



<div style="display: none;" id="b-content"></div>