<mat-toolbar role="banner" *ngIf="!inFeedback" class="toolbar " id="navbar" style="background: #ffffff; display:none" fxLayout
    fxLayoutAlign="space-between center">
    <div *ngIf="!(smallScreen && !showSearch)" fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
        <button [disableRipple]="true" class="btn" mat-icon-button (click)="sidenav.toggle()"
            *ngIf="smallScreen == true">
            <mat-icon (mouseover)="sidenav.open()">menu</mat-icon>
        </button>
        <a href="">
            <img src="https://v2.aptstudy.com/wp-content/uploads/2018/11/logo.png" height="50">
        </a>
    </div>
    <span class="spacer"></span>
    <div fxLayout fxLayoutAlign="start center" *ngIf="smallScreen !== true">
        <button mat-button [disableRipple]="true" class="px-16 btn" *ngIf="showSearch == true"
            routerLinkActive="active-route" (click)="navigatePage('home')" [routerLink]="['/home']">Home</button>
        <button mat-button [disableRipple]="true" class="px-16 btn" *ngIf="showSearch == true"
            routerLinkActive="active-route" (click)="navigatePage('downloads')"
            [routerLink]="['/downloads']">Downloads</button>
        <button mat-button [disableRipple]="true" class="px-16 btn" *ngIf="showSearch == true"
            routerLinkActive="active-route" [matMenuTriggerFor]="menu" (mouseenter)="menu"> Earn With Us </button>
        <button mat-button [disableRipple]="true" class="px-16 btn" *ngIf="showSearch == true"
            routerLinkActive="active-route" (click)="navigatePage('school-tie-ups')"
            [routerLink]="['/school-tie-ups']">Tie-Up</button>
        <button mat-button [disableRipple]="true" class="px-16 btn" *ngIf="showSearch == true"
            routerLinkActive="active-route" (click)="navigatePage('contact-us')" [routerLink]="['/contact-us']">Contact
            Us</button>

        <mat-menu #menu="matMenu">
            <button mat-menu-item [disableRipple]="true" class="btn no-hover-effect"
                (click)="navigatePage('Join-team-apt')">Join Team APT </button>
            <button mat-menu-item [disableRipple]="true" class="btn no-hover-effect"
                (click)="navigatePage('work-as-Collabotor')">Work As a Collaborator</button>
            <button mat-menu-item [disableRipple]="true" class="btn no-hover-effect"
                (click)="navigatePage('video-faculty')">Work As a Video Faculty</button>
            <button mat-menu-item [disableRipple]="true" class="btn no-hover-effect"
                (click)="navigatePage('Language-translator')">Work As a Language Translator</button>
            <button mat-menu-item [disableRipple]="true" class="btn no-hover-effect"
                (click)="navigatePage('Dubbing-Artist')">Work As a Dubbing Artist</button>
        </mat-menu>
        <button mat-icon-button *ngIf="showSearch == true " (click)="openSearch()">
            <mat-icon>search</mat-icon>
        </button>
    </div>
    <mat-form-field fxFlex="400px" floatLabel="never" *ngIf="showSearch == false && smallScreen !== true">
        <mat-label class="standard" style="color:black">Search</mat-label>
        <input matInput #search autocomplete="off" (keyup.enter)="SearchData(search.value)" style="color:black">
        <button matSuffix mat-icon-button (click)="SearchData(search.value)">
            <mat-icon (click)="CloseHome()">close</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field floatLabel="never" *ngIf="showSearch == false && smallScreen ==  true" fxFlex="100">
        <mat-label class="searchFont">Search</mat-label>
        <input matInput #search autocomplete="off" (keyup.enter)="SearchData(search.value)" style="color:black">
        <button matSuffix mat-icon-button (click)="SearchData(search.value)">
            <mat-icon (click)="CloseHome()">close</mat-icon>
        </button>
    </mat-form-field>
    <button class="search-btn" mat-icon-button *ngIf="showSearch == true && smallScreen == true" (click)="openSearch()">
        <mat-icon>search</mat-icon>
    </button>
</mat-toolbar>

<mat-sidenav-container (window:resize)="getWidth()">
    <mat-sidenav #sidenav [mode]="sidenavMode" (openedStart)="navOpened(true)" (closedStart)="navOpened(false)" [opened]="sidenavMenu" class="sidnav-menu">
        <mat-nav-list *ngIf="smallScreen == true" style="overflow: hidden">
            <mat-list-item (click)="sideNavOpenClose(sidenav.mode);navigatePage('home');" [routerLink]="['/home']"
                routerLinkActive="active-route">
                <a class="menu-s" matLine>Home</a>
            </mat-list-item>
            <mat-list-item (click)="sideNavOpenClose(sidenav.mode);navigatePage('downloads');"
                routerLinkActive="active-route" [routerLink]="['/downloads']">
                <a class="menu-s" matLine>Downloads</a>
            </mat-list-item>
            <!-- <mat-list-item class="font_size18" routerLinkActive="active-route"  [routerLink]="['/Earn-with-us/']"> -->
            <!-- <a matLine> -->
            <mat-accordion matLine routerLinkActive="active-route">
                <mat-expansion-panel (opened)="expansionColor=true" (closed)="expansionColor=false">
                    <mat-expansion-panel-header class="changeColor">
                        <mat-panel-title>
                            <span [style.color]="expansionColor?'red':'black'"
                                [style.fontWeight]="expansionColor?'800':'initial'">Earn With Us</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-list role="list">
                        <mat-list-item role="listitem"
                            (click)="sideNavOpenClose(sidenav.mode);navigatePage('Join-team-apt');"
                            routerLinkActive="active-route" [routerLink]="['/Earn-with-us/Join-team-apt']">
                            <p matLine class="menu-s">Join Team APT</p>
                        </mat-list-item>
                        <mat-list-item role="listitem"
                            (click)="sideNavOpenClose(sidenav.mode);navigatePage('work-as-Collabotor');"
                            routerLinkActive="active-route" [routerLink]="['/Earn-with-us/work-as-Collabotor']">
                            <p matLine class="menu-s">Work As a Collaborator</p>
                        </mat-list-item>
                        <mat-list-item role="listitem"
                            (click)="sideNavOpenClose(sidenav.mode);navigatePage('video-faculty');"
                            routerLinkActive="active-route" [routerLink]="['/Earn-with-us/video-faculty']">
                            <p matLine class="menu-s">Work As a Video Faculty</p>
                        </mat-list-item>
                        <mat-list-item role="listitem"
                            (click)="sideNavOpenClose(sidenav.mode);navigatePage('Language-translator');"
                            routerLinkActive="active-route" [routerLink]="['/Earn-with-us/Language-translator']">
                            <p matLine class="menu-s">Work As a Language Translator</p>
                        </mat-list-item>
                        <mat-list-item role="listitem"
                            (click)="sideNavOpenClose(sidenav.mode);navigatePage('Dubbing-Artist');"
                            routerLinkActive="active-route" [routerLink]="['/Earn-with-us/Dubbing-Artist']">
                            <p matLine class="menu-s">Work As a Dubbing Artist</p>
                        </mat-list-item>
                    </mat-list>
                </mat-expansion-panel>
            </mat-accordion>
            <!-- </a> -->
            <!-- </mat-list-item> -->
            <mat-list-item (click)="sideNavOpenClose(sidenav.mode);navigatePage('school-tie-ups');"
                routerLinkActive="active-route" [routerLink]="['/school-tie-ups']">
                <a class="menu-s" matLine>Tie-Up</a>
            </mat-list-item>
            <mat-list-item (click)="sideNavOpenClose(sidenav.mode);navigatePage('contact-us');"
                routerLinkActive="active-route" [routerLink]="['/contact-us']">
                <a class="menu-s" matLine>Contact Us</a>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content id="main" class="main">
        <div class="main_div" fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
            <div fxFlex="100">
                <main>
                    <router-outlet (activate)='routeChange($event)' #o="outlet"></router-outlet>
                </main>
                <app-footer *ngIf="!inFeedback"></app-footer>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>