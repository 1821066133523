

<div class="full-width" *ngIf="dataLoaded">
    <div class="full-width" fxLayout fxLayoutAlign="center center">
        <div style="padding: 20px 0;" fxFlex="90">
            <img [src]="dir + timerResponse.logo" height="75px" alt="test_logo">
        </div>
    </div>
<!--
    <div class="full-width" fxLayout="column" fxLayoutAlign="center center">
        <div *ngIf="isFormVisible" fxFlex="180px" fxFlex.md="150px" fxFlex.sm="120px" fxFlex.xs="90px">
            
        </div>
        <div *ngIf="isFormVisible" fxFlex="120px" fxFlex.md="100px" fxFlex.sm="75px" fxFlex.xs="60px">
            
        </div>
    </div> -->

    <div class="full-width" fxLayout fxLayoutAlign="center center" (window:resize)="getWidth($event)">
        <div *ngIf="!isFormVisible" fxFlex="70" fxFlex.xs="95" fxFlex.sm="85" fxFlex.md="80" fxLayoutGap="50px"
            fxLayoutGap.sm="40px" fxLayoutGap.xs="30px" fxLayout="column" fxLayoutAlign="center center">
            <h1 [class]="mobileDevice ? 'mat-display-2' : 'mat-display-4'"
                [style.color]="timerResponse?.timerTitle[0].color">{{timerResponse?.timerTitle[0].text}}</h1>
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="50px">
                <p class="mat-body-strong text-c">
                    {{timerResponse?.timerDescription}}
                </p>

                <div fxFlex="150px" fxLayout fxLayoutAlign="center center">
                    <div *ngIf="!timerOff" fxLayout.xs="column" fxLayout.sm="column" fxLayout
                        fxLayoutAlign="center center" fxLayoutGap="80px">
                        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
                            <!-- <h2 *ngIf="daysBool" class="mat-display-3"><b>{{daysLeft}} DAYS LEFT</b></h2>
                            <h2 *ngIf="dayBool" class="mat-display-3"><b>{{daysLeft}} DAY LEFT</b></h2> -->
                            <h1 class="mat-display-3 px-timer">
                                <span *ngIf="daysBool || dayBool">
                                    {{ daysLeft + 'D : ' }}
                                </span>
                                {{CurrentTime}}
                            </h1>
                        </div>
                    </div>

                    <button *ngIf="timerOff" mat-flat-button color="primary" fxFlex="300px"
                        (click)="isFormVisible = true">
                        <b class="white">START TEST</b>
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="isFormVisible" fxFlex="70" fxFlex.xs="95" fxFlex.sm="85" fxFlex.md="80" fxLayoutGap="50px"
            fxLayoutGap.sm="40px" fxLayoutGap.xs="30px" fxLayout fxLayout.xs="column" fxLayout.sm="column"
            fxLayoutAlign="center">

            <div fxFlex="50" fxFlex.xs="100" fxFlex.gt-md="65">
                <h1 [style.color]="timerTimeResponse?.title[0]?.color" class="mat-display-1 text-c">
                    <b>{{timerTimeResponse?.title[0]?.text}}</b>
                </h1>
                <div fxLayout="column" fxLayoutAlign="center start" fxLayoutAlign.xs="center center">
                    <p class="mat-body" style="font-size:x-large">{{timerTimeResponse.subtitle}}</p>
                </div>
                <div fxLayout="column" fxLayoutGap="20px" fxLayoutColumn="center start">
                    <div fxLayout="column" fxLayoutGap="10px"
                        *ngFor="let point of timerTimeResponse?.points; let i = index">
                        <p class="mat-body-strong">{{i+1}}. {{point.point}}</p>
                        <img class="mat-elevation-z3" style="border-radius: 5px;" *ngIf="point.image" width="280"
                            [src]="dir + point.image" alt="point.point">
                    </div>
                </div>
            </div>
            <div fxFlex.gt-md="35" fxFlex="50" fxFlex.xs="100" fxLayout>
                <form fxFlex="100" fxLayout="column" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign.xs="start center"
                    fxLayoutAlign.sm="start center" fxLayoutGap="25px" [formGroup]="startForm">
                    <h1 class="mat-h2 text-c">Fill Your Details</h1>
                    &nbsp; &nbsp; &nbsp;&nbsp;
                    <mat-form-field>
                        <!-- LABEL -->
                        <mat-label>Contact no.</mat-label>
                        <!-- FOR INPUT -->
                        <input matInput formControlName="mobile" autocomplete="off" type="tel" required minlength="5" maxlength="16">
                        <!-- ERROR -->
                        <mat-error *ngIf="mobile.invalid && (mobile.touched || mobile.dirty)" style="color: #f44336">
                            <mat-error *ngIf="mobile.errors?.required">Contact No is required</mat-error>
                            <mat-error *ngIf="mobile.errors?.pattern">Invalid Mobile Number</mat-error>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <!-- LABEL -->
                        <mat-label>Roll no.</mat-label>
                        <!-- FOR INPUT -->
                        <input matInput formControlName="rollNumber" autocomplete="off" required>
                        <!-- ERROR -->
                        <mat-error *ngIf="rollNumber.errors?.required">Roll No is required</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <!-- LABEL -->
                        <mat-label>Previous year percentage/grade</mat-label>
                        <!-- FOR INPUT -->
                        <input matInput formControlName="previousMarks" autocomplete="off" required>
                        <!-- ERROR -->
                        <mat-error *ngIf="previousMarks.errors?.required">Percentage/Grade is required</mat-error>
                    </mat-form-field>
                    
                        <mat-form-field  *ngFor="let form of formData;let i = index">
                            <!-- LABEL -->
					<mat-label>{{form.label}}</mat-label>

					<!-- FOR INPUT -->
					<input *ngIf="form.type === 'Textinput'" matInput formControlName="{{form.key}}" autocomplete="off"
						[required]="form.required" [numbersOnly]="form.subtype === 'number'"
						[minlength]="form.key === 'mobile' ? minLength : ''"
						[maxlength]="form.key === 'mobile' ? maxLength : ''">

					<!-- FOR DROPDOWN -->
					<mat-select *ngIf="form.type === 'Dropdown'" required formControlName="{{form.key}}"
						[required]="form.required" [multiple]="form.multiple"
						(selectionChange)="countryCheck(form.key)">
						<ng-container *ngIf="isString(form.options[0])">
							<mat-option *ngFor="let option of form.options" [value]="option">{{option}}</mat-option>
						</ng-container>
						<ng-container *ngIf="isObject(form.options[0])">
							<mat-option *ngFor="let option of form.options" [value]="option.opt">{{option.opt}}
							</mat-option>
						</ng-container>
					</mat-select>
					<!-- ERROR -->
					<mat-error *ngIf="form.required" style="color:red;">{{form.key!=="email"?(form.label):('Email
						Pattern')}} is required</mat-error>
                            
                        </mat-form-field>
                        
                    

                    <div style="width: 100%;" fxLayout fxLayoutAlign="center center">
                        <button [disabled]="startForm.invalid" fxFlex="60" fxFlex.xs="80" fxFlex.sm="70"
                            style="color: white;" type="button" mat-flat-button color="primary" (click)="submit()">
                            Next
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>



    <div class="full-width" fxLayout="column" fxLayoutAlign="center center">
        <div fxFlex="180px" fxFlex.md="150px" fxFlex.sm="120px" fxFlex.xs="90px">
            <!-- EMPTY CONTENT TO MANAGE CONTENT --BOTTOM -->
        </div>
    </div>

</div>