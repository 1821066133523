<div class="no-data" *ngIf="!aboutCourse || !allClasses" fxLayoutAlign="center center" fxFlex>
    <ngx-loading show="true"
        [config]="{ backdropBackgroundColour: 'rgba(0, 0, 0, 0)', primaryColour: '#54ACE4' , secondaryColour: '#2486C5', tertiaryColour:  '#0C5C8F' }">
    </ngx-loading>
</div>
<div *ngIf="allClasses && aboutCourse" (window:resize)="getWidth()">
    <div class="res-container" fxLayout fxLayoutAlign="center center">
        <div fxLayout fxLayoutAlign="start center" fxFlex="96">
            <div fxFlex="100">
                <br>
                <div fxLayout fxLayoutAlign="center center">
                    <h1 class="mat-display-1"> <b>Courses</b> </h1>
                </div>
                <br>
                <div fxLayout fxLayoutAlign="start" fxLayoutGap="20px" fxLayout.sm="column" fxLayout.xs="column">
                    <div fxFlex="30" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="center center" fxFill>
                        <div fxLayout fxLayoutAlign="start center" fxLayoutAlign.xs="center center"
                            fxLayoutAlign.sm="center center">
                            <img src="assets/images/Downloads.png" width="100%" alt="home">
                        </div>
                    </div>
                    <div fxFlex="70" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign.xs="center center"
                        fxLayoutAlign.sm="center center" fxLayoutAlign="start center" fxFill>
                        <div fxLayout fxLayoutAlign="start center">
                            <div [style.padding]="cols == 4 ? '30px' : '5px'" fxFlex="100">
                                <div fxLayout fxLayoutAlign="start center">
                                    <h2 class="courseHeading1">
                                        <b> {{aboutCourse?.title}}</b>
                                    </h2>
                                </div>
                                <div fxLayout fxLayoutAlign="center center">
                                    <p class="courseDis">
                                        {{aboutCourse?.description}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <br>
                <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="80px">
                    <div *ngFor="let category of allClasses; let i = index">
                        <div fxLayout fxLayoutAlign="start center">
                            <h1 class="courseHeading">
                                {{category.title}}
                            </h1>
                        </div>
                        <mat-grid-list class="full-width" [cols]="cols" [rowHeight]="cols == 4 ? '250px' : '200px'"
                            gutterSize="10">
                            <mat-grid-tile *ngFor="let class of category.class" (click)="navigate(class)"
                                class="course-img">
                                <div style="width: 100%; height: 100%;" fxLayoutAlign.xs="center" fxLayoutAlign.sm="center" fxLayout fxLayoutAlign="start">
                                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.xs="start center" fxLayoutAlign.sm="start center" fxLayoutGap="15px">
                                        <img [src]="ImageUrl + class.image" [height]="cols === 4 ? '180' : '120'"
                                            alt="Photo stream">
                                        <div class="full-width" fxLayout fxLayoutAlign="center center">
                                            <p style="font-size: 14px; text-align: center;" class="mat-body-strong">
                                                <b>{{class.name}}</b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </div>
                </div>
                <br>
            </div>
        </div>
        <br>
    </div>
    <app-download-app [from]="'courses'" [source]="utm_source" [medium]="utm_medium" [campaign]="campaignType"
        [publisher]="publisherId">
    </app-download-app>
</div>
<div *ngIf="false">
    <div fxLayout fxLayoutAlign="center center">
        <h1 class="mat-display-1">
            <b>Our Courses</b>
        </h1>
    </div>
    <br>
    <br>
    <div fxLayout="column" fxLayoutGap="40px" style="width: 100%;">
        <div *ngFor="let heading of title">
            <div fxLayout fxLayoutAlign="start center" fxLayoutGap="20px" class="MainDiv">
                <div fxFlex="100" fxLayoutAlign="start center">
                    <div fxLayout fxLayoutAlign="start center">
                        <div fxFlex="100">
                            <div fxLayout fxLayoutAlign="start center">
                                <div fxFlex="100">
                                    <div fxLayout fxLayoutAlign="start center">
                                        <h1 class="courseHeading">
                                            {{heading}}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
            <div *ngFor="let data of classData" class="MainDiv">
                <mat-grid-list *ngIf="data.title === heading" [cols]="cols" rowHeight="200px"
                    (window:resize)="getWidth()">
                    <div *ngFor="let menu of AllClass">
                        <mat-grid-tile class="course-img"
                            *ngIf="menu.products.length > 0 && menu.status === 'published' && data._id === menu.classcatId"
                            (click)="navigate(menu); ">
                            <div style="width: 100%;" fxLayout fxLayoutAlign="start center">
                                <div style="margin-bottom: 10px;">
                                    <img class="image" [src]="ImageUrl + menu.image" height="124" alt="Photo stream">
                                    <div fxLayout>
                                        <p style="font-size: 14px;" class="mat-h2">{{menu.name}}</p>
                                    </div>
                                </div>
                            </div>
                        </mat-grid-tile>
                    </div>
                </mat-grid-list>
            </div>
        </div>
    </div>
    <p>&nbsp;</p>
    <div fxLayout fxLayoutAlign="start center" class="MainDiv" fxLayoutGap="20px" fxLayout.sm="column"
        fxLayout.xs="column">
        <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="center center" fxFill>
            <div fxLayout fxLayoutAlign="center center">
                <img src="assets/images/ourCourses.png" class="imageWith" alt="home">
            </div>
        </div>
        <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="center center" fxFill>
            <div fxLayout fxLayoutAlign="start center">
                <div fxFlex="100">
                    <div fxLayout fxLayoutAlign="start center">
                        <p class="courseHeading">
                            {{aboutData[0].title}}
                        </p>
                    </div>
                    <div fxLayout fxLayoutAlign="start center">
                        <h4 class="courseDis">
                            {{aboutData[0].description}}

                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br><br>
    <app-download-app [from]="'courses'" [source]="utm_source" [medium]="utm_medium" [campaign]="campaignType"
        [publisher]="publisherId">
    </app-download-app>
</div>