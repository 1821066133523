import { Component, OnInit } from '@angular/core';
import { GetDataServiceService } from '../../../service/get-data-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { base_url } from '../../../service/config';
import { AuthGuardGuard } from '../auth-guard.guard';

@Component({
  selector: 'app-test-camp-thankyou',
  templateUrl: './test-camp-thankyou.component.html',
  styleUrls: ['./test-camp-thankyou.component.scss']
})
export class TestCampThankyouComponent implements OnInit {

  public name: string;
  mobileDevice: boolean = false;
  public thankResponse: any;

  dir = base_url + 'uploads/testCampaign/download/';
  id: any = ''
  constructor(public ps: GetDataServiceService, public route: ActivatedRoute, public activateGuard: AuthGuardGuard) {
    this.name = this.route.snapshot.queryParamMap.get('name');
    this.id = this.route.snapshot.queryParamMap.get('id')
  }

  ngOnInit(): void {
    this.getWidth()
    this.thanks()
    this.activateGuard.setCanActivate(false)
  }
  
  thanks() {
    let url = base_url + 'TestCampaigns?filter={"where":{"id":"'+this.id+'"},"fields":["thankTitle","thanksDescription", "logo"]}';
    this.ps.getData(url).subscribe((response: any) => {
      console.log('Response is', response);
      this.thankResponse = response.data[0];
    })
  }

  getWidth(event?) {
    this.mobileDevice = event ? event.target.innerWidth <= 959 ? true : false : window.innerWidth <= 959 ? true : false
  }

}