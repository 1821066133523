import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { TestCampTestScreenComponent } from './test-camp-test-screen/test-camp-test-screen.component';

@Injectable({
  providedIn: 'root'
})
export class DauthGuardGuard implements CanDeactivate<TestCampTestScreenComponent> {
  canDeactivate(component: TestCampTestScreenComponent) {
    let can = component.canDeactivate();
    console.log('DeactivateGuard#canDeactivate called, can: ', can);
    if (!can) {
        if((window.navigator.userAgent).split("/")[0] === 'Mozilla') {
            var r = confirm('Are you sure to exit? Your test will automatically get submitted.')
            if(r) {
                sessionStorage.setItem('mozillaRefresh', 'yes');
                sessionStorage.setItem('canActivate', 'true');
                can = true;
                component.submit();
                // location.reload();
            }
        }
        else {
            return component.promptDialog(); 
        }
        // console.log((window.navigator.userAgent))
        // alert('Deactivation blocked');
        return false;
    }
    return true;
}
  
}
