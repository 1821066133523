import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { base_url } from '../../../service/config';
import { GetDataServiceService } from '../../../service/get-data-service.service';


@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {

  msg: any;
  check: any;
  dir = base_url + 'uploads/testCampaign/download/';
  logo = sessionStorage.getItem('testLogo')
  id:any = ''
  constructor(public route: ActivatedRoute, public router: Router, public ps: GetDataServiceService) {
    this.check = this.route.snapshot.queryParamMap.get('success');
    this.id = this.route.snapshot.queryParamMap.get('id');
    if (!this.check) {
      this.router.navigate(['test-campaign'], { queryParams: { id: sessionStorage.getItem('campId') } });
      sessionStorage.removeItem('resultmsg');
    }
    else
    {
      this.msg = sessionStorage.getItem('resultmsg');
        sessionStorage.removeItem('resultmsg');
    }

  }

  ngOnInit(): void {
    this.getResultData()
    this.getWidth()
    this.deleteStorages()
  }

  deleteStorages(){
    sessionStorage.removeItem('rollAnswer');
    sessionStorage.removeItem('counter');
    sessionStorage.removeItem('testDetails');
    sessionStorage.removeItem('totalQues');
    sessionStorage.removeItem('refreshed');
    sessionStorage.removeItem('campId');
  //  sessionStorage.removeItem('testLogo');
    sessionStorage.removeItem('canActivate');
    sessionStorage.removeItem('Succ');
  }


  mobileDevice: boolean = false
  getWidth(event?) {
    this.mobileDevice = event ? event.target.innerWidth <= 959 ? true : false : window.innerWidth <= 959 ? true : false
  }

  resultResponse: any;
  getResultData() {
    let url = base_url + 'Tests?filter={"where":{"campaignId":"'+this.id+'"}}';
    this.ps.getData(url).subscribe((response: any) => {
      console.log('Response is', response);
      this.resultResponse = response.data[0];
    })  }

}
