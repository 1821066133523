<div fxLayout fxLayoutAlign="space-between center" fxLayoutGap="20px" (window:resize)="getWidth($event)">
	<div fxFlex="100" class="divImage"
		style="background-image: url(assets/Websiteimg/2\ 4.png); padding: 20px; background-repeat: no-repeat;background-size: cover">
		<div fxLayout fxLayoutAlign="center center" fxLayout.sm="column" fxLayout.xs="column">
			<div style="padding: 30px 0;" fxFlex="40" fxLayoutAlign="center center" fxFlex.sm="100">
				<img src="assets/image/mobile.png" alt="home" [height]="mobileDevice ? '300' : '400'">
			</div>
			<div fxFlex="50" fxFlex.sm="100">
				<div fxLayout fxLayoutAlign="center center">
					<div fxFlex="100">
						<div fxLayout fxLayoutAlign="center center">
							<div fxFlex="100">
								<h3 class="downlodsHeading "> START YOUR JOURNEY WITH US, JUST DOWNLOAD APP </h3>
							</div>
						</div>
						<div fxLayout fxLayoutAlign="center center">
							<p class="DownlodsDiscription"> Share your past,present and future of our glorious
								journey. We still have long ways to go and so do you
								,Be the first one to get our latest study materil, test series,offers and event
								information.Stay connected 24*7
								on the go, no matter where you are. Download APT STUDY APP NOW!!
							</p>
						</div>
						<br>
						<div style="padding-bottom: 30px;" fxLayout fxLayoutAlign="center center">
							<button mat-raised-button class="whiteText" color="primary" (click)="DownloadsApp()">
								DOWNLOAD APT APP </button>
						</div>
						<br>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>