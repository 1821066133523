import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router, ActivationEnd } from "@angular/router";
import { GetDataServiceService } from '../service/get-data-service.service';
import { base_url, apt_url } from "../service/config";
import { MatSnackBar } from "@angular/material/snack-bar";
import { interval } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
const NUM_REGEX = /^[1-9][0-9]{5,19}$/;
const NAME_REGEX = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
const EMAIL_REGEX = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

declare var $: any;
interface Rate {
	id: number;
	rating: number;
	message: string;
}

import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { trigger, transition, style, animate, stagger, query, useAnimation } from '@angular/animations';
import {
	slideInDown,
	slideInUp,
	slideInLeft,
	slideInRight,
	slideOutDown,
	slideOutUp,
	slideOutLeft,
	slideOutRight,
} from 'ng-animate';
import { flyInOut, expand } from '../animation-details/animation-details.component';
import { response } from 'express';

@Component({
	selector: 'app-feedback',
	templateUrl: './feedback.component.html',
	styleUrls: ['./feedback.component.scss'],
	animations: [
		flyInOut(),
		expand(),
		trigger('slideInDown', [transition('* => *', useAnimation(slideInDown))]),
		trigger('slideInUp', [transition('* => *', useAnimation(slideInUp))]),
		trigger('slideInLeft', [transition('* => *', useAnimation(slideInLeft))]),
		trigger('slideInRight', [transition('* => *', useAnimation(slideInRight))])
	],
})
export class FeedbackComponent implements OnInit {

	public rateUsForm: FormGroup;

	public selStars: number = 0;
	public maxStars: any;

	public ratingClicked: number = 0;
	public message: string;
	public items: Rate[] = [
		{ 'id': 0, 'rating': 0, 'message': 'Rate Us' },
		{ 'id': 1, 'rating': 1, 'message': 'Very Disappointing' },
		{ 'id': 2, 'rating': 2, 'message': 'Disappointing' },
		{ 'id': 3, 'rating': 3, 'message': 'Quite Good' },
		{ 'id': 4, 'rating': 4, 'message': 'Impressive' },
		{ 'id': 5, 'rating': 5, 'message': 'Excellent' }
	];

	ratingComponentClick(clickObj: any): void {
		this.userRatings = clickObj
		this.rated = true
		const item = this.items.find(((i: any) => i.rating === clickObj.rating));
		console.log(clickObj)
		if (item) {
			item.rating = clickObj.rating;
			this.ratingClicked = clickObj.rating;
			this.message = item.message;
		}
	}


	userRatings: any = {}

	imageUrl: any = ''
	productName: any = ''
	productDesc: any = ``
	public rating: number;
	public ratings: number;

	classId: any = ''
	userId: any = ''
	constructor(public fb: FormBuilder, public ps: GetDataServiceService, public router: Router,
		public snakBar: MatSnackBar, public route: ActivatedRoute, public dialog: MatDialog, public sanitizer: DomSanitizer) {
		this.classId = this.route.snapshot.queryParamMap.get('classId');
		this.userId = this.route.snapshot.queryParamMap.get('userId');
		if (this.classId && this.userId) {
			this.getClassData()
		}
		else if(this.classId && !this.userId)
		{
			this.router.navigate(['/course/course-details'],{queryParams:{id:this.classId}})
		}
		else{
			this.router.navigate(["/"]);
		}

	}

	ngOnInit(): void {
		this.inilizeForm();
	}

	getClassData() {
		let url = apt_url + 'Classes/?filter={"where": {"id": "' + this.classId + '"}, "include": {"relation":"onlinedata","scope":{"fields":["id","productContent"]}}}'
		this.ps.getData(url).subscribe((data: any) => {
			console.log(data)
			if (data.length) {
				this.imageUrl = apt_url + 'uploads/Class/download/' + data[0].image
				this.productName = data[0].name,
					this.productDesc = data[0].onlinedata[0].productContent.description
			}
			else{
			console.log('Wrong Class');
			this.router.navigate(["/"]);
			}
		});
	}


	inilizeForm() {
		this.rateUsForm = this.fb.group({
	//		name: new FormControl((sessionStorage.getItem('savedName')?sessionStorage.getItem('savedName'):''), [Validators.required, Validators.pattern(NAME_REGEX)]),
	//		mobile: new FormControl((sessionStorage.getItem('savedContact')?sessionStorage.getItem('savedContact'):''), [Validators.required, Validators.pattern(NUM_REGEX), Validators.minLength(6), Validators.maxLength(15)]),
			description: new FormControl('', [Validators.maxLength(250)])
		});
	}


	rated: any = true
	submitForm() {
		console.log("form input value is", this.rateUsForm.value);
		if (this.rateUsForm.valid && this.userRatings.rating) {
			console.log('Entered Loop')
			this.rated = true
			let postDatas = {
	//			name: this.rateUsForm.value.name,
	//			mobile: this.rateUsForm.value.mobile,
				description: this.rateUsForm.value.description.toString().trim(),
				rating: this.userRatings.rating,
				createdAt: new Date(),
				updatedAt: new Date(),
				userId: this.userId,
				classId: this.classId
			}
			let url: any = apt_url + 'Ratings';
			console.log(postDatas);
			this.ps.PostData(postDatas,url).subscribe((response:any) => {
				console.log("review is here ", response);
				this.snakBar.open('Thanks for sharing your review with us', 'OK', {
					duration: 3000
				})
				this.rateUsForm.reset();
				Object.keys(this.rateUsForm.controls).forEach(key => {
					this.rateUsForm.get(key).setErrors(null);
				})
			}, error => {
				if(error.error && error.error.error && error.error.error.message.includes('duplicate')) {
					this.snakBar.open('Already submiited your ratings before', 'OK', { duration: 2000 })
				}
				console.log("reviews geting error ", error);
			});
		} else {
			if(!this.userRatings.rating) {
				this.rated = false
				this.skippedRating()
				this.snakBar.open('Please rate the product', 'OK', {
					duration: 3000
				})
			}
			else {
				this.snakBar.open('Please enter Required Fields', 'OK', {
					duration: 3000
				})
			}
		}
	}


	skippedRating() {
		let el = document.getElementById('rate-star')
		el.classList.add('rate-imp')
		setTimeout(() => {
			el.classList.remove('rate-imp')
		}, 500);
	}

}

