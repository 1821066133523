<div class="full-width" *ngIf="dataLoaded">
    <div style="padding: 20px 0;" fxLayout fxLayoutAlign="center center">
        <div fxFlex="80" fxLayout>
            <img [src]="dir + logo" height="75">
        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <div class="full-width" fxLayout fxLayoutAlign="center center" (window:resize)="getWidth($event)">
        <div fxFlex="80" fxFlex.xs="96" fxFlex.sm="90" fxLayout="column">
            <div>
                <h1 [style.color]="data.title[0].color" [innerHTML]="data.title[0].text"
                    [ngClass]="mobileDevice ? 'mat-display-1 inner-text' : 'mat-display-2 inner-text'"></h1>
                <p class="mat-body"><b>{{data.instructions.subtitle}}</b></p>
                <div fxLayout="column" fxLayoutGap="20px" fxLayoutColumn="center start">
                    <div fxLayout="column" fxLayoutGap="10px"
                        *ngFor="let point of data?.instructions.points; let i = index">
                        <p class="mat-body-strong">{{i+1}}. {{point.point}}</p>
                        <img class="mat-elevation-z3" style="border-radius: 5px;" *ngIf="point.image" width="280"
                            [src]="dir + point.image" alt="point.point">
                    </div>
                </div>
            </div>
            <br>
            <br>
            <br>
            <br>
            <br>
            <div style="width: 100%;" fxLayout fxLayoutAlign="center center">
                <button fxFlex="250px" fxFlex.xs="80" fxFlex.sm="70" style="color: white;" type="submit" mat-flat-button
                    color="primary" (click)="submit()">
                    Start Test
                </button>
            </div>
        </div>
    </div>
</div>


<div class="no-data">

</div> 
<div class="full" fxLayout fxLayoutAlign="center center" *ngIf="!dataLoaded">
    <mat-spinner diameter="40"></mat-spinner>
</div>