<div class="full-width">
	<mat-toolbar>
		<img src="https://v2.aptstudy.com/wp-content/uploads/2018/11/logo.png" height="50">
	</mat-toolbar>

	<div fxLayout fxLayoutAlign="center center" (window:resize)="getWidth($event)">
		<img *ngIf="invalidcampaign" [src]="dir + normalCampaignData?.logo" height="100" alt="not_error">
	</div>
	<p>&nbsp;</p>

	<div #element class="full-width" fxLayout fxLayoutAlign="center center">
		<div fxFlex="85" fxFlex.xs="96" fxFlex.sm="90" fxLayout.xs="column" fxLayout.sm="column" fxLayout
			fxLayoutAlign.xs="start center" fxLayoutAlign.sm="start center" fxLayoutAlign="center start"
			fxLayoutGap="20px">
			<div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100">
				<div>
					<h1 [style.color]="normalCampaignData?.contentTitle[0]?.color" class="mat-display-1">
						{{normalCampaignData?.contentTitle[0]?.text}}</h1>
				</div>
				<div fxLayout="column" fxLayoutColumn="center start">
					<p *ngFor="let point of normalCampaignData?.contents" class="mat-body">{{point}}</p>
				</div>
			</div>

			<div *ngIf="!formLoaded && invalidcampaign" fxLayout fxLayoutAlign="center center" fxFlex="50"
				fxFlex.xs="100" fxFlex.sm="100">
				<p class="mat-body-strong">Loading...</p>
			</div>

			<form [style.width]="mobileDevice ? '100%' : 'auto'" fxLayout="column" fxLayoutAlign="start center"
				*ngIf="formLoaded" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign.xs="start center"
				fxLayoutAlign.sm="start center" fxLayoutGap="10px" [formGroup]="campForm">
				<h1 style="color: #ff0000;" class="mat-display-1">Register Now</h1>

				<mat-form-field [style.width]="mobileDevice ? '90%' : '80%'"
					*ngFor="let form of formData; let i = index">
					<!-- LABEL -->
					<mat-label>{{form.label}}</mat-label>

					<!-- FOR INPUT -->

					<input *ngIf="form.type === 'Textinput'" matInput formControlName="{{form.key}}" autocomplete="off"
						[required]="form.required" [numbersOnly]="form.subtype === 'number'"
						[minlength]="form.key === 'mobile' ? minLength : ''"
						[maxlength]="form.key === 'mobile' ? maxLength : ''" (keypress)=" form.key === 'name' ? omit_special_char($event) : ''">

					<!-- FOR DROPDOWN -->

					<mat-select *ngIf="form.type === 'Dropdown'" required formControlName="{{form.key}}"
						[required]="form.required" [multiple]="form.multiple"
						(selectionChange)="countryCheck(form.key)">
						<ng-container *ngIf="isString(form.options[0])">
							<mat-option *ngFor="let option of form.options" [value]="option">{{option}}</mat-option>
						</ng-container>
						<ng-container *ngIf="isObject(form.options[0])">
							<mat-option *ngFor="let option of form.options" [value]="option.opt">{{option.opt}}
							</mat-option>
						</ng-container>
					</mat-select>

					<!-- ERROR -->
					<mat-error *ngIf="form.required" style="color:red;">{{form.key!=="email"?(form.label):('Email
						Pattern')}} is required</mat-error>
				</mat-form-field>

				<div style="width: 100%;" fxLayout fxLayoutAlign="center center">
					<button [disabled]="campForm.invalid" fxFlex="60" fxFlex.xs="80" fxFlex.sm="70"
						style="color: white;" type="button" mat-flat-button color="primary" (click)="submit()">
						SUBMIT
					</button>
				</div>
			</form>
		</div>
	</div>


	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<div class="full-width" fxLayout fxLayoutAlign="center center"
		*ngIf="normalCampaignData?.bestResultTitle?.length>0 && normalCampaignData?.bestImages?.length>0">
		<div fxFlex="80" fxFlex.xs="96" fxFlex.sm="90" fxLayout="column" fxLayoutAlign="center center">
			<div class="inner-text">
				<h1 [style.color]="normalCampaignData?.bestResultTitle[0]?.color" class="mat-display-1">
					{{normalCampaignData?.bestResultTitle[0]?.text}}</h1>
			</div>
			<p>&nbsp;</p>
			<div fxLayout fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap.xs="20px" fxLayoutGap="80px">
				<img *ngFor="let img of normalCampaignData?.bestImages" height="200" [src]="dir + img" alt="not_avail">
			</div>
		</div>
	</div>
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<div class="full-width" fxLayout fxLayoutAlign="center center"
		*ngIf="normalCampaignData?.featuresTitle?.length>0 && normalCampaignData?.features?.length>0">
		<div fxFlex="80" fxFlex.xs="96" fxFlex.sm="90" fxLayout="column" fxLayoutAlign="center center">
			<div class="inner-text">
				<h1 [style.color]="normalCampaignData?.featuresTitle[0]?.color" class="mat-display-1">
					{{normalCampaignData?.featuresTitle[0]?.text}}</h1>
			</div>
			<p>&nbsp;</p>
			<mat-grid-list class="full-width" [cols]="mobileDevice ? '1' : '3'"
				[rowHeight]="mobileDevice ? '100px' : '120px'" gutterSize="20">
				<mat-grid-tile *ngFor="let feature of normalCampaignData?.features">
					<div class="full-width" fxLayout>
						<div style="padding: 5px 0;" fxFlex="30" fxLayout fxLayoutAlign="center start">
							<img height="50" [src]="dir + feature.image" alt="error">
						</div>
						<div fxFlex="70" fxLayout="column" fxLayoutAlign="start start">
							<p class="mat-body-strong primary" [innerHtml]="feature.title"></p>
							<p class="mat-body" [innerHtml]="feature.description"></p>
						</div>
					</div>
				</mat-grid-tile>
			</mat-grid-list>
		</div>
	</div>
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<div style="width: 100%;" fxLayout fxLayoutAlign="center center">
		<button fxFlex="250px" class="reg-btn" mat-flat-button color="warn"
			(click)="element.scrollIntoView({ behavior: 'smooth', block: 'center' })">
			REGISTER NOW
		</button>
	</div>
	<div class="full-width" *ngIf="normalCampaignData?.resultImageTitle?.length">
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<div class="full-width" fxLayout fxLayoutAlign="center center"
			*ngIf="normalCampaignData?.resultImageTitle?.length>0 && normalCampaignData?.resultImages?.length>0">
			<div fxFlex="80" fxFlex.xs="96" fxFlex.sm="90" fxLayout="column" fxLayoutAlign="center center">
				<div class="inner-text">
					<h1 [style.color]="normalCampaignData?.resultImageTitle[0]?.color" class="mat-display-1">
						{{normalCampaignData?.resultImageTitle[0]?.text}}</h1>
				</div>
				<p>&nbsp;</p>
				<mat-grid-list class="full-width" [cols]="mobileDevice ? '2' : '4'"
					[rowHeight]="mobileDevice ? '150px' : '200px'" gutterSize="20">
					<mat-grid-tile *ngFor="let result of normalCampaignData?.resultImages">
						<img [height]="mobileDevice ? '150' : '200'" [src]="dir + result" alt="404_error">
					</mat-grid-tile>
				</mat-grid-list>
			</div>
		</div>
	</div>

	<div class="full-width" *ngIf="normalCampaignData?.facultyTitle?.length">
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<div class="full-width" fxLayout fxLayoutAlign="center center"
			*ngIf="normalCampaignData?.facultyTitle?.length>0 && normalCampaignData?.facultyImages?.length>0">
			<div fxFlex="80" fxFlex.xs="96" fxFlex.sm="90" fxLayout="column" fxLayoutAlign="center center">
				<div class="inner-text">
					<h1 [style.color]="normalCampaignData?.facultyTitle[0]?.color" class="mat-display-1">
						{{normalCampaignData?.facultyTitle[0]?.text}}</h1>
				</div>
				<p>&nbsp;</p>
				<mat-grid-list class="full-width" [cols]="mobileDevice ? '2' : '4'"
					[rowHeight]="mobileDevice ? '150px' : '200px'" gutterSize="20">
					<mat-grid-tile *ngFor="let image of normalCampaignData?.facultyImages">
						<img [height]="mobileDevice ? '150' : '200'" [src]="dir + image" alt="404_error">
					</mat-grid-tile>
				</mat-grid-list>
				<p>&nbsp;</p>
				<h2 class="mat-h4 inner-text">{{normalCampaignData?.facultyAbout}}</h2>
			</div>
		</div>
	</div>

	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<div class="full-width" fxLayout fxLayoutAlign="center center"
		*ngIf="normalCampaignData?.supportTitle?.length>0 && normalCampaignData?.supports?.length>0">
		<div fxFlex="80" fxFlex.xs="96" fxFlex.sm="90" fxLayout="column" fxLayoutAlign="center center">
			<div class="inner-text">
				<h1 [style.color]="normalCampaignData?.supportTitle[0]?.color" class="mat-display-1">
					{{normalCampaignData?.supportTitle[0]?.text}}</h1>
			</div>
			<p>&nbsp;</p>
			<mat-grid-list class="full-width" [cols]="mobileDevice ? '1' : '3'"
				[rowHeight]="mobileDevice ? '100px' : '120px'" gutterSize="20">
				<mat-grid-tile *ngFor="let support of normalCampaignData?.supports">
					<div class="full-width" fxLayout>
						<div style="padding: 5px 0;" fxFlex="30" fxLayout fxLayoutAlign="center start">
							<img height="50" [src]="dir + support.image" alt="error">
						</div>
						<div fxFlex="70" fxLayout="column" fxLayoutAlign="start start">
							<p class="mat-body-strong" [innerHtml]="support.title"></p>
							<p class="mat-body" [innerHtml]="support.description"></p>
						</div>
					</div>
				</mat-grid-tile>
			</mat-grid-list>
		</div>
	</div>

	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<div style="width: 100%;" fxLayout fxLayoutAlign="center center">
		<button fxFlex="250px" class="reg-btn" mat-flat-button color="warn"
			(click)="element.scrollIntoView({ behavior: 'smooth', block: 'center' })">
			REGISTER NOW
		</button>
	</div>

	<div class="full-width" *ngIf="normalCampaignData?.USPtitle?.length">
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<div class="full-width" fxLayout fxLayoutAlign="center center">
			<div fxFlex="80" fxFlex.xs="96" fxFlex.sm="90" fxLayout fxLayout.sm="column" fxLayout.xs="column"
				fxLayoutAlign="center start">
				<div fxFlex="50" fxLayout="column" [class]="mobileDevice ? 'full-width' : ''">
					<div class="inner-text">
						<h1 [style.color]="normalCampaignData?.USPtitle[0]?.color" class="mat-display-1">
							{{normalCampaignData?.USPtitle[0]?.text}}</h1>
					</div>
					<p>&nbsp;</p>
					<div *ngIf="mobileDevice" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column"
						fxLayoutAlign="start start">
						<img src="assets/image/usp.png" width="80%">
					</div>
					<p *ngIf="mobileDevice">&nbsp;</p>
					<div fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.sm="center center"
						fxLayoutAlign.xs="center center">
						<p class="mat-body" *ngFor="let usp of normalCampaignData?.USP">{{usp}}</p>
					</div>
				</div>
				<div *ngIf="!mobileDevice" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column"
					fxLayoutAlign="start start">
					<img src="assets/image/usp.png" width="80%">
				</div>
			</div>
		</div>
	</div>

	<div class="full-width" *ngIf="normalCampaignData?.speakTitle?.length">
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<div class="full-width" fxLayout fxLayoutAlign="center center"
			*ngIf="normalCampaignData?.speakTitle?.length>0 && normalCampaignData?.topperSpeak?.length>0">
			<div fxFlex="80" fxFlex.xs="96" fxFlex.sm="90" fxLayout="column" fxLayoutAlign="center center">
				<div class="inner-text">
					<h1 [style.color]="normalCampaignData?.speakTitle[0]?.color" class="mat-display-1">
						{{normalCampaignData?.speakTitle[0]?.text}}</h1>
				</div>
				<p>&nbsp;</p>
				<div fxLayout.xs="column" fxLayout.sm="column" fxLayout fxLayoutAlign="center center" fxLayoutGap="80px">
					<div *ngFor="let img of normalCampaignData?.topperSpeak" fxLayout="column" fxLayoutAlign="center center"
						fxLayoutGap="15px">
						<img height="200" [src]="dir + img.image" alt="not_avail">
						<p class="mat-body">{{img.testimonial}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>

	<p>&nbsp;</p>
	<div class="full-width" fxLayout fxLayoutAlign="center center" *ngIf="normalCampaignData?.message?.length>0">
		<!-- <div class="inner-text" [innerHtml]="normalCampaignData?.message"></div> -->
		<div class="inner-text">
			<h1 [style.color]="normalCampaignData?.message[0]?.color" class="mat-display-1">
				{{normalCampaignData?.message[0]?.text}}</h1>
		</div>
	</div>

	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<div style="width: 100%;" fxLayout fxLayoutAlign="center center">
		<button fxFlex="250px" class="reg-btn" mat-flat-button color="warn"
			(click)="element.scrollIntoView({ behavior: 'smooth', block: 'center' })">
			REGISTER NOW
		</button>
	</div>
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<p>&nbsp;</p>


	<div style="height: 50px;" fxLayout fxLayoutAlign="center center" class="footer">
		<span style="color: #bcbcbc">&#9400; &nbsp;</span>
		<p class="footer-text"> Copyright APT. Handcrafted by <a href="https://eulogik.com" target="_blank">Eulogik</a>,
			India &nbsp;</p><span style="font-size: 16px; height: 16px; color: #fff;">&#10084;</span>
	</div>
</div>