import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ActivationEnd } from "@angular/router";
import { GetDataServiceService } from '../../../service/get-data-service.service';
import { base_url, apt_url } from "../../../service/config";
import { MatSnackBar } from "@angular/material/snack-bar";
import { interval } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadsAppComponent } from '../../../dialog/downloads-app/downloads-app.component';
import { FormControl, FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { Observable, Subscription, timer } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { flyInOut, expand } from '../../../animation-details/animation-details.component';
import { MatOption } from '@angular/material/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
const NUM_REGEX = /^[0-9]\d{9}$/g;
const NAME_REGEX = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
const EMAIL_REGEX = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

@Component({
  selector: 'app-buy-course',
  templateUrl: './buy-course.component.html',
  styleUrls: ['./buy-course.component.scss'],
  animations: [
    flyInOut(),
    expand(),
  ]
})
export class BuyCourseComponent implements OnInit {

  @ViewChild('allSelected') private allSelected: MatOption;
  //  @ViewChild('subSelected') private subSelected: MatOption;
  @ViewChild('individual') private indiv: MatSelect;
  public showloader: boolean = false;
  private subscrip: Subscription;
  private timer: Observable<any>;

  public loading1: boolean = true;
  public loading2: boolean = true;
  public loading3: boolean = true;
  public loading4: boolean = true;
  public loading5: boolean = true;

  public purchaseForm: FormGroup;
  public classId: string = '';
  public ClassName: string = '';
  public userId: string = '';
  public allSubject: any = [];
  public CoursePrice: number = 0;
  public discountPrice: number = 0;
  public Price: number = 0;
  public requestFrom: string;
  public SeniorBullet: any = ["Lorem ipsum, or lipsum as it is sometimes known.", " graphic or web designs.", "The purpose of lorem ipsum is to create.", "focus is meant to be on design, not content.", "The passage experienced"];
  public imageUrl: string = apt_url + 'uploads/Class/download/'
  public timerData: any = [];
  public ratingCount: number = 0;
  // urm source related variables
  public utm_source: string = '';
  public utm_medium: string = '';
  public campaignType: string = '';
  public publisherId: string = '';

  public angular: any;
  public isAllSelected: boolean = true;
  public bool: boolean = false;
  // public tosslePerOne: any;
  // public toggleAllSelection: any;
  public glob: any;
  public tosslePerOne(v, c) { };
  public toggleAllSelection() { };
  public serverName: any;
  public discPrice: any;
  public img: any;
  public credits: any;
  public bool1: boolean;
  public subjectDisplay: any;
  public allSubjectunderline: boolean;
  public discountedPrice: any;
  public originalPrice: any;
  public listAllSubjects: any;
  public paymentsubjectId: any = [];
  public validSubjectLength: number = 0;
  public rom: string = "[";
  public allSujectsSelected: boolean;
  public validSubjectName: string[] = [];
  public couponId: any;
  public couponAmount: number = 0;
  public couponCheck: boolean;
  public sendCouponValue:number;

  public options: any = [
    { value: 'Option1', selected: true },
    { value: 'Option2', selected: false }
  ];

  userTypeFilters = [
    {
      key: 1, value: 'Value 1',
    },
    {
      key: 2, value: 'Value 2',
    },
    {
      key: 3, value: 'Value 3',
    },
    {
      key: 4, value: 'Value 4',
    }
  ];


  constructor(public fb: FormBuilder, public getTopicService: GetDataServiceService, public router: Router,
    public snakBar: MatSnackBar, public route: ActivatedRoute, public dialog: MatDialog) {
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.classId = this.route.snapshot.queryParamMap.get('classId');
    //     this.getAllSubject();
    console.log("subject name & id is here ", this.ClassName, 'IDDDD', this.classId);
    console.log('UserId', this.userId);
    this.utm_source = this.route.snapshot.queryParamMap.get('utm_source');
    if (this.utm_source !== null) {
      this.utm_medium = this.route.snapshot.queryParamMap.get('utm_medium');
      this.campaignType = this.route.snapshot.queryParamMap.get('campaigntype');
      this.publisherId = this.route.snapshot.queryParamMap.get('publisherid');
    } else {

    }
    this.initializeForm()
    this.getAllSubject()

  }

  ngOnInit(): void {

    this.initializeForm()
    this.getAllSubject()


    this.getClassData();
    this.GetProductData();

    this.getClassPrice();
    //   this.getDiscountedPrice();
    this.showRating(this.ratingCount);
    this.setTimer();
    // this.coupon(this.serverName);
    // this.errormessage(this.coupcode);

  }

  initializeForm() {
    this.purchaseForm = this.fb.group({
      course: new FormControl('', [Validators.required]),
      coupons: new FormControl('')
    });

    console.log('PurchaseForm', this.purchaseForm)
  }

  toggleAll(toggleStatus) {
    this.isAllSelected = toggleStatus;
    console.log("hiii" + toggleStatus)
    for (let i = 0; i < this.options.length; i++) {
      this.options[i].selected = toggleStatus
    }
  }

  optionToggled(toggleStatus) {

    console.log(toggleStatus)
  }

  public ngOnDestroy() {
    if (this.subscrip && this.subscrip instanceof Subscription) {
      this.subscrip.unsubscribe();
    }
  }

  public setTimer() {

    // set showloader to true to show loading div on view
    this.showloader = true;

    this.timer = timer(3000); // 5000 millisecond means 5 seconds
    this.subscrip = this.timer.subscribe(() => {
      // set showloader to false to hide loading div from view after 5 seconds
      this.showloader = false;
    });
  }
  someMethod(event) {
    console.log("event value is", event);

    // event[0].
  }
  // devigate payments screen 
  PAYNOW() {
    let arr = this.purchaseForm.value.course;
    if (arr.length > 0) {
      console.log("do nothigs", this.purchaseForm.value);
      console.log("fdf")
      if (!this.couponCheck) {
        console.log('From', this.couponId);
        console.log('From', this.sendCouponValue);
        console.log('From', this.CoursePrice);
        this.router.navigate(['/downloads/paytm'], { queryParams: { classId: this.classId, userId: this.userId, price: this.CoursePrice, coupon: '0', transactionType: 'buyproduct', couponId: '', subjectids: this.rom, allSelected: this.allSujectsSelected } });
        console.log('PAYNOW() with ROM', this.rom);
      } else {
        console.log("send with coupans");
        console.log(this.couponId);
        console.log(this.sendCouponValue);
        console.log(this.CoursePrice);
        this.router.navigate(['/downloads/paytm'], { queryParams: { classId: this.classId, userId: this.userId, price: this.CoursePrice, coupon: this.sendCouponValue, transactionType: 'buyproduct', couponId: this.couponId, subjectids: this.rom, allSelected: this.allSujectsSelected } });
        //       this.router.navigate(['/downloads/paytm'], { queryParams: { classId: this.classId, userId: this.userId, price:80, coupon: this.couponAmount, transactionType: 'buyproduct', couponId: this.couponId, subjectids: this.rom, allSelected: this.allSujectsSelected } });
        console.log('PAYNOW() with ROM', this.rom);
      }
    }
    // this.router.navigate(['/course/payments-faild'], { queryParams: { id: "5fsf4sd54gdsg4ssdd",name: "8th Class" } });
    else {
      this.snakBar.open('You have not selected any subjects !', 'OK', {
        duration: 3000
      })
    }

  }

  showRating(rating) {
    // THE METHOD DISPLAYS RATING OF THE PARTICULAR COURSE
    var arr: any = [];
    for (let i = 5; i > 0; i--) {
      if (Number.isInteger(rating)) {
        if (i <= rating) {
          arr.push('star')
        }
        else {
          arr.push('star_outline')
        }
      }
      else {
        var diff = i - rating;
        if (diff >= 1)
          arr.push('star_outline')
        else if (diff < 1 && diff > 0)
          arr.push('star_half')
        else
          arr.push('star')

      }
      if (i === 1) {
        return arr.reverse(); //returns array
      }
    }
  }



  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    // console.log("before data ",this.timerData);
    this.timerData = array;
    // console.log("before data ",this.timerData);
  }
  // timer data start here 
  public source = interval(6000);
  subscription: Subscription;
  SettimeInterval() {
    this.subscription = this.source.subscribe(val => this.shuffle(this.timerData));
  }

  // i am here start working this
  DownloadsApp() {
    console.log("do nothings");
    const dialogRef = this.dialog.open(DownloadsAppComponent, {
      width: '500px',
      disableClose: true,
      data: { screen: "home", action: "download App", heading: "User Information" },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result !== undefined) {
        console.log("post data");
        let url = base_url + 'CampaignUsers';
        let postData: any = {};
        if (this.utm_source !== null) {
          postData = {
            name: result.name,
            mobile: result.mobile,
            password: 'apt#123',
            createdAt: new Date(),
            "utm_medium": this.utm_medium,
            "compaigntype": this.campaignType,
            "publisherid": this.publisherId,
            "utm_source": this.utm_source,
          }

        } else {
          postData = {
            name: result.name,
            mobile: result.mobile,
            password: 'apt#123',
            createdAt: new Date()
          }
        }

        this.getTopicService.PostData(postData, url).subscribe(response => {
          console.log("all class data is here ", response);
          // let temp: any = response;
          this.snakBar.open('Thanks for sharing your information with us', 'OK', {
            duration: 3000
          })
        }, error => {
          console.log("app downlods  geting error ", error);
        });
      }
    });
  }

  getClassData() {

    let url = apt_url + 'Classes?filter={"where": {"id": "' + this.classId + '"}}';
    //   let url= apt_url + 'Classes/getCategoryClasses?access_token=7zHS4FZt37rdMw8qy8qHJNsItWhMaUfEaHABGCcgrG34F99klffwCpf7hMUri8JG';
    this.getTopicService.getData(url).subscribe(response => {
      console.log("class data is **** ", response);
      let temp: any = response[0];
      this.ClassName = temp.name;

      this.img = temp.image;
      console.log(this.img)
      console.log(this.ClassName);
      this.ratingCount = 5//temp.ratingValue;
      console.log(this.ratingCount);
      this.loading1 = false;
    }, err => {
      console.log("get about course content error ", err);
    });

  }

  public features: any = [];
  public contentTitle: string = '';
  public contentDescription: string = '';
  public rawImage: string = '';
  public courseVideoURL: string = '';
  public courseVideoTitle: string = '';
  public courseVideoDesc: string = '';
  public TooperimageUrl: string = apt_url + 'Uploads/products/download/'
  public coup: any;
  public coupcode: any;
  public count: number = 0;
  public beforeCoupAmt: number;

  GetProductData() {
    let url = apt_url + 'OnlineClassdata?filter={"where": {"classId": "' + this.classId + '"}}&access_token=' + sessionStorage.getItem('aptAccessToken');
    //    let url= apt_url + 'Classes/getCategoryClasses?access_token=7zHS4FZt37rdMw8qy8qHJNsItWhMaUfEaHABGCcgrG34F99klffwCpf7hMUri8JG';
    this.getTopicService.getData(url).subscribe(response => {
      console.log("product data is **** ", response);
      let temp: any = response[0];
      console.log('Features', temp.features);
      if (temp.features !== undefined) {
        this.features = temp.features;
      }

      if (temp.ProductDetailContent !== undefined) {
        this.contentTitle = temp.ProductDetailContent.title;
        this.contentDescription = temp.ProductDetailContent.description;
        this.rawImage = apt_url + 'Uploads/products/download/' + temp.ProductDetailContent.image
      }

      if (temp.ProductDetailVideoContent !== undefined) {
        this.courseVideoURL = temp.ProductDetailVideoContent.url;
        this.courseVideoTitle = temp.ProductDetailVideoContent.title;
        this.courseVideoDesc = temp.ProductDetailVideoContent.description;
      }

      if (temp.toppers !== undefined) {
        this.timerData = temp.toppers;
        this.SettimeInterval();
      }
      this.loading2 = false;

    }, err => {
      console.log("get about course content error ", err);
    });
  }


  afterCoupon: boolean;
  clickbutton: boolean;
  couponValue: number;
  coupon(value) {

    let arr = this.purchaseForm.value.course;
    if (arr.length > 0) {
      this.serverName = value;
      console.log(this.serverName);
      this.afterCoupon = false;
      //      this.discountedPrice = this.discountedPrice + this.couponAmount;
      //      this.couponAmount = 0;
      this.couponCheck = false;
      this.couponMessage = '';
      this.couponId='';
      this.sendCouponValue=0;
      this.couponValue=0;
      // if (this.clickbutton === true) {
      //   document.getElementById("coupon1").innerHTML = "";
      //   document.getElementById("coupon2").innerHTML = "";
      //   document.getElementById("coupon3").innerHTML = "";
      //   this.clickbutton = false;
      // }
      // if (this.serverName.length >= 3)
      //   this.bool = true;
      // else
      //   this.bool = false;
    }

  }

  hasError: boolean;
  couponMessage: any = ''
  applyCoupon(value) {
 //   this.beforeCoupAmt = this.discountedPrice;
    this.coupcode = value
    let data = {
      coupon: this.coupcode,
      userId: this.userId
    };
    let url = apt_url + 'Coupons/check?access_token=' + sessionStorage.getItem('aptAccessToken');
    this.getTopicService.PostData(data, url).subscribe((response: any) => {
      console.log("hello", response);
      if (response.statusCode === 200) {

        this.couponMessage = 'Applied'

        this.couponId = response.data.id;
        this.couponAmount = response.data.amount;
        
        this.couponValue = (this.couponAmount / 100) * this.discountedPrice;
        this.sendCouponValue=this.couponValue;
 //       this.CoursePrice = this.discountedPrice - this.couponValue;
        this.discountedPrice = this.discountedPrice - this.couponValue;
        
        this.hasError = false;
        this.couponCheck = true;

      }
      else if(response.statusCode===402 && response.message==='coupon is already in used')
      {
        this.couponMessage = 'Coupon already used'
        this.hasError = true;
      }
      else if(response.statusCode===402 && response.message==='coupon is expired')
      {
        this.couponMessage = 'Coupon Expired'
        this.hasError = true;
      }
      else {
        this.couponMessage = 'Invalid Coupon'
        this.hasError = true;
      }
      this.coup = response.message;
      this.afterCoupon = true;
    }, err => {
      console.log(" error ", err);
    });
    // let url = apt_url + 'Coupons';

  }

  couponmessage() {
    this.clickbutton = true;
  }

  togglecoupon() {

    this.couponMessage = '';
    (<HTMLInputElement>document.getElementById("value")).value = "";
    this.afterCoupon = false;
    this.hasError = true;
    this.couponId = null;
    //    this.discountedPrice = this.discountedPrice + this.couponAmount;

//    this.CoursePrice = this.discountedPrice + this.couponValue;
    this.discountedPrice = this.discountedPrice + this.couponValue;
    this.couponAmount = 0;
    this.sendCouponValue=0;
    this.couponCheck = false;
  }


  getAllSubject() {
    //let url = apt_url + 'RemoteMethods/getCourses';
    //  let url = apt_url + 'Classes/getCategoryClasses?access_token=7zHS4FZt37rdMw8qy8qHJNsItWhMaUfEaHABGCcgrG34F99klffwCpf7hMUri8JG'
    setTimeout(() => {
      let url = apt_url + 'Subjects/getAllSubject';
      let validCount = 0;
      let postData: any = {
        classId: this.classId,
        userId: this.userId
      };
      // let postData: any = {
      //   classId: this.classId,
      // };
      this.getTopicService.PostData(postData, url).subscribe(response => {
        console.log("all subject id here data is **** ", response);
        let temp: any = response;
        this.allSubject = temp.data;
        //       this.allSubject=temp.data;

        console.log('Subject', this.allSubject);
        this.loading3 = false;
        this.glob = this.allSubject;
        //   this.indiv.options.forEach( (item : MatOption) => item.select());

        this.allSelected.select();

        this.allSujectsSelected = true;
        /*    this.allSubject.forEach(e=>{
              if(e.order==false)
              {
                this.validSubjectName.push(e.name);
              }
            }) */
        this.allSubject.forEach(e => {
          if (e.order == false) {
            this.credits += e.credits;
          }
        })
        this.indiv.options.forEach((item: MatOption) => {
          for (let i = 0; i < this.allSubject.length; i++) {
            if (item.value == this.allSubject[i].name && this.allSubject[i].order == false) {
              item.select();
            }
          }
        });
        //     this.getDiscountedPrice();
        this.getDiscount();
        this.allSubjectunderline = true;

        this.tosslePerOne = (all, subject) => {
          if (subject.order == false) {
            validCount = 0;
            for (let i = 0; i < this.allSubject.length; i++) {
              if (this.allSubject[i].order == false)
                validCount++;
            }
            this.allSujectsSelected = false;
            console.log('TosslePerOne', all);
            console.log('TosslePerOne Single Subject', this.purchaseForm.value);
            //    this.discountedPrice=this.credits;
            this.paymentsubjectId = [];
            //     this.CoursePrice=this.credits;
            this.getIndividualId();
            this.allSubjectunderline = false;
            console.log('Valid Subject Name', this.validSubjectName);
            if (this.allSelected.selected) {
              this.allSelected.deselect();
              this.paymentsubjectId = [];
              return false;

            }
            if (this.purchaseForm.value.course.length == validCount) {
              this.paymentsubjectId = [];
              this.allSelected.select();
              this.allSujectsSelected = true;
              //    this.discountedPrice=this.discPrice;
              //    this.CoursePrice=this.discountedPrice;
              this.getDiscount();
              this.allSubjectunderline = true;
              //   this.getDiscountedPrice();
              console.log('Equsals', this.purchaseForm.value.course);

            }
            if (this.purchaseForm.value.course.length === 0) {
              if (this.couponCheck) {
                this.couponMessage = '';
                (<HTMLInputElement>document.getElementById("value")).value = "";
                this.afterCoupon = false;
                this.hasError = true;
                this.couponAmount = 0;
                this.sendCouponValue=0;
                this.couponCheck = false;
                this.couponId = '';
              }
            }

          }
        }

        this.toggleAllSelection = () => {
          if (this.allSelected.selected) {
            this.allSujectsSelected = true;
            this.indiv.options.forEach((item: MatOption) => {
              for (let i = 0; i < this.allSubject.length; i++) {
                if (item.value == this.allSubject[i].name && this.allSubject[i].order == false) {
                  item.select();
                }
              }
            });
            this.paymentsubjectId = [];
            this.getDiscount();
            console.log('after fhsdgjh', this.allSubject)
            this.allSubjectunderline = true;

            //    this.allSubject.forEach(()=>this.subSelected.select())

            console.log('Checking of Purchase', this.purchaseForm.value);

            console.log('Purchase value', this.purchaseForm.value.course);
          } else {
            this.indiv.options.forEach((item: MatOption) => { item.deselect() });
            console.log("123444");
            this.paymentsubjectId = [];

            //     this.discountedPrice=this.credits;
            //     this.CoursePrice=this.credits;
            this.discountedPrice = 0;
            this.CoursePrice = 0;
            this.credits = 0;
            this.allSujectsSelected = false;
            //      this.getIndividualId();
            this.allSubjectunderline = false;
            //       this.purchaseForm.value.course.patchValue(['']);
            console.log('Uncheck of Purchase Value', this.purchaseForm.value);
            if (this.couponCheck) {
              this.couponMessage = '';
              (<HTMLInputElement>document.getElementById("value")).value = "";
              this.afterCoupon = false;
              this.hasError = true;
              this.couponAmount = 0;
              this.couponCheck = false;
              this.couponId = '';
              this.sendCouponValue=0;
            }
          }
        }

      }, err => {
        console.log("get about course content error ", err);
      });
    });
  }
  public allCourse: boolean = false;
  getDiscount() {
    let discCount = 0;
    for (let i = 0; i < this.allSubject.length; i++) {
      if (this.allSubject[i].order == false) {
        discCount += this.allSubject[i].discountPrice;
      }
    }

    this.getIndividualId();

    //  this.discountedPrice =(this.couponCheck)?(discCount-this.couponValue):discCount;
 /*   if (this.couponCheck) {
      this.couponValue = (this.couponAmount / 100) * discCount;
      this.CoursePrice = discCount - this.couponValue;
      this.discountedPrice = discCount - this.couponValue;
    }
    else {
      this.discountedPrice = discCount;
      this.CoursePrice = this.discountedPrice;
    } */
    if (this.couponCheck) {
      this.couponValue = (this.couponAmount / 100) * discCount;
      this.discountedPrice = discCount;
      this.CoursePrice = this.discountedPrice;
      this.sendCouponValue=this.couponValue;
      this.discountedPrice=discCount-this.couponValue;
    }
    else {
      this.discountedPrice = discCount;
      this.CoursePrice = this.discountedPrice;
    } 
 //   this.discountedPrice = discCount;
    
 //     this.CoursePrice = this.discountedPrice;
    //   this.CoursePrice =this.discountedPrice;
    console.log('Purchase value from getDiscount()', this.purchaseForm.value.course);
    console.log('Payment Subject Id', this.paymentsubjectId);

  }

  getIndividualId() {
    let products = this.purchaseForm.value.course;
    let convert: string = ''; let sum = 0; this.credits = 0;
    this.rom = "[";
    for (let i = 0; i < products.length; i++) {
      for (let j = 0; j < this.allSubject.length; j++) {
        if (products[i] == this.allSubject[j].name) {
          sum = sum + this.allSubject[j].credits;
          convert = this.allSubject[j]._id.toString();
          console.log('convert value', convert);
          this.paymentsubjectId.push(convert);
        }
      }
    }

    this.credits = sum;
    //  this.discountedPrice=(this.couponCheck)?(this.credits-this.couponValue):this.credits;
    //  this.discountedPrice = this.credits;

 /*   if (this.couponCheck) {
      this.couponValue = (this.couponAmount / 100) * this.credits;
      this.CoursePrice = this.credits - this.couponValue;
      this.discountedPrice = this.credits - this.couponValue;
    }
    else {
      this.discountedPrice = this.credits;
      this.CoursePrice = this.discountedPrice;
    } */
    if (this.couponCheck) {
      this.couponValue = (this.couponAmount / 100) * this.credits;
      this.discountedPrice = this.credits;
      this.CoursePrice = this.discountedPrice;
      this.sendCouponValue=this.couponValue;
      this.discountedPrice=this.credits-this.couponValue;
    }
    else {
      this.discountedPrice = this.credits;
      this.CoursePrice = this.discountedPrice;
    } 
//    this.discountedPrice = this.credits;
//      this.CoursePrice = this.discountedPrice;
    for (let i = 0; i < this.paymentsubjectId.length; i++) {
      if (this.paymentsubjectId.length == (i + 1)) {
        this.rom = this.rom + `"` + this.paymentsubjectId[i] + `"` + "]";
      }
      else {
        this.rom = this.rom + `"` + this.paymentsubjectId[i] + `"` + ",";
      }
    }
    console.log('Rom from individual', this.rom);
    console.log('Payment Subject Id from individual', this.paymentsubjectId);

  }

  /* get course()
    {
      return this.purchaseForm.get('course') as FormArray;
    } */

  // get all calss Data here 
  getClassPrice() {
    let url = apt_url + 'RemoteMethods/getCourses';
    let postData: any = {
      classId: this.classId,
      userId: this.userId
    };
    let sum = 0;
    let subjectLists = this.purchaseForm.value.course;
    this.getTopicService.PostData(postData, url).subscribe((response: any) => {
      console.log("all getClassPrice here data is **** ", response);
      let temp: any = response.data;
      this.subjectDisplay = temp.data;
      for (let i = 0; i < subjectLists.length; i++) {
        for (let j = 0; j < this.allSubject.length; j++) {
          //   if(temp.data[i]._id == this.classId){
          if (temp[i]._id == this.allSubject[j]._id) {

            //         this.CoursePrice = temp.data[i].productSum;
            console.log('Course Price', this.CoursePrice);
          } else {

          }
        }
      }
      this.loading4 = false;
      //  this.allSubject = temp.data;CoursePrice
    }, err => {
      console.log("get about course content error ", err);
    });
  }

  getDiscountedPrice() {
    // let url = apt_url + 'Classes?filter={"where":{"id":"5f255bc721371a76fe1f6aa2"}}';
    // let url = apt_url + 'Classes?filter={"where": {"id": "'+this.classId+'"}}';
    //  let url=apt_url +'Subjects?filter={"where": {"classId": "'+this.classId+'"}}';
    let url = apt_url + 'Subjects?filter={"where": {"and":[{"classId": "' + this.classId + '"},{"status":"published"}]}}';
    let postData: any = {

    };
    this.getTopicService.getData(url).subscribe(response => {
      console.log("all discountPrice here data is **** ", response);
      this.listAllSubjects = response; let temp = this.listAllSubjects; let discntedPrice = 0;
      for (let i = 0; i < temp.length; i++) {
        for (let j = 0; j < this.allSubject.length; j++) {
          if (temp[i].id == this.allSubject[j]._id && this.allSubject[j].order == false)
            discntedPrice += temp[i].discountedPrice;
        }
      }
      this.discountedPrice = discntedPrice;
      this.CoursePrice = this.discountedPrice;
      //     this.discountPrice = temp[0].discountPrice;

      this.loading5 = false;
      //  this.allSubject = temp.data;CoursePrice
    }, err => {
      console.log("discount price error ", err);
    });
  }
}

