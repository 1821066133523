<div (window:resize)="responsiveDesign()">
  <div class="no-data" *ngIf="loading" fxLayoutAlign="center center" fxFlex>
    <ngx-loading [show]="loading"
      [config]="{backdropBackgroundColour: 'rgba(0, 0, 0, 0)', primaryColour: '#54ACE4' , secondaryColour: '#2486C5', tertiaryColour:  '#0C5C8F' }">

    </ngx-loading>
  </div>

  <div *ngIf="!loading" fxLayout="column">
    <div fxLayoutAlign="center center">
      <br>
      <h1 style="font-size: 34px; padding-top: 3px; margin-top: 30px"><b>{{aboutUsData?.aboutApt?.title}}</b></h1>
    </div>
    <br>
    <br>
    <div fxLayout fxLayoutAlign="space-between center" fxLayoutGap="20px">
      <div fxFlex="100">
        <img [src]="imgBase + aboutUsData?.aboutApt?.coverImage" width="100%">
      </div>
    </div>
    <br>
    <br>

    <div fxLayout fxLayoutAlign="center center">
      <h4 fxFlex="90" class="courseDis">
        {{aboutUsData?.aboutApt?.description}}
      </h4>
    </div>
    <br><br>
    <div fxLayout fxLayoutAlign="center center" class="full-w">
      <div fxFlex="90" fxLayout fxLayoutAlign="space-between center" fxLayoutGap="20px" fxLayout.sm="column"
        fxLayout.xs="column">
        <div fxFlex="35" fxFlex.sm="100%" fxFlex.xs="100%">
          <div fxLayout="column">
            <h1 class="mat-h1"><b>{{aboutUsData?.aboutFounders?.title}}</b></h1>
            <p class="mat-body">{{aboutUsData?.aboutFounders?.description}}</p>
          </div>
        </div>
        <div fxLayout fxLayout.xs="column" fxLayoutAlign="end" fxLayoutAlign.sm="center" fxLayoutAlign.xs="center"
          fxFlex="60" fxLayoutGap="40px" fxLayoutGap.md="20px" fxLayoutGap.sm="30px" fxLayoutGap.xs="10px">

          <mat-card *ngFor="let founder of aboutUsData?.aboutFounders?.founders; let i = index"
            class="example-card custom-elevation-low" style>

            <div (mouseover)="showAbout[i] = true" (mouseout)="showAbout[i] = false">
              <img class="img" height="200" width="200" style="object-fit: cover; border-radius: 10px;"
                [src]="imgBase + founder.image">
              <div *ngIf="showAbout[i]" style="border-radius: 10px; height: 200px; width: 200px; margin-top: -200px;"
                fxLayout fxLayoutAlign="center center" class="about-text">
                <p style="text-align: center;" class="mat-body">{{founder?.designation}}</p>
              </div>

            </div>
            <div style="width: 200px; text-align: center;" fxLayout="column" fxLayoutAlign="center center">
              <p class="mat-body-strong name" style="height: 35px; text-align: center;">
                {{founder?.name}}</p>
              <p class="mat-body designation no-margin">{{founder?.about}}</p>
            </div>
            <mat-card-actions style="padding: 10px 0;" fxLayout fxLayoutAlign="space-between center">
              <a id="founders0-facebook" [href]="founder.facebook" target="_blank"
                class="fa fa-facebook fast fa-facebook1"></a>
              <a id="founders0-twitter" [href]="founder.twitter" target="_blank"
                class="fa fa-twitter fast fa-twitter1"></a>
              <a id="founders0-instagram" [href]="founder.instagram" target="_blank"
                class="fa fa-instagram fast fa-instagram1"></a>
              <a id="founders0-linkedin" [href]="founder.linkedin" target="_blank"
                class="fa fa-linkedin fast fa-linkedin1"></a>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </div>

    <br><br>

    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.xs="start start"
      [class]="mobileDevice? 'text-l' : 'text-c' " style="margin-left: 5%;margin-right: 5%;">
      <p class="registraion">{{aboutUsData?.aboutFaculties?.title}}</p>
      <br>
      <h4 class="courseDis">{{aboutUsData?.aboutFaculties?.description}}</h4>
    </div>

    <br> <br>


    <div fxLayout fxLayoutAlign="center center" class="full-w">
      <div fxLayout fxLayoutGap="20px" fxLayoutAlign="center" class="full-width">
        <div class="flex-container">
          <mat-card *ngFor="let faculty of aboutUsData?.aboutFaculties?.faculties; let i = index"
            class="example-card custom-elevation-low" style>

            <div (mouseover)="showAbout[i] = true" (mouseout)="showAbout[i] = false">
              <img class="img" height="200" width="200" style="object-fit: cover; border-radius: 10px;"
                [src]="imgBase + faculty.image">
              <div *ngIf="showAbout[i]" style="border-radius: 10px; height: 200px; width: 200px; margin-top: -200px;"
                fxLayout fxLayoutAlign="center center" class="about-text">
                <p style="text-align: center;" class="mat-body">{{faculty?.designation}}</p>
              </div>

            </div>
            <div style="width: 200px; text-align: center;" fxLayout="column" fxLayoutAlign="center center">
              <p class="mat-body-strong name" style="height: 35px; text-align: center;">
                {{faculty?.name}}</p>
              <p class="mat-body designation no-margin">{{faculty?.about}}</p>
            </div>
            <mat-card-actions style="padding: 10px 0;" fxLayout fxLayoutAlign="space-between center">
              <a id="founders0-facebook" [href]="faculty.facebook" target="_blank"
                class="fa fa-facebook fast fa-facebook1"></a>
              <a id="founders0-twitter" [href]="faculty.twitter" target="_blank"
                class="fa fa-twitter fast fa-twitter1"></a>
              <a id="founders0-instagram" [href]="faculty.instagram" target="_blank"
                class="fa fa-instagram fast fa-instagram1"></a>
              <a id="founders0-linkedin" [href]="faculty.linkedin" target="_blank"
                class="fa fa-linkedin fast fa-linkedin1"></a>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>

    </div>
  </div>