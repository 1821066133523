<div class="full-width vh-100" *ngIf="dataLoaded">
    <mat-toolbar class="mat-elevation-z3 c-tool" fxLayout fxLayoutAlign="space-between center">
        <div fxLayout fxLayoutAign="start center" fxLayoutGap="10px">
            <h3 class="mat-h3 px-18-14 no-margin">&nbsp;&nbsp;Total Ques: {{testDetails?.totalQuestion}}</h3>
            <h3 class="mat-h3 px-18-14 no-margin">&nbsp;|&nbsp;</h3>
            <h3 class="mat-h3 px-18-14 no-margin">Time: {{testTime}} min</h3>
        </div>
        <div fxLayout fxLayoutGap="10px" fxLayoutAlign="end center">
            <!-- <mat-icon>watch_later_outline</mat-icon> -->
            <mat-icon>watch_later</mat-icon>
            <p *ngIf="seconds > 9" class="red px-18-14 no-margin">{{timeLeft}}:{{seconds}}</p>
            <p *ngIf="seconds <= 9" class="red px-18-14 no-margin">{{timeLeft}}:0{{seconds}}</p>
            <span>&nbsp;&nbsp;</span>
        </div>
    </mat-toolbar>

    <div class="c-tool" fxLayout fxLayoutAlign="space-between center">
        <img style="margin-left: 10px;" src="https://v2.aptstudy.com/wp-content/uploads/2018/11/logo.png" height="50">
        <button *ngIf="mobileDevice" id="btn" class="btn-marks" mat-raised-button color="primary"
            (click)="openSidenav(sidenav)" [disableRipple]="true">
            <span fxLayout fxLayoutGap="20px" fxLayoutAlign="start center">
                <mat-icon class="btn-icon">{{iconName}}</mat-icon>
                <span fxLayout="column">
                    <span style="color: white;height: 16px;">MARK</span>
                    <span style="color: white;">ANSWERS</span>
                </span>
                <span *ngIf="showResult"
                    style="color: white; font-size: 18px;">{{markCounter}}/{{testDetails?.totalQuestion}} </span>
            </span>
        </button>

        <button *ngIf="!mobileDevice" class="btn-marks" mat-raised-button color="primary" [disableRipple]="true">
            <span style="color: white; font-size: 18px;">MARK ANSWERS
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{markCounter}}/{{testDetails?.totalQuestion}}</b></span>
        </button>
    </div>
    <mat-divider></mat-divider>

    <mat-sidenav-container class="full-width c-height" hasBackdrop="false" (window:resize)="getWidth()">

        <mat-sidenav autoFocus="false" class="mat-elevation-z1 answers" #sidenav
            [disableClose]="mobileDevice ? false : true" [mode]="mobileDevice ? 'over' : 'side'"
            [opened]="mobileDevice ? false : true" position="end">
            <div>
                <div class="option-container" fxLayout *ngFor="let ques of totalQuestions; let i = index">
                    <div fxLayout fxLayoutAlign="center center" fxFlex="15">
                        <p class="mat-body no-margin">{{i + 1}}.</p>
                    </div>
                    <div fxFlex="85" fxLayout fxLayoutAlign="space-around center">
                        <mat-radio-group *ngIf="testDetails?.questionType === 'single'" fxFlex="100" fxLayout labelPosition="before" fxLayoutAlign="space-around"
                            aria-labelledby="example-radio-group-label">
                            <mat-radio-button color="primary" *ngFor="let opt of options; let j = index"
                                (change)="getAnswer($event, j, i, 'single')" [value]="opt"
                                [checked]="allAnswers[i]?.options[j]?.answer">
                                {{opt?.toUpperCase()}}.
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="testDetails?.questionType === 'multiple'" class="padding-20" fxFlex="100" fxLayout
                            fxLayoutAlign="space-around center">
                            <div *ngFor="let opt of options; let j = index">
                                <mat-checkbox (change)="getAnswer($event, j, i, 'multiple')"
                                    [checked]="allAnswers[i]?.options[j]?.answer"><b>{{opt?.toUpperCase()}}</b></mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout fxLayoutAlign="center center" style="width: 100%;padding: 10px 0;">
                <button fxFlex="80" mat-raised-button color="primary" style="color: white;" (click)="promptDialog()">
                    SUBMIT
                </button>
            </div>
        </mat-sidenav>

        <mat-sidenav-content class="ov-flw-h">
            <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" class="zoom-btn">
                <button mat-icon-button (click)="rotations()">
                    <mat-icon>rotate_90_degrees_ccw</mat-icon>
                </button>

                <button mat-icon-button (click)="top()">
                    <mat-icon>first_page</mat-icon>
                </button>

                <button mat-icon-button (click)="bottom()">
                    <mat-icon>last_page</mat-icon>
                </button>
                <button mat-icon-button (click)="zoomIn()">
                    <mat-icon>zoom_in</mat-icon>
                </button>

                <button mat-icon-button (click)="zoomOut()">
                    <mat-icon>zoom_out</mat-icon>
                </button>
            </div>
            <div class="full-width pdf-container">
                <pdf-viewer [src]="src+'#toolbar=0'" [render-text]="true" style="display: block;" [zoom]="zoomPDF"
                    [rotation]="rotation" (after-load-complete)="totalPages($event)" [page]="pdfPage"
                    [original-size]="true" [fit-to-page]="true"></pdf-viewer>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
<div class="full" fxLayout fxLayoutAlign="center center" *ngIf="!dataLoaded">
    <mat-spinner diameter="40"></mat-spinner>
</div>