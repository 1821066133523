<div (window:resize)="getWidth()" fxLayout fxLayoutAlign="center center" *ngFor="let blog of allBlogs;let i = index">
    <div fxFlex="100">
        <div fxLayout fxLayoutAlign="start center">
            <h1 [style.marginBottom]="mobileDevice ? '10px' : '15px'" class="courseHeading no-margin">
                {{blog.title}}
            </h1>
        </div>
        <div [style]="mobileDevice ? 'padding: 5px 0;' : 'padding: 0'" fxLayout
            fxLayoutAlign="start center">
            <div fxFlex="50" fxLayoutAlign="start center" fxFlex.sm="40" fxFlex.xs="40">
                <div class="BlogsHeading cut-text">
                    <button style="pointer-events: none;" mat-icon-button>
                        <mat-icon class="BlogsIcon">people_outline</mat-icon>
                    </button> By {{blog.staff.name}}
                </div>
            </div>
            <div fxFlex="50" fxLayoutAlign="start center" fxFlex.sm="60" fxFlex.xs="60">
                <div class="BlogsHeading cut-text">
                    <button style="pointer-events: none;" mat-icon-button>
                        <mat-icon class="BlogsIcon">list_alt</mat-icon>
                    </button> {{blog.categories[0].title}} <span *ngIf="blog.categories.length > 1"
                        #tooltip="matTooltip" [matTooltip]="returnValName(blog.categories, 'cat')"
                        style="color: #1565c0;">+
                        {{blog.categories.length - 1}} </span>
                </div>
            </div>
        </div>
        <div fxLayout fxLayoutAlign="center center">
            <div fxFlex="100" (click)="readBlog(blog)">
                <img [src]="imageUrl + blog.cover " class="blogsImage" alt="home">
            </div>
        </div>
        <br>
        <div fxLayout fxLayoutAlign="center center" (click)="readBlog(blog)" #blogdata>
            <div fxFlex="100" class="classData">
                <p class="mat-body-strong">
                    {{blog?.preview}}
                </p>
            </div>
        </div>
        <br>
        <div class="readone" fxLayoutAlign="start center">
            <p class="readMore" (click)="readBlog(blog)" style="cursor: pointer;">
                READ MORE
            </p>
        </div>
        <br>
    </div>
</div>

<div fxLayout fxLayoutAlign="center center" class="full-width no-data" *ngIf="!allBlogs || !allBlogs?.length">
    <h3>NO POSTS FOUND!</h3>
</div>