import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { MatSnackBar } from "@angular/material/snack-bar";
import { DOCUMENT, Location } from "@angular/common";
import { base_url } from '../../../service/config';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetDataServiceService } from '../../../service/get-data-service.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ThanksComponent } from '../../../thanks/thanks.component';
import { NotRegisterComponent } from '../../../not-register/not-register.component';
import { InvalidCampaignComponent } from '../../invalid-campaign/invalid-campaign.component';
const NUM_REGEX = /^[1-9][0-9]{5,14}$/;
const EMAIL_REGEX = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
@Component({
  selector: 'app-norm-camp',
  templateUrl: './norm-camp.component.html',
  styleUrls: ['./norm-camp.component.scss']
})
export class NormCampComponent implements OnInit {

  id: any
  normalCampaignData: any;
  dir = base_url + 'uploads/campaign/download/';
  mobileDevice: boolean = false;
  public utm_source: string = '';
  public utm_medium: string = '';
  public campaignType: string = '';
  public publisherId: string = '';
  public invalidcampaign: boolean = true;
  campForm = new FormGroup({});
  formLoaded: boolean = false;
  public maxLength: number = 10;
  public minLength: number;

  isObject(val): boolean {
    return typeof val === 'object';
  }

  isString(val): boolean {
    return typeof val === 'string';
  }
  formData: any = [
    {
      key: "name",
      label: "Name",
      required: true,
      subtype: "text",
      type: "Textinput"
    },
    {
      key: "country",
      label: "Country",
      multiple: false,
      options: ["India", "UAE", "Bangladesh", "Singapore", "Nepal"],
      required: true,
      subtype: "single",
      type: "Dropdown"
    },
    {
      key: "city",
      label: "City",
      required: true,
      subtype: "text",
      type: "Textinput"
    },
    {
      key: "mobile",
      label: "Contact Number",
      required: true,
      subtype: "number",
      type: "Textinput"
    },
    {
      key: "email",
      label: "Email",
      required: true,
      subtype: "text",
      type: "Textinput"
    }
  ]




  constructor(public ps: GetDataServiceService, public route: ActivatedRoute,
    public location: Location, public dialog: MatDialog, @Inject(DOCUMENT) private document: Document) {
    this.id = this.route.snapshot.queryParamMap.get('id')
    this.utm_source = this.route.snapshot.queryParamMap.get('utm_source');
    if (this.utm_source !== null) {
      this.utm_medium = this.route.snapshot.queryParamMap.get('utm_medium');
      this.campaignType = this.route.snapshot.queryParamMap.get('campaigntype');
      this.publisherId = this.route.snapshot.queryParamMap.get('publisherid');
    } else {

    }
    this.getData(this.id)
  }

  ngOnInit(): void {
    this.getWidth()
  }


  url = base_url + 'NormalCampaigns/';
  getNormalCampaign(id) {
    return this.ps.getData(this.url + id)
  }

  getForm(id) {
    let url = base_url + 'Forms/'
    let filter = '?filter={"where": {"campaignId": "' + id + '"}}'
    return this.ps.getData(url + filter)
  }


  createForm(data) {
    data.forEach((element, index) => {
      console.log(element.key);
      this.campForm.addControl(
        element.key, element.key === 'email' ? (
          new FormControl("", element.required ? [Validators.pattern(EMAIL_REGEX)] : [])) : (
        new FormControl("", element.required ? [Validators.required] : []))
      );

      if (index === data.length - 1) {
        this.formLoaded = true
      }
    });
  }

  countryCheck(country) {
    if (country === 'country') {
      if (this.campForm.value.country === "India") {
        console.log(this.campForm.value.country)
        this.minLength = 10;
        this.maxLength = 10;
      }
      else {
        console.log(this.campForm.value.country)
        this.minLength = 5;
        this.maxLength = 16;
      }
    }
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }







  getData(id) {
    this.getNormalCampaign(id).subscribe((data: any) => {
      console.log(data)
      if (data.statusCode !== 200 || !data.data || !data.data.status || data.data.status !== 'published') {
        this.invalidcampaign = false;
        const dialogRef1 = this.dialog.open(InvalidCampaignComponent, {
          width: '500px',
          disableClose: true,
          data: { screen: " Invalid Campaign", action: "", heading: "This campaign is not available" }
        }).afterClosed().subscribe(e => {
          this.redirect();
        });
      }
      else if ((data.statusCode === 200 && !data.data) || (data.statusCode === 200 && !data.data.status) || (data.statusCode === 200 && data.data.status !== 'published')) {
        this.invalidcampaign = false;
        const dialogRef1 = this.dialog.open(InvalidCampaignComponent, {
          width: '500px',
          disableClose: true,
          data: { screen: " Invalid Campaign", action: "", heading: "This campaign is not available" }
        }).afterClosed().subscribe(e => {
          this.redirect();
        });
      }
      else {
        this.normalCampaignData = data.data
        this.getForm(id).subscribe((form: any) => {
          console.log(form)
          if (form.statusCode === 200) {
            if (form.data.length > 0) {
              this.formData = [...this.formData, ...form.data[0].requestData.fields]
              console.log(this.formData)
              this.createForm(this.formData)
            }
            else {
              this.formData = [...this.formData];
              this.createForm(this.formData);
            }
            if (form.statusCode === 200) {
              this.normalCampaignData.form = form.data[0]
              // setForm(form.data[0])
              console.log(this.normalCampaignData)
            }
          }
        })
      }
    })
  }
  redirect() {
    this.document.location.href = 'https://aptstudy.com/';
  }


  getWidth(event?) {
    this.mobileDevice = event ? event.target.innerWidth <= 959 ? true : false : window.innerWidth <= 959 ? true : false
  }

  submit() {
    let url = base_url + 'CampaignUsers';
    let obj = {};
    if (this.utm_source !== null) {
      obj = {
        ...this.campForm.value,
        ...{
          username: this.campForm.value.mobile,
          campaignId: this.id,
          password: "apt#123",
          credits: 0,
          utm_medium: this.utm_medium,
          campaigntype: this.campaignType,
          publisherid: this.publisherId,
          utm_source: this.utm_source,
          source: this.campaignType,
        }
      };
    }
    else {
      obj = {
        ...this.campForm.value,
        ...{
          username: this.campForm.value.mobile,
          campaignId: this.id,
          credits: 0,
          password: "apt#123",
          source: this.campaignType,
        }
      };
    }
    this.ps.PostData(obj, url).subscribe((response: any) => {
      console.log(response);
      if (response.statusCode === 200) {
        this.dialog.open(ThanksComponent, {
          width: '500px',
          disableClose: true,
          data: { screen: "Thank You", action: "Thanks", heading: "Thank You For Registering" }
        }).afterClosed().subscribe(data => {
          this.campForm.reset();
          /*
          Object.keys(this.campForm.controls).forEach(key => {
            this.campForm.get(key).setErrors(null);
          }); */
          
        });
      }
      else {
        this.dialog.open(NotRegisterComponent, {
          width: '500px',
          disableClose: true,
          data: { screen: "Not Registered", action: "", heading: "Could Not Register" }
        });
      }

    });
  }
}

