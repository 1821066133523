import { Component, OnInit, ɵbypassSanitizationTrustResourceUrl } from '@angular/core';
import { ActivatedRoute, Router, ActivationEnd } from "@angular/router";
import { GetDataServiceService } from '../../../service/get-data-service.service';
import { base_url, apt_url } from "../../../service/config";
import { MatSnackBar } from "@angular/material/snack-bar";
import { interval } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadsAppComponent } from '../../../dialog/downloads-app/downloads-app.component';
import { ExistsComponent } from '../../../exists/exists.component'
import { FormControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subscription, timer } from 'rxjs';
import { flyInOut, expand } from '../../../animation-details/animation-details.component';

const NUM_REGEX = /^[1-9][0-9]{5,19}$/;
const NAME_REGEX = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
const EMAIL_REGEX = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { trigger, transition, style, animate, stagger, query, useAnimation } from '@angular/animations';
import {
	slideInDown,
	slideInUp,
	slideInLeft,
	slideInRight,
	slideOutDown,
	slideOutUp,
	slideOutLeft,
	slideOutRight,
} from 'ng-animate';

@Component({
	selector: 'app-course-detail',
	templateUrl: './course-detail.component.html',
	styleUrls: ['./course-detail.component.scss'],
	animations: [
		flyInOut(),
		expand(),
		trigger('slideInDown', [transition('* => *', useAnimation(slideInDown))]),
		trigger('slideInUp', [transition('* => *', useAnimation(slideInUp))]),
		trigger('slideInLeft', [transition('* => *', useAnimation(slideInLeft))]),
		trigger('slideInRight', [transition('* => *', useAnimation(slideInRight))])
	],
})
export class CourseDetailComponent implements OnInit {

	private subscrip: Subscription;
	private timer: Observable<any>;

	public loading1: boolean = true;
	public loading2: boolean = true;
	public loading3: boolean = true;

	public namelength: any;
	public bool1: boolean;
	public slideInDown: any;
	public slideInUp: any;
	public slideInLeft: any;
	public slideInRight: any;
	public appointmentsForm: FormGroup;
	public classId: string = '';
	public ClassName: string = '';
	public ratingCount: number = 0;
	public SeniorBullet: any = ["Lorem ipsum, or lipsum as it is sometimes known.", " graphic or web designs.", "The purpose of lorem ipsum is to create.", "focus is meant to be on design, not content.", "The passage experienced"];
	public imageUrl: string = apt_url + 'uploads/Class/download/'
	public TooperimageUrl: string = apt_url + 'Uploads/products/download/'
	public timerData: any = [];
	// urm source related variables
	public utm_source: string = '';
	public utm_medium: string = '';
	public campaignType: string = '';
	public publisherId: string = '';
	public val: any;
	public bool: boolean;
	public mobileMax: any;
	public flag: boolean;
	public AllCoutryName: any;
	public img: any;
	public link: any;


	constructor(public fb: FormBuilder, public getTopicService: GetDataServiceService, public router: Router,
		public snakBar: MatSnackBar, public route: ActivatedRoute, public dialog: MatDialog, public sanitizer: DomSanitizer) {
		this.ClassName = this.route.snapshot.queryParamMap.get('name');
		this.classId = this.route.snapshot.queryParamMap.get('id');
		this.utm_source = this.route.snapshot.queryParamMap.get('utm_source');
		if (this.utm_source !== null) {
			this.utm_medium = this.route.snapshot.queryParamMap.get('utm_medium');
			this.campaignType = this.route.snapshot.queryParamMap.get('campaigntype');
			this.publisherId = this.route.snapshot.queryParamMap.get('publisherid');
		} else {


		}
		this.getAllCountry();
		this.initForm();
	}

	ngOnInit(): void {
		//this.SettimeInterval();
		this.getProductData();
		this.showRating(this.ratingCount);
		this.getClassData();
		this.getWidth();
	}

	mobileDevice: boolean = false
	getWidth(event?) {
		this.mobileDevice = event ? event.target.innerWidth <= 600 ? true : false : window.innerWidth <= 600 ? true : false
	}

	public ngOnDestroy() {
		if (this.subscrip && this.subscrip instanceof Subscription) {
			this.subscrip.unsubscribe();
		}
	}


	onChange(event) {
		console.log(event.dial_code)
		if (event.dial_code == 91) {
			this.mobileMax = 10;
			this.flag = true;
			this.appointmentsForm.controls.mobile.setValidators([Validators.required, Validators.pattern(NUM_REGEX), Validators.minLength(10), Validators.maxLength(10)])
			this.appointmentsForm.controls.mobile.updateValueAndValidity()
		} else {
			this.mobileMax = 15;
			this.flag = false;
			this.appointmentsForm.controls.mobile.setValidators([Validators.required, Validators.pattern(NUM_REGEX), Validators.minLength(6), Validators.maxLength(16)])
			this.appointmentsForm.controls.mobile.updateValueAndValidity()
		}
	}


	defaultCountry: any;
	getAllCountry() {
		let url = 'assets/jsonFile/allCountryCodes.json';
		console.log(url);
		this.getTopicService.getData(url).subscribe(response => {
			// console.log("get class details data is ", response);
			this.AllCoutryName = response;
			this.defaultCountry = this.AllCoutryName[0];
			console.log("tisis ", this.AllCoutryName)
			// this.classDetail = dummy.data;   
		})
	}

	safeUrl(value: any): SafeResourceUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl(value)
	}


	// inilize form value 
	initForm() {
		this.appointmentsForm = this.fb.group({
			name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(150), Validators.pattern(NAME_REGEX)]),
			email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)]),
			mobile: new FormControl('', [Validators.required, Validators.pattern(NUM_REGEX), Validators.minLength(10), Validators.maxLength(10)]),
			country: new FormControl('', [Validators.required]),
		});
	}
	// 

	showRating(rating) {
		// THE METHOD DISPLAYS RATING OF THE PARTICULAR COURSE
		var arr: any = [];
		for (let i = 5; i > 0; i--) {
			if (Number.isInteger(rating)) {
				if (i <= rating) {
					arr.push('star')
				}
				else {
					arr.push('star_outline')
				}
			}
			else {
				var diff = i - rating;
				if (diff >= 1)
					arr.push('star_outline')
				else if (diff < 1 && diff > 0)
					arr.push('star_half')
				else
					arr.push('star')

			}
			if (i === 1) {
				return arr.reverse(); //returns array
			}
		}
	}


	BuyNow() {
		this.dialog.open(DownloadsAppComponent, {
			width: '500px',
			disableClose: true,
			data: { screen: "Class DEtail", action: "Course", heading: "Your Information" }
		}).afterClosed().subscribe(result => {
			console.log('The dialog was closed', result);
			if (result) {
				let url = apt_url + 'AppUsers/createUser';
				let postData: any = {};
				if (this.utm_source !== null) {
					postData = {
						name: result.name,
						mobile: result.mobile,
						email: result.email,
						classId: this.classId,
						createdAt: new Date(),
						school: result.schoolname,
						city: result.city,
						countryCode: result.countrycode,
						country: result.country,
						languageId: result.languageId,
						"utm_medium": this.utm_medium,
						"compaigntype": this.campaignType,
						"publisherid": this.publisherId,
						"utm_source": this.utm_source,
						"source": this.campaignType,
					}
				} else {
					postData = {
						name: result.name,
						mobile: result.mobile,
						email: result.email,
						classId: this.classId,
						school: result.schoolname,
						city: result.city,
						createdAt: new Date(),
						countryCode: result.countrycode,
						country: result.country,
						languageId: result.languageId
					}
				}
				this.getTopicService.PostData(postData, url).subscribe((response: any) => {
					console.log("all class data is here ", response);
					let usrId = "";
					let temp: any = response;
					this.val = response["message"]
					console.log('This val is', this.val);
					if (this.val == 'ORDER_ALREADY_EXIST') {
						const dialogRef1 = this.dialog.open(ExistsComponent, {
							width: '500px',
							disableClose: true,
							data: { screen: "Buy details", action: "Course", heading: "Already Purchased!!" }
						});
					}
					else if (this.val.includes('Email already exists')) {
						console.log('Here');
						this.snakBar.open('Email already exists !', 'OK', {
							duration: 5000
						});
					}
					else {
						if (this.val != "USER_ALREADY_EXIST") {
							this.snakBar.open('Thanks for sharing your information with us', 'OK', {
								duration: 3000
							})
							// sessionStorage.setItem('savedName', result.name);
							// sessionStorage.setItem('savedContact', result.mobile);
							// sessionStorage.setItem('savedEmailId', result.email);
							// sessionStorage.setItem('savedSchool', result.schoolname);
							// sessionStorage.setItem('savedCity', result.city);
							// sessionStorage.setItem('savedCountry', result.country);
							sessionStorage.setItem('savedName', result.name);
							sessionStorage.setItem('savedContact', result.mobile);
							sessionStorage.setItem('savedEmailId', result.email);
							sessionStorage.setItem('savedSchool', result.schoolname);
							sessionStorage.setItem('savedCity', result.city);
							sessionStorage.setItem('savedCountry', result.country);
							if (this.utm_source !== null) {
								console.log('Router1')
								this.router.navigate(['/course/buy-course'], { queryParams: { classId: this.classId, userId: temp.user.id, utm_source: this.utm_source, utm_medium: this.utm_medium, campaigntype: this.campaignType, publisherid: this.publisherId } });
								console.log('Inside this');
							} else {
								console.log('Router2')
								this.router.navigate(['/course/buy-course'], { queryParams: { classId: this.classId, userId: temp.user.id } });
							}
						}
						else {
							// sessionStorage.setItem('savedName', result.name);
							// sessionStorage.setItem('savedContact', result.mobile);
							// sessionStorage.setItem('savedEmailId', result.email);
							// sessionStorage.setItem('savedSchool', result.schoolname);
							// sessionStorage.setItem('savedCity', result.city);
							// sessionStorage.setItem('savedCountry', result.country);
							sessionStorage.setItem('savedName', result.name);
							sessionStorage.setItem('savedContact', result.mobile);
							sessionStorage.setItem('savedEmailId', result.email);
							sessionStorage.setItem('savedSchool', result.schoolname);
							sessionStorage.setItem('savedCity', result.city);
							sessionStorage.setItem('savedCountry', result.country);
							if (this.val === "USER_ALREADY_EXIST") {
								let url = apt_url + 'Subjects/getAllSubject';
								let postData: any = {
									classId: this.classId,
									userId: temp.user[0].id
								};
								this.getTopicService.PostData(postData, url).subscribe((res: any) => {
									let subDetail = res.data; let count = 0;
									console.log('Res value is', res);
									let subDetaillength = subDetail.length;
									for (let i = 0; i < subDetaillength; i++) {
										console.log('Detail of order', subDetail[i].order);
										if (subDetail[i].order) {
											count++;
											console.log('Count from if condition', count);
										}
									}
									if (count < subDetaillength) {
										if (this.utm_source !== null) {
											console.log('Router1')
											this.router.navigate(['/course/buy-course'], { queryParams: { classId: this.classId, userId: temp.user[0].id, utm_source: this.utm_source, utm_medium: this.utm_medium, campaigntype: this.campaignType, publisherid: this.publisherId } });
										} else {
											console.log('Router2')
											this.router.navigate(['/course/buy-course'], { queryParams: { classId: this.classId, userId: temp.user[0].id } });
										}
										this.snakBar.open('Continue Purchase with us !', 'OK', {
											duration: 3000
										});
									}
									else {
										console.log('Count value is', count);
										const dialogRef1 = this.dialog.open(ExistsComponent, {
											width: '500px',
											disableClose: true,
											data: { screen: "Buy details", action: "Course", heading: "Already Purchased!!" }
										});
										console.log('Already exists');
									}
								}, error => {
									console.log("app downlods  geting error ", error);
								});
							}
						}
					} console.log('Inside this');

				}, error => {
					console.log("app downlods  geting error ", error);
				});
			}
		});
	}

	existsData() {
	}

	shuffle(array) {
		var currentIndex = array.length, temporaryValue, randomIndex;
		// While there remain elements to shuffle...
		while (0 !== currentIndex) {
			// Pick a remaining element...
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;

			// And swap it with the current element.
			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}
		// console.log("before data ",this.timerData);
		this.timerData = array;
		// console.log("before data ",this.timerData);
	}


	DownloadsApp() {
		this.dialog.open(DownloadsAppComponent, {
			width: '500px',
			disableClose: true,
			data: { screen: "home", action: "download App", heading: "User Information" },
		}).afterClosed().subscribe(result => {
			console.log('The dialog was closed', result);
			if (result !== undefined) {
				console.log("post data");
				let url = base_url + 'CampaignUsers';
				let postData: any = {};
				if (this.utm_source !== null) {
					postData = {
						name: result.name,
						mobile: result.mobile,
						password: 'apt#123',
						createdAt: new Date(),
						countrycode: result.countrycode,
						"utm_medium": this.utm_medium,
						"compaigntype": this.campaignType,
						"publisherid": this.publisherId,
						"utm_source": this.utm_source,
					}

				} else {
					postData = {
						name: result.name,
						mobile: result.mobile,
						countrycode: result.countrycode,
						password: 'apt#123',
						createdAt: new Date()
					}
				}

				this.getTopicService.PostData(postData, url).subscribe(response => {
					console.log("all class data is here ", response);
					// let temp: any = response;
					this.snakBar.open('Thanks for sharing your information with us', 'OK', {
						duration: 3000
					})

				}, error => {
					console.log("app downlods  geting error ", error);
				});
			}
		});
	}

	submitForm() {
		console.log("form input value is", this.appointmentsForm.value);
		if (this.appointmentsForm.valid) {
			let postData: any = {};
			if (this.utm_source !== null) {
				postData = {
					name: this.appointmentsForm.value.name,
					mobile: this.appointmentsForm.value.mobile,
					email: this.appointmentsForm.value.email,
					country: this.appointmentsForm.value.country.name,
					countryCode: this.appointmentsForm.value.country.dial_code,
					classId: this.classId,
					//		source:this.ClassName,
					password: 'apt#123',
					createdAt: new Date(),
					"utm_medium": this.utm_medium,
					"compaigntype": this.campaignType,
					"publisherid": this.publisherId,
					"utm_source": this.utm_source,
					"source": this.campaignType,
				}

			} else {
				postData = {
					name: this.appointmentsForm.value.name,
					mobile: this.appointmentsForm.value.mobile,
					email: this.appointmentsForm.value.email,
					country: this.appointmentsForm.value.country.name,
					countryCode: this.appointmentsForm.value.country.dial_code,
					classId: this.classId,
					source: this.ClassName,
					password: 'apt#123',
					createdAt: new Date(),
				}
			}

			let url: any = base_url + 'AppoinmentUsers'
			this.getTopicService.PostData(postData, url).subscribe(response => {
				console.log("all class data is here ", response);
				// let temp: any = response;
				this.snakBar.open('Thanks for sharing your information with us', 'OK', {
					duration: 3000
				})
				this.appointmentsForm.reset();
				//		this.appointmentsForm.updateValueAndValidity();
				Object.keys(this.appointmentsForm.controls).forEach(key => {
					this.appointmentsForm.get(key).setErrors(null);
				})
			}, error => {
				console.log("app downlods  geting error ", error);
			});
		} else {
			this.snakBar.open('Please enter Required Fields', 'OK', {
				duration: 3000
			})
		}
	}
	public features: any = [];
	public contentTitle: string = '';
	public contentDescription: string = '';
	public rawImage: string = '';
	public courseVideoURL: string = '';
	public courseVideoTitle: string = '';
	public courseVideoDesc: string = '';
	classData: any;
	classData1: any = [];

	productData: any;
	getProductData() {
		let url = apt_url + 'OnlineClassdata?filter={"where": {"classId": "' + this.classId + '"}}&access_token=' + sessionStorage.getItem('aptAccessToken');
		this.getTopicService.getData(url).subscribe((response: any) => {
			console.log("product data is **** ", response);

			let temp: any = response[0];
			this.classData = temp;
			this.classData1.toppers = temp ? temp.toppers : [];
			this.productData = response[0]
			if (temp) {
				if (temp.features !== '' || temp.features !== undefined) {
					this.features = temp.features;
				}

				if (temp.productContent) {
					this.contentTitle = temp.productContent.title;
					this.contentDescription = temp.productContent.description;
					this.rawImage = apt_url + 'Uploads/products/download/' + temp.productContent.image
				}

				if (temp.courseVideoContent) {
					console.log("video", temp.courseVideoContent)
					this.link = this.safeUrl(temp.courseVideoContent.videoData.link)
					this.courseVideoURL = temp.courseVideoContent ? temp.courseVideoContent.url : '';
					this.courseVideoTitle = temp.courseVideoContent ? temp.courseVideoContent.title : '';
					this.courseVideoDesc = temp.courseVideoContent ? temp.courseVideoContent.description : '';
				}
			}
			this.loading3 = false;
			console.log('Loading 3', this.loading3);
		}, err => {
			console.log("get about course content error ", err);
		});
	}

	allClassData: any;
	getClassData() {
		let url = apt_url + 'Classes?filter={"where": {"id": "' + this.classId + '"}}';
		this.getTopicService.getData(url).subscribe((response: any) => {
			console.log('class data', response);
			if (response.length) {
				this.allClassData = response[0]
				let temp: any = response[0];
				this.ClassName = temp ? temp.name : '';
				this.img = temp.image;
				this.ratingCount = 5 //temp.ratingValue;
				this.loading1 = false;
				console.log('Loading1', this.loading1);
			}
			else this.loading1 = false
		}, err => {
			console.log("get about course content error ", err);
		});

	}
}
