import { Component, OnInit, ElementRef, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router, ActivationEnd } from "@angular/router";
import { GetDataServiceService } from '../../service/get-data-service.service';
import { base_url, apt_url } from "../../service/config";
// import { DOCUMENT } from '@angular/common';Inject
import { MatTableDataSource } from '@angular/material/table';
import { trigger, transition, style, animate, stagger, query, useAnimation } from '@angular/animations';
import {
	slideInDown,
	slideInUp,
	slideInLeft,
	slideInRight,
	slideOutDown,
	slideOutUp,
	slideOutLeft,
	slideOutRight,
} from 'ng-animate';
import { MatPaginator } from "@angular/material/paginator";

@Component({
	selector: 'app-blogs',
	templateUrl: './blogs.component.html',
	styleUrls: ['./blogs.component.scss'],
	animations: [
		trigger('slideInDown', [transition('* => *', useAnimation(slideInDown))]),
		trigger('slideInUp', [transition('* => *', useAnimation(slideInUp))]),
		trigger('slideInLeft', [transition('* => *', useAnimation(slideInLeft))]),
		trigger('slideInRight', [transition('* => *', useAnimation(slideInRight))])
	],
})
export class BlogsComponent implements OnInit {

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

	public loading1: boolean = true;
	public loading2: boolean = true;

	public slideInDown: any;
	public slideInUp: any;
	public slideInLeft: any;
	public slideInRight: any;
	@ViewChild('blogdata') myDiv: ElementRef;
	public searchValue: string = '';
	public Allcategory: any = [];
	public allBlogs: any;
	public dataSource: any;
	public imageUrl: string = base_url + 'uploads/blogs/download/';
	// urm source related variables
	public utm_source: string = '';
	public utm_medium: string = '';
	public campaignType: string = '';
	public publisherId: string = '';
	public recentDisplay: any = [];
	public permanentData: any = [];

	constructor(public route: ActivatedRoute, public router: Router, public blogService: GetDataServiceService) {
		window.scrollTo(0, 1)
		this.utm_source = this.route.snapshot.queryParamMap.get('utm_source');
		if (this.utm_source !== null) {
			this.utm_medium = this.route.snapshot.queryParamMap.get('utm_medium');
			this.campaignType = this.route.snapshot.queryParamMap.get('campaigntype');
			this.publisherId = this.route.snapshot.queryParamMap.get('publisherid');
		} else { }
	}
	//  ,@Inject(DOCUMENT) document
	ngOnInit(): void {
		this.GetBlogCategory();
		this.GetBlogs();
		this.getWidth();
	}

	isSearchingPost: boolean = false
	applyFilter(filterValue: string) {
		this.isSearchingPost = true
		this.isSearching()
		console.log("do nothings", filterValue);
		this.dataSource.filter = filterValue.trim().toLowerCase();
		console.log("filter screen  ", this.dataSource)
		this.allBlogs = this.dataSource.filteredData;
		console.log(this.allBlogs);
	}

	isSearching() {
		setTimeout(() => {
			this.isSearchingPost = false
		}, 750)
	}

	OnSearch() {
		this.searchValue = '';
		this.GetBlogs();
	}

	BlogDetail(data) {
		// if (this.utm_source !== null) {
		// 	this.router.navigate(['/blogs/blog-details'], { queryParams: { id: data.id, utm_source: this.utm_source, utm_medium: this.utm_medium, campaigntype: this.campaignType, publisherid: this.publisherId } })
		// } else {
		// 	this.router.navigate(['/blogs/blog-details'], { queryParams: { id: data.id } })
		// }
		if (this.utm_source !== null) {
			this.router.navigate(['/blogs', data.slug], { queryParams: { utm_source: this.utm_source, utm_medium: this.utm_medium, campaigntype: this.campaignType, publisherid: this.publisherId } })
		} else {
			this.router.navigate(['/blogs', data.slug])
		}

	}
	// get blogs category here 
	GetBlogCategory() {
		let url = base_url + 'Categories?filter={"where": {"status": "published"}}'
		this.blogService.getData(url).subscribe(response => {
			console.log("all blogs category is here ", response);
			let temp: any = response;
			this.Allcategory = temp.data;
			this.loading1 = false;
		}, error => {
			console.log("blogs category geting error ", error);
		});
	}

	blogData: any = [];
	recentBlogs: any = []
	getRecentBlogs() {
		let url = base_url + 'Blogs?filter={"limit": 3, "fields": ["title", "cover", "id", "createdBy"] , "where": {"status": "published"},"include": { "relation": "staff", "scope": {"fields": ["name"]}},"order" : "createdAt DESC"}'
		this.blogService.getData(url).subscribe((data: any) => {
			this.recentBlogs = data.data
		})
	}

	// get all published blogs in gere 
	async GetBlogs() {
		let url = base_url + 'Blogs?filter={"where": {"status": "published"},"include": ["categories","staff"],"order" : "createdAt DESC"}'
		this.blogService.getData(url).subscribe(async (response: any) => {
			this.getRecentBlogs();
			console.log("all blogs is here ", response);
			console.log(response);
			response.data = await this.genPreviewData(response.data)
			this.dataSource = new MatTableDataSource(response.data);
			this.allBlogs = this.dataSource && this.dataSource.filteredData ? this.dataSource.filteredData : [];
			this.blogData = [...this.allBlogs];
			this.permanentData = response.data;
			this.recentDisplay = this.permanentData;
			this.loading2 = false;
			setTimeout(() => {
				this.dataSource.paginator = this.paginator
				console.log(this.dataSource, this.paginator)
			}, 1000);
		}, error => {
			console.log("blogs geting error ", error);
		});
	}


	genPreviewData(allBlogs) {
		return new Promise((resolve) => {
			for (let i = 0; i < allBlogs.length; i++) {
				const blog = allBlogs[i];
				blog.preview = this.showdata(blog.content, i)
				if (i === allBlogs.length - 1) resolve(allBlogs)
			}
		})
	}

	showdata(data, index) {
		document.getElementById('b-content').innerHTML = data;
		let text = document.getElementById('b-content').innerText.substring(0, 500)
		return text
	}

	returnValName(array: any, type: string) {
		var returnArray: any = [];
		if (type === 'cat') {
			if (array.length > 0) {
				for (let index = 1; index < array.length; index++) {
					returnArray.push(array[index].title)
				}
				return returnArray.toString().replace(/,/g, ", ")
			}
			else
				return ''
		}
		else {
			if (array.length > 0) {
				for (let index = 1; index < array.length; index++) {
					returnArray.push(array[index])
				}
				return returnArray.toString().replace(/,/g, ", ")
			}
			else
				return ''
		}
	}

	filterByCat(category) {
		this.allBlogs = this.blogData.filter(blog => blog.categoryIds.includes(category.id))
	}


	mobileDevice: boolean = false
	getWidth(event?) {
		this.mobileDevice = event ? event.target.innerWidth <= 600 ? true : false : window.innerWidth <= 600 ? true : false
	}
} 
