<div class="no-data" *ngIf="loading1" fxLayoutAlign="center center" fxFlex>
    <ngx-loading [show]="loading1 && loading2"
        [config]="{backdropBackgroundColour: 'rgba(0, 0, 0, 0)', primaryColour: '#54ACE4' , secondaryColour: '#2486C5', tertiaryColour:  '#0C5C8F' }">

    </ngx-loading>
</div>

<div *ngIf="!loading1 " [@expand] [@flyInOut] fxLayout fxLayoutAlign="start center">
    <div fxFlex="100">
        <div fxLayout fxLayoutAlign="center center">
            <p class="MainHeding"> {{SubjectName}} </p>
        </div>
        <br>
        <div fxLayout fxLayoutAlign="start start" style="margin-left: 5%;margin-right: 5%;" fxLayoutGap="20px"
            fxLayout.sm="column" fxLayout.xs="column">
            <div fxFlex="100">
                <div fxLayout fxLayoutAlign="center center" [@slideInUp]="'slideInUp'">
                    <img src="assets/images/Topics.png" class="imagedata" alt="home">
                </div>
                <p>&nbsp;</p>
                <div fxLayout fxLayoutAlign="center center">
                    <h4 fxFlex="60" fxFlex.sm="70" fxFlex.xs="90" class="courseDis">
                        Our courses are designed to clarify all the topics, be it basic or advanced.
                        We make sure that students get basic strength that they need in all subjects by
                        giving them the highest quality of content available.
                        There is no point in building a monument on a weak foundation.
                        Courses are completed punctually and revision is done properly in advance.
                        Doubts are revisited multiple times for a deeper understanding.
                    </h4>
                </div>
                <br>
                <div fxLayout fxLayoutAlign="center center">
                    <div fxFlex="60" fxFlex.sm="70" fxFlex.xs="100">
                        <mat-nav-list class="mat-elevation-z3">
                            <div *ngFor="let topic of topics;let i = index">
                                <div *ngIf="TopicData[i].length>0">
                                    <mat-list-item (click)="showInfo(topic)">
                                        Chapter {{i+1}} - {{topic.name}}
                                        <span class="spacer"></span>
                                        <button mat-icon-button>
                                            <mat-icon>keyboard_arrow_right</mat-icon>
                                        </button>
                                    </mat-list-item>
                                </div>
                            </div>
                        </mat-nav-list>
                    </div>
                </div>
            </div>
        </div>
        <br><br>
        <app-download-app [from]="SubjectName" [source]="utm_source" [medium]="utm_medium" [campaign]="campaignType"
            [publisher]="publisherId">
        </app-download-app>
        <br><br>
    </div>
</div>
<br>