import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { base_url, apt_url } from "./config";

export interface PATH_DIRECTORY {
  atime: string,
  ctime: string,
  mtime: string,
  name: string,
  size: number,
}

@Injectable({
  providedIn: 'root'
})
export class GetDataServiceService {

  constructor(private http: HttpClient) { }

  allCountries: any = [];
  public getCountries() {
    // THIS FUNCTION GETS COUNTRIES STORED IN APT 
    let url = apt_url + 'Settings?filter={"where": {"type": "country"}, "order": "countryCode ASC"}'
    this.http.get(url).subscribe((data: any) => {
      this.allCountries = this.ascendingOrder(data[0].data, 'countryCode');
    })
  }

  ascendingOrder(array, property) {
    return array.sort((a, b) => {
      return a[property] - b[property];
    })
  }


  defaultLanguage: any = [];
  public getDefLangauge() {
    // THIS FUNCTION GETS ONLY ENGLISH LANGUAGE
    let url = apt_url + 'Languages?filter={"where":{"status":"active", "title": {"like": "english", "options": "i"}}}'
    this.http.get(url).subscribe((data: any) => {
      this.defaultLanguage = data[0]
    })
  }

  /* ---------------------- || BELOW ARE ALL IN  ONE SERVICE METHODS || ------------------------*/


  public getData(url: any) {
    return this.http.get(url)
  }

  public PostData(data: Object, url: any) {
    console.log(url)
    return this.http.post(url, data)
  }

  public PatchData(data: Object, url: any) {
    return this.http.patch(url, data)
  }
  createDirectory2(postItem: any, url: any): any {
    // CREATE FILE DIRECTORY
    return this.http.post<PATH_DIRECTORY>(url, postItem)
  }
  // uploadDocImage(files: FileList, url: any) {
  //   var formData = new FormData();
  //   Array.from(files).forEach(f => formData.append('file', f))
  //   console.log("dsgdshgdhdfhdfhdfhdfhdhdhh", formData, url);
  //   return this.http.post(url, formData)
  // }

  public xUpload = (container: string, path: string, img: any) => {
    return new Promise((resolve, reject) => {
      const directory = `${base_url}uploads`
      this.http.post(directory, { name: container }).subscribe(async (dir: any) => {
        if (dir && dir.name) {
          // UPLOAD IMAGE HERE
          const image = await this.xFileUpload(path, img)
          resolve(image)
        }
        else reject('Failed to create directory')
      }, async (err: any) => {
        // CATCH VALID ERROR AND UPLOAD THE FILE
        if (err.error.error.code === 'EEXIST') {
          // HERE UPLOAD THE FILE
          const image = await this.xFileUpload(path, img)
          resolve(image)
        }
        else reject(err)
      })
    })
  }

  xFileUpload = (path: string, img: any) => {
    // UPLOAD FILE HERE
    return new Promise((resolve, reject) => {
      const xFormData = new FormData()
      xFormData.append('file', img, img.name)
      this.http.post(`${base_url}${path}`, xFormData).subscribe((response: any) => {
        if (response) resolve(response.data.result.files.file[0].name)
        else reject('Failed to upload file.')
      }, (err: any) => {
        reject(err)
      })
    })
  }

  public DeleteData(url: any) {
    return this.http.delete(url)
  }

  public submitAnswer(data: Object, url: any) {
    return this.http.post(url, data)
  }

}
