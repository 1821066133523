<!-- <a #paytm style="display: none;" [href]="paymentURL" target="_self" id="paytmGate">Click here</a> -->

<!-- <a [href]="paymentURL" download="paytmGate" style="display: none;" id="paytmGate"></a> -->

<div class="no-data full-width" fxLayoutAlign="center center">
    <ngx-loading [show]="true"
        [config]="{backdropBackgroundColour: 'rgba(0, 0, 0, 0)', primaryColour: '#54ACE4' , secondaryColour: '#2486C5', tertiaryColour:  '#0C5C8F' }">
    </ngx-loading>
</div>


<a [href]="paymentURL" [download]="paymentURL" style="display: none;" id="paytmGate"></a>