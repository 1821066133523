<div style="height: 100%; width: 100%; overflow: auto !important;">
	<mat-toolbar>
		<img src="https://v2.aptstudy.com/wp-content/uploads/2018/11/logo.png" height="50">
	</mat-toolbar>

	<div fxLayout fxLayoutAlign="center center" (window:resize)="getWidth($event)">
		<img *ngIf="invalidcampaign" [src]="dir + faceCampaignData?.image" height="100" alt="not_error">
	</div>
	<br>
	<div #element class="full-width" fxLayout fxLayoutAlign="center start">
		<div fxFlex="80" fxFlex.xs="96" fxFlex.sm="90" fxLayout.xs="column" fxLayout.sm="column" fxLayout
			fxLayoutAlign="center start" fxLayoutGap="20px">
			<div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100">
				<div [innerHtml]="faceCampaignData?.contentTitle[0].text"
					[style.color]="faceCampaignData?.contentTitle[0]?.color" class="mat-display-1"></div>
				<div fxLayout="column" fxLayoutColumn="center start">
					<p *ngFor="let point of faceCampaignData?.contents" class="mat-body">{{point}}</p>
				</div>
			</div>
			<form [style.width]="mobileDevice ? '100%' : 'auto'" fxLayout="column" fxLayoutAlign="start center"
				*ngIf="formLoaded" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign.xs="start center"
				fxLayoutAlign.sm="start center" fxLayoutGap="10px" [formGroup]="campForm">
				<h1 style="color: #ff0000;" class="mat-h1">Register Now</h1>
				<mat-form-field style="width: 90%;" *ngFor="let form of formData; let i = index">
					<!-- LABEL -->
					<mat-label>{{form.label}}</mat-label>
					<!-- FOR INPUT -->
					<input *ngIf="form.type === 'Textinput'" matInput formControlName="{{form.key}}" autocomplete="off"
						[required]="form.required" [numbersOnly]="form.subtype === 'number'"
						[minlength]="form.key === 'mobile' ? minLength : ''"
						[maxlength]="form.key === 'mobile' ? maxLength : ''">

					<!-- FOR DROPDOWN -->
					<mat-select *ngIf="form.type === 'Dropdown'" required formControlName="{{form.key}}"
						[required]="form.required" [multiple]="form.multiple"
						(selectionChange)="countryCheck(form.key)">
						<mat-option *ngFor="let option of form.options" [value]="option.opt ? option.opt : option">
							{{option.opt ? option.opt : option}}
						</mat-option>
					</mat-select>
					<!-- ERROR -->
					<mat-error *ngIf="form.required" style="color:red;">{{form.key!=="email"?(form.label):('Email
						Pattern')}} is required</mat-error>
				</mat-form-field>
				<div style="width: 100%;" fxLayout fxLayoutAlign="center center">
					<button [disabled]="campForm.invalid" fxFlex="60" fxFlex.xs="80" fxFlex.sm="70"
						style="color: white;" type="button" mat-flat-button color="primary" (click)="submit()">
						SUBMIT
					</button>
				</div>
			</form>
		</div>
	</div>
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<div class="full-width" fxLayout fxLayoutAlign="center center"
		*ngIf="faceCampaignData?.bestResultTitle?.length>0 && faceCampaignData?.bestImages?.length>0">
		<div fxFlex="80" fxFlex.xs="96" fxFlex.sm="90" fxLayout="column" fxLayoutAlign="center center">
			<div class="inner-text" [innerHtml]="faceCampaignData?.bestResultTitle[0].text"
				[style.color]="faceCampaignData?.bestResultTitle[0]?.color" class="mat-display-1"></div>
			<p>&nbsp;</p>
			<div fxLayout fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap.xs="20px" fxLayoutGap="80px">
				<img *ngFor="let img of faceCampaignData?.bestImages" height="200" [src]="dir + img" alt="not_avail">
			</div>
		</div>
	</div>
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<div style="width: 100%;" fxLayout fxLayoutAlign="center center">
		<button fxFlex="250px" class="reg-btn" mat-flat-button color="warn"
			(click)="element.scrollIntoView({ behavior: 'smooth', block: 'center' })">
			REGISTER NOW
		</button>
	</div>
	
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<div class="full-width" fxLayout fxLayoutAlign="center center"
		*ngIf="faceCampaignData?.featuresTitle?.length>0 && faceCampaignData?.features?.length>0">
		<div fxFlex="80" fxFlex.xs="96" fxFlex.sm="90" fxLayout="column" fxLayoutAlign="center center">
			<div class="inner-text" [innerHtml]="faceCampaignData?.featuresTitle[0].text"
				[style.color]="faceCampaignData?.featuresTitle[0]?.color" class="mat-display-1"></div>
			<p>&nbsp;</p>
			<mat-grid-list class="full-width" [cols]="mobileDevice ? '1' : '3'"
				[rowHeight]="mobileDevice ? '100px' : '120px'" gutterSize="20">
				<mat-grid-tile *ngFor="let feature of faceCampaignData?.features">
					<div class="full-width" fxLayout>
						<div style="padding: 5px 0;" fxFlex="30" fxLayout fxLayoutAlign="center start">
							<img height="50" [src]="dir + feature.image" alt="error">
						</div>
						<div fxFlex="70" fxLayout="column" fxLayoutAlign="start start">
							<p class="mat-body-strong" [innerHtml]="feature.title"></p>
							<p class="mat-body" [innerHtml]="feature.description"></p>
						</div>
					</div>
				</mat-grid-tile>
			</mat-grid-list>
		</div>
	</div>

	<div class="full-width" *ngIf="faceCampaignData?.resultImageTitle?.length">
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<div class="full-width" fxLayout fxLayoutAlign="center center"
			*ngIf="faceCampaignData?.resultImageTitle?.length>0 && faceCampaignData?.resultImages?.length>0">
			<div fxFlex="80" fxFlex.xs="96" fxFlex.sm="90" fxLayout="column" fxLayoutAlign="center center">
				<div class="inner-text" [innerHtml]="faceCampaignData?.resultImageTitle[0].text"
					[style.color]="faceCampaignData?.resultImageTitle[0]?.color" class="mat-display-1"></div>
				<p>&nbsp;</p>
				<mat-grid-list class="full-width" [cols]="mobileDevice ? '2' : '4'"
					[rowHeight]="mobileDevice ? '150px' : '200px'" gutterSize="20">
					<mat-grid-tile *ngFor="let result of faceCampaignData?.resultImages">
						<img [height]="mobileDevice ? '150' : '200'" [src]="dir + result" alt="404_error">
					</mat-grid-tile>
				</mat-grid-list>
			</div>
		</div>
	</div>
	<div class="full-width" *ngIf="faceCampaignData?.speakTitle?.length">
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<div class="full-width" fxLayout fxLayoutAlign="center center">
			<div fxFlex="80" fxFlex.xs="96" fxFlex.sm="90" fxLayout="column" fxLayoutAlign="center center">
				<div class="inner-text" [innerHtml]="faceCampaignData?.speakTitle[0].text"
					[style.color]="faceCampaignData?.speakTitle[0]?.color" class="mat-display-1"></div>
				<p>&nbsp;</p>
				<div fxLayout fxLayoutAlign="center center" fxLayoutGap="80px">
					<div *ngFor="let img of faceCampaignData?.topperSpeak" fxLayout="column"
						fxLayoutAlign="center center" fxLayoutGap="15px">
						<img height="200" [src]="dir + img.image" alt="not_avail">
						<p class="mat-body">{{img.testimonial}}</p>
					</div>
				</div>
			</div>
		</div>
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<div style="width: 100%;" fxLayout fxLayoutAlign="center center">
			<button fxFlex="250px" class="reg-btn" mat-flat-button color="warn"
				(click)="element.scrollIntoView({ behavior: 'smooth', block: 'center' })">
				REGISTER NOW
			</button>
		</div>
	</div>

	<div class="full-width" *ngIf="displayCount && displayCount.length">
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<p>&nbsp;</p>
	
		<div fxLayout fxLayout.xs="column" fxLayoutAlign="center center" style="height: 95px;" fxLayoutGap="10px"
			class="footer">
			<div class="counter-footer" fxLayout="column" fxLayoutAlign="center center">
				<p class="footer-text">Total Visitors</p>
				<div fxLayout *ngIf="!mobileDevice">
					<mat-card class="visitors_number mat-elevation-z8" *ngFor="let data of displayCount" fxLayout
						fxLayoutAlign="center center">
						{{data}}
					</mat-card>
				</div>
				<div fxLayout *ngIf="mobileDevice">
					<mat-card class="visitors_number1 mat-elevation-z8" *ngFor="let data of displayCount" fxLayout
						fxLayoutAlign="center center">
						{{data}}
					</mat-card>
				</div>
			</div>
			<div fxLayout fxLayoutAlign="center center">
				<span style="color: #bcbcbc">&#9400; &nbsp;</span>
				<p class="footer-text"> Copyright APT. Handcrafted by <a href="https://eulogik.com"
						target="_blank">Eulogik</a>,
					India &nbsp;</p><span style="font-size: 16px; height: 16px; color: #fff;">&#10084;</span>
			</div>
		</div>
	</div>
</div>